import { ColorCodes } from '@utils/color-constants';
import { Injectable } from '@angular/core';
import { HelperService } from 'src/app/shared/helpers/helper.service';
import * as THREE from 'three';
import { Mesh, Vector2, Object3D } from 'three';
import { CanvasConfig } from 'src/app/shared/shape-selector/shape-selector.model';
import { AlteredShapeConfiguration, Object3DUserData } from '@utils/shape';
import { SetShapeUserDataService } from 'src/app/shared/helpers/set-shape-userdata.service';
import { DragDropShape } from 'src/app/shared/drag-and-drop/drag-and-drop-model';

@Injectable({
  providedIn: "root"
})
export class DragAndDropService {
  public draggedObject: Mesh = new THREE.Mesh();
  private objects: Object3D[] = [];
  private groundPositionY = -83.5; // This is the plane surface.
  private objectDictionary: Record<string, THREE.Object3D> = {}; // This is to optimise the search.
  private defaultScaleOfAnyShape = new THREE.Vector3(1, 1, 1);

  constructor(
    private helperService: HelperService,
    private setShapeUserDataService: SetShapeUserDataService
  ) {}

  /*
    This method handels the dragging of the selected shapes and collision detection.
  */
  public dragObject(
    draggableObject: THREE.Object3D | null,
    mouseMovePosition: Vector2,
    camera: THREE.Camera,
    objects: THREE.Object3D[]
  ): void {
    this.objects = objects;
    this.updateObjectDictionary(this.objects);
    this.draggedObject = draggableObject as Mesh;
    if (draggableObject) {
      const draggedObjectIndex = this.objects.indexOf(draggableObject);
      if (draggedObjectIndex !== -1) {
        const raycaster = new THREE.Raycaster();
        raycaster?.setFromCamera(mouseMovePosition, camera);
        const foundShapes = raycaster.intersectObjects(objects); // Means the plane along with other objects.

        if (foundShapes.length) {
          // We create a bounding box of the dragged shape first.
          const dragBoundingBox = new THREE.Box3().setFromObject(
            draggableObject
          );

          // This will copy the position to the dragged shape with respect to the mouse coordinates on the plane
          for (const shape of foundShapes) {
            // Shape here refers to the plane
            if (shape.object.userData["isDraggable"]) continue;
            if (
              shape.point.x < DragDropShape.Maximum_Drag_Drop_Point_Shape &&
              shape.point.x > -DragDropShape.Maximum_Drag_Drop_Point_Shape &&
              shape.point.z > -DragDropShape.Maximum_Drag_Drop_Point_Shape &&
              shape.point.z < DragDropShape.Maximum_Drag_Drop_Point_Shape
            ) {
              draggableObject.position.x = shape.point.x;
              draggableObject.position.z = shape.point.z;
            }
          }

          // We need to update the position of the bounding box simultaneously with the dragged shape
          dragBoundingBox
            .copy((draggableObject as any).geometry.boundingBox)
            .applyMatrix4(draggableObject.matrixWorld);

          let nearestCollision = false; // To end the loop as soon as the collided shape is found.
          for (let object of objects) {
            if (!object.userData["isDraggable"] || object === draggableObject) {
              continue; // Skip draggable objects and the dragged object itself
            }

            // We create another bounding box for the shape that is found colliding.
            const shapeBoundingBox = new THREE.Box3().setFromObject(object);
            // We check with the boundingboxes and detect the collision.
            const isCollision = dragBoundingBox.intersectsBox(shapeBoundingBox);

            if (isCollision && !nearestCollision) {
              nearestCollision = true;
              this.performCollisionActions(
                draggableObject as Mesh,
                object as Mesh
              );
            }
          }
          if (!nearestCollision) {
            this.revertCollisionActions(draggableObject as THREE.Mesh);
          }
          this.setIncreasedYforScaledShape(draggableObject);
          this.objects[draggedObjectIndex] = draggableObject;
          objects[objects.indexOf(draggableObject)] = draggableObject;
        }
      }
    }
  }

  // This method handels dropping of the dragged shape on right click of the mouse.
  public dropObject(
    draggableObject: THREE.Object3D | null,
    objects: THREE.Object3D[]
  ): THREE.Object3D | null {
    this.draggedObject = draggableObject as Mesh;
    this.objects = objects;
    this.updateObjectDictionary(this.objects);

    if (draggableObject) {
      if (draggableObject.userData["isInCollision"]) {
        this.placeShapesOnTop();
      } else {
        this.setShapeToNearGridCoords();
      }
      // Below lines will update the dragged object new position in the grid data.
      const { row, column, cell } = this.helperService.calculateRowAndColumn(
        this.draggedObject as Mesh
      );

      this.draggedObject.userData[Object3DUserData.cellReference] = {
        row: row,
        column: column,
        cell: cell
      };
      this.updateInitialPosition;
    }
    this.setShapeUserDataService.adjacentData(
      this.objects,
      draggableObject as Mesh
    );
    this.setIncreasedYforScaledShape(draggableObject);
    return draggableObject;
  }

  private get isShapeScaled(): boolean {
    return (
      this.draggedObject.scale.x !== this.defaultScaleOfAnyShape.x ||
      this.draggedObject.scale.y !== this.defaultScaleOfAnyShape.y ||
      this.draggedObject.scale.z !== this.defaultScaleOfAnyShape.z
    );
  }

  /*
    When the shape is scaled, the shape is expanded in all the direction including y
    since y has to be constant to match the plane we need to adjust the y value of
    the shape manually.
  */
  private setIncreasedYforScaledShape(
    draggableObject: THREE.Object3D<THREE.Event> | null
  ): void {
    if (draggableObject && this.isShapeScaled && draggableObject.position) {
      draggableObject.position.y =
        draggableObject?.position.y +
        (AlteredShapeConfiguration.standardHeigthOfShape / 2);
    }
  }

  /*
    This method handels the collision detection, we are currently reducing the opacity of the dragged shape if it comes in
    contact with any of the shape on the plane. And these changes are temporary.
  */
  private performCollisionActions(
    draggedObject: Mesh,
    collidedObject: Mesh
  ): void {
    this.draggedObject = draggedObject;
    draggedObject.userData["isInCollision"] = true;
    draggedObject.userData["collidedObjectId"] = collidedObject.uuid;

    // This is the new transperent material that is being applied on the shape
    const transparentMaterial = new THREE.MeshBasicMaterial({
      color: ColorCodes.red,
      transparent: true,
      opacity: CanvasConfig.transparentOpacity
    });

    const positionY = this.topmostShape
      ? this.topmostShape.position.y
      : collidedObject.position.y;
    draggedObject.material = transparentMaterial;
    draggedObject.position.y =
      positionY + AlteredShapeConfiguration.standardHeigthOfShape;
  }

  /*
  Reverts back all the temporary changes done on the shape during collision.
*/
  private revertCollisionActions(draggedObject: Mesh): void {
    draggedObject.userData["isInCollision"] = false;
    draggedObject.material = draggedObject.userData["initialMaterial"];
    draggedObject.position.y = draggedObject.userData["initialPosition"].y;
  }

  /*
    This method loops untill it gets the empty spot on the plane
    The logic here is we get the mouse coordinates check if there is any shape on that grid if found move to the next grid
    else return the co-ordinates, It only returns the 'x' and 'y' since our 'z' remains constant.
  */
  private findEmptyGridSpot(
    startingGridCords: Vector2,
    draggableObject: Mesh
  ): Vector2 {
    let emptyGridCords = startingGridCords.clone();
    while (this.checkCollisionOnGrid(emptyGridCords, draggableObject)) {
      // Move to the next grid cell
      emptyGridCords.x += CanvasConfig.cellSize;

      // If the end of the row is reached, move to the next row
      if (emptyGridCords.x >= CanvasConfig.gridSize) {
        emptyGridCords.x = startingGridCords.x;
        emptyGridCords.y += CanvasConfig.cellSize;
      }
    }

    return emptyGridCords;
  }

  // Helps in looping through each grid and returns boolean based on the presence of any shape.
  private checkCollisionOnGrid(
    gridCords: Vector2,
    draggableObject: Mesh
  ): boolean {
    // Loop through objects and check for collision at the specified grid coordinates
    for (const object of this.objects) {
      if (object !== draggableObject) {
        const objectBoundingBox = new THREE.Box3().setFromObject(object);
        if (
          objectBoundingBox.containsPoint(
            new THREE.Vector3(
              gridCords.x,
              draggableObject.position.y,
              gridCords.y
            )
          )
        ) {
          return true; // Collision detected
        }
      }
    }
    return false; // No collision detected
  }

  // This method handels the placement of the shapes on top of another
  private placeShapesOnTop(): void {
    this.revertCollisionActions(this.draggedObject as Mesh);
    // Set the position of the draggable object to the nearest grid intersection.
    if (this.topmostShape) {
      const precisedGridCoords = this.helperService.getNearestGridCords(
        this.draggedObject.name,
        new Vector2(
          this.topmostShape?.position.x,
          this.topmostShape?.position.z
        )
      );

      // We get the nearest grid coords first
      this.draggedObject.position.set(
        precisedGridCoords.x,
        this.topmostShape.position.y +
          AlteredShapeConfiguration.standardHeigthOfShape,
        precisedGridCoords.y
      );
    } else {
      this.setShapeToNearGridCoords();
    }
  }

  private setShapeToNearGridCoords(): void {
    // These are the mouse coordinates.
    const locationX = this.draggedObject.position.x;
    let locationY = this.draggedObject.position.y;
    const locationZ = this.draggedObject.position.z;

    // Here we make the shape sit on the grid avoiding them to go inside.
    locationY = this.helperService.getGroundLocationY(locationY);
    // First we get the nearest grid co-ordinates
    const nearestGridCords = this.helperService.getNearestGridCords(
      this.draggedObject.name,
      new Vector2(locationX, locationZ)
    );

    // Find an empty grid spot to place the shape
    const emptyGridCords = this.findEmptyGridSpot(
      nearestGridCords,
      this.draggedObject as Mesh
    );

    // Set the position of the draggable object to the nearest grid intersection if no shape below is found within the range.
    if (!this.isShapePresentBelow(emptyGridCords.x, emptyGridCords.y)) {
      const positionKey = `${emptyGridCords.x}-${this.groundPositionY}-${emptyGridCords.y}`;
      const shapeWithExactPosition = this.objectDictionary[positionKey];
      if (shapeWithExactPosition) {
        const initialPosition = this.draggedObject.userData[
          Object3DUserData.initialPosition
        ];
        this.draggedObject.position.set(
          initialPosition.x,
          initialPosition.y,
          initialPosition.z
        );
      } else {
        this.draggedObject.position.set(
          emptyGridCords.x,
          this.groundPositionY,
          emptyGridCords.y
        );
      }
    } else {
      // Set the position of the draggable object to the nearest grid intersection.
      this.draggedObject.position.set(
        emptyGridCords.x,
        locationY,
        emptyGridCords.y
      );
    }
  }

  private isShapePresentBelow(x: number, z: number): boolean {
    const maxY =
      this.draggedObject.position.y -
      AlteredShapeConfiguration.standardHeigthOfShape; // Assuming locationY is the initial position

    for (const object of this.objects) {
      if (object.position.x === x && object.position.z === z) {
        if (object.position.y === maxY) {
          return true; // Found a shape below within the specified range
        }
      }
    }

    return false; // No shape found below within the range
  }

  // This will get the topmost shape present in the perticular grid.
  private get topmostShape(): Mesh | null {
    const { row, column, cell } = this.helperService.calculateRowAndColumn(
      this.draggedObject as Mesh
    );
    const shapesInTheMatchingGrid = this.objects.filter(
      shape =>
        shape.userData[Object3DUserData.cellReference]?.row ===
          Math.round(row) &&
        shape.userData[Object3DUserData.cellReference]?.column ===
          Math.round(column) &&
        shape !== this.draggedObject
    );

    if (shapesInTheMatchingGrid.length === 0) {
      return null; // No matching shapes found
    }

    const topmostShape = shapesInTheMatchingGrid.reduce(
      (prevShape, currentShape) => {
        const prevCell =
          prevShape.userData[Object3DUserData.cellReference].cell;
        const currentCell =
          currentShape.userData[Object3DUserData.cellReference].cell;

        return currentCell > prevCell ? currentShape : prevShape;
      },
      shapesInTheMatchingGrid[0] // Use the first shape as the initial value
    );

    return topmostShape as Mesh;
  }

  private get updateInitialPosition(): Mesh {
    const res =  this.draggedObject.userData[Object3DUserData.initialPosition].copy(
      this.draggedObject.position
    );
    return res;
  }

  private updateObjectDictionary(objects: THREE.Object3D[]) {
    this.objectDictionary = {};
    for (const obj of objects) {
      const positionKey = `${obj.position.x}-${obj.position.y}-${obj.position.z}`;
      this.objectDictionary[positionKey] = obj;
    }
  }
}
