<div class="container">
  <div class="canvas-container">
    <div #rendererContainer class="main-canvas">
    </div>
    <div class="mini-canvas" *ngIf="!isViewMode">
      <shape-selector></shape-selector>
    </div>
    <div class="compass">
      <compass-cube></compass-cube>
    </div>
    <div class="capture-btn-container" *ngIf="isViewMode && structureData?.status === structureStatus.Published">
      <button mat-button 
      class="capture-btn" 
      (click)="captureImage()" 
      [disabled]="isDisabledCaptureBtn"
      >
      Capture
      </button>
    </div>
    <div class="zoom-actions" *ngIf="!isViewMode">
      <mat-icon class="in" svgIcon="zoomIn" (click)="zoom(zoomConfig.zoomIn)">
      </mat-icon>
      <mat-icon class="out" svgIcon="zoomOut" (click)="zoom(zoomConfig.zoomOut)">
      </mat-icon>
    </div>
    <div class="drawer-container" *ngIf="!isViewMode">
      <action-button-container 
      [isEditMode]="isEditMode" 
      [isEditted]="isEditted" 
      [structureData]="structureData" 
      (isImageCaptured)="autoCaptureImageToActionBtn($event)" 
      [imageDataFromChild]="imageDataToChild">
      </action-button-container>
    </div>
    <div class="back-button" (click)="redirectPage()" *ngIf="!isViewMode">
      <mat-icon>arrow_back</mat-icon>
    </div>
  </div>
</div>