import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { StructureService } from '../services/structure/structure.service';
import { StructureInstance } from '@models/structure.model';
import { MatDialog } from '@angular/material/dialog';
import { GenericDialogComponent } from '../shared/generic-dialog/generic-dialog.component';
import { DialogInvokingComponents } from '@models/generic-dialog.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-my-structures',
  templateUrl: './my-structures.component.html',
  styleUrls: ['./my-structures.component.scss']
})
export class MyStructuresComponent implements OnInit {
  public structures: { count: number, rows: StructureInstance[] } = { count: 0, rows: [] };
  public myStructuresData: { count: number, rows: StructureInstance[] } = { count: 0, rows: [] };
  public loading = false;
  public searchQuery = "";
  public showCheckboxes = false;
  private currentPageIndex = 0;
  private subsequentPageSize = 5;
  public selectAllCheckbox = false;
  public isIndeterminate = false;
  public selectedCardData: number[] = [];
  private disableOnChildCheckboxChange = true;
  public shouldSkipFilterStructure: boolean = false;

  constructor(
    private structureService: StructureService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.fetchStructures();
  }

  private fetchStructures(): void {
    this.loading = true;
    this.structureService.getSavedStructures(this.currentPageIndex, this.subsequentPageSize, this.searchQuery)
    .pipe(
      finalize(()=> {
        this.loading = false;
      })
    )
    .subscribe({
      next: response => {
        let updatedStructures;
        this.myStructuresData = response;
        if (this.currentPageIndex === 0) {
          this.myStructuresData = response;
          updatedStructures = response;
        } else {
          updatedStructures = {
            count: response.count,
            rows: [...this.structures.rows, ...response.rows]
          };
        }
        this.structures = updatedStructures;
        this.loading = false;
        this.cdr.detectChanges();
      },
      error: () => {
        this.cdr.detectChanges();
      }
    });
  }

  public loadMoreMyStructures(event: Boolean): void {
    if (event) {
      this.currentPageIndex = 0;
      this.fetchStructures();
    } else {
      this.currentPageIndex++;
      this.fetchStructures();
    }
  }

  public resetDataAndFetch(): void {
    this.currentPageIndex = 0;
    this.fetchStructures();
  }

  public toggleAllCheckboxes(event: MatCheckboxChange) {
    this.showCheckboxes = event.checked;
    if (this.showCheckboxes) {
      this.shouldSkipFilterStructure = true;
      const filteredData = this.structures.rows.filter(item => item.status === 'Saved');
      this.structures = {
        count: filteredData.length,
        rows: filteredData
      };
    } else {
      this.currentPageIndex = 0;
      this.fetchStructures();
    }
    this.updateChildCheckboxes(this.showCheckboxes);
    this.cdr.detectChanges();
  }

  public filterStatus(status: string) {
    this.disableOnChildCheckboxChange = true;
    if (status === 'All') this.fetchStructures();
    else {
      const filteredRows = this.myStructuresData.rows.filter(item => item.status === status);
      this.structures = {
        count: filteredRows.length,
        rows: filteredRows
      };
    }
  }

  public updateChildCheckboxes(isChecked: boolean): void {
    if (this.structures?.rows) {
      this.structures.rows.forEach(item => item.isCheckbox = isChecked);
    }
  }

  // search 
  public sendDataToFilterStructure(query: string): void {
    if (this.shouldSkipFilterStructure) return;
    this.searchQuery = query;
    if (!query.length && this.shouldSkipFilterStructure) {
      this.fetchStructures();
    }
  }

  public filterStructures(query: string): void {
    if (this.shouldSkipFilterStructure) return;
    this.searchQuery = query;
    this.disableOnChildCheckboxChange = true;
    if (this.searchQuery.trim() === "") {
      this.searchQuery = "";
      this.fetchStructures();
    } else {
      this.loading = true;
      this.structureService.getSavedStructures(this.currentPageIndex, this.myStructuresData.count, this.searchQuery).subscribe({
        next: response => {
          const filteredRows = response.rows.filter(item =>
            item.structureName.toLowerCase().includes(this.searchQuery.toLowerCase())
          );
          this.structures = {
            count: filteredRows.length,
            rows: filteredRows
          };
          this.loading = false;
          this.updateChildCheckboxes(false);
          this.cdr.detectChanges();
        },
        error: () => {
          this.loading = false;
          this.cdr.detectChanges();
        }
      });
    }
  }

  public onChildCheckboxChange(selectedCount: number[]) {
    this.selectedCardData = selectedCount;
    if (this.disableOnChildCheckboxChange) return;
    this.selectAllCheckbox = selectedCount.length === this.structures.rows.length;
    this.isIndeterminate = selectedCount.length > 0 && selectedCount.length < this.structures.rows.length;
  }

  public onSelectionChange(event: Event): void {
    if(!this.selectedCardData.length) {
      return;
    }

    const selectElement = event.target as HTMLSelectElement;
    const selectedValue = selectElement.value;

    // Call specific method based on the selected value
    switch (+selectedValue) {
      case 1:
        this.onBulkPublish(this.selectedCardData);
        break;
      case 2:
        this.onBulkDelete(this.selectedCardData);
        break;
      default:
        break;
    }
  }

  private onBulkPublish(structureIdArray: number[]): void {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      data: {
        componentName: DialogInvokingComponents.MyStructuresList,
        title: "Publish Structures",
        content:
          `Are you sure you want to publish all of these structures(${this.selectedCardData.length})? This action cannot be undone.`,
        firstBtn: "Publish",
        secondBtn: "Cancel"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.structureService.publishStructures(structureIdArray).subscribe({
          next: response => {
            this.resetDataAndFetch();
            this.selectAllCheckbox = false;
            this.showCheckboxes = false;
            this.selectedCardData = [];
            this.isIndeterminate = false;
            this.searchQuery = "";
            this.snackBar.open(response.message, 'close', {
              duration: 3000
            });
          },
          error: error => {
            this.snackBar.open(error, 'close', {
              duration: 3000
            });
          }
        })
      }
    });
  }

  private onBulkDelete(structureIdArray: number[]): void {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      data: {
        componentName: DialogInvokingComponents.MyStructuresList,
        title: "Delete Structures",
        content:
          `Are you sure you want to permanently delete all of these structures(${this.selectedCardData.length})? This action cannot be undone.`,
        firstBtn: "Delete",
        secondBtn: "Cancel"
      },
      autoFocus:false,
      restoreFocus:false,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.structureService.deleteStructures(structureIdArray).subscribe({
          next: response => {
            this.resetDataAndFetch();
            this.selectAllCheckbox = false;
            this.showCheckboxes = false;
            this.selectedCardData = [];
            this.isIndeterminate = false;
            this.searchQuery = "";
            this.snackBar.open(response.message, 'close', {
              duration: 3000
            });
          },
          error: error => {
            this.snackBar.open(error, 'close', {
              duration: 3000
            });
          }
        })
      }
    });
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }
}
