
// We have to define all the type of meshes, geometries, materials, and helpers here
export const ShapeType = {
    Mesh: 'Mesh',
    LineSegments: 'LineSegments',
    Scene: 'Scene',
    Geometry: {
      BoxGeometry: 'BoxGeometry',
      EdgesGeometry: 'EdgesGeometry',
      SphereGeometry: "SphereGeometry",
      CircleGeometry: "CircleGeometry",
      ConeGeometry: "ConeGeometry",
      CylinderGeometry: "CylinderGeometry",
      ExtrudeGeometry: "ExtrudeGeometry",
      PlaneGeometry: "PlaneGeometry"
    },
    Material: {
        MeshBasicMaterial: 'MeshBasicMaterial',
    }
}
