import { Mesh } from 'three';
import { Injectable } from '@angular/core';
import { Object3DUserData } from '@utils/shape';

@Injectable({
  providedIn: 'root',
})
export class DeleteShapeService {
  constructor() {}

  public delete(
    deletingShape: THREE.Object3D[],
    allShapes: THREE.Object3D[],
    scene: THREE.Scene
  ): THREE.Object3D[] {
    // Use filter to create a new array without the deletingShape
    for(let i =0; i<deletingShape.length; i++){
      const filteredShapes = allShapes.filter((shape) => shape !== deletingShape[i]);

      // Optionally, dispose of the deleted shape to free up memory
      scene.remove(deletingShape[i]);
      // Replace the original array with the filtered array
    allShapes.length = 0;
    allShapes.push(...filteredShapes);

    enum FaceType {
      Right = 'Right',
      Left = 'Left',
      Top = 'Top',
      Bottom = 'Bottom',
      Front = 'Front',
      Back = 'Back',
    }

    for (let j = 1; j < allShapes.length; j++) {
      const adjacentData = allShapes[j].userData[Object3DUserData.adjacentData];
      const shapeUUID = deletingShape[i].uuid;

      for (const faceTypeName in FaceType) {
        if (adjacentData && adjacentData[faceTypeName] === shapeUUID) {
          allShapes[j].userData[Object3DUserData.faceType][faceTypeName] === 'Curved'? 'Curved': 'Plane';
        }
      }
    }
    }
    return allShapes;
  }
}
