import { ReviewModel } from "./admin.model";
import { AdminStatusHistoryModel } from "./adminStatusHistory.model";
import { TableColumn } from "./data-grid.model";
import { RefundStatus } from "./payment.model";
import { ProductDetails } from "./product.model";

export enum OrderStatus {
  Failed = "Failed",
  Pending = "Pending",
  Ordered = "Ordered",
  Packed = "Packed",
  Cancelled = "Cancelled",
  Dispatched = "Dispatched",
  Delivered = "Delivered",
  ReturnInitiated = "Return Initiated",
  PickupScheduled = "Pickup Scheduled",
  Returned = "Returned"
}

export enum OrderStatusNext {
  Failed = "",
  Pending = "Ordered",
  Ordered = "Packed",
  Packed = "Dispatched",
  Cancelled = "",
  Dispatched = "Delivered",
  Delivered = "",
  ReturnInitiated = "Pickup Scheduled",
  PickupScheduled = "Returned",
  Returned = ""
}

export interface OrderInput {
  products: {
    id: number;
    quantity: number;
  }[];
  totalAmount: number;
  deliveryCharge: number;
  customerName?: string;
  customerEmail?: string;
  customerMobile: string;
  recipientName: string;
  recipientMobileNumber: string;
  recipientAddressType: string;
  recipientAddress: string;
  recipientCity: string;
  recipientState: string;
  recipientPincode: number;
  recipientHouseNumber: string;
}

export interface BaseOrder {
  products: ProductDetails[];
  totalAmount: number;
  deliveryCharge: number;
  customerMobile: string;
  orderStatus?: string;
  paymentGateway?: string;
  paymentStatus?: string;
  paymentStatusDescription?: string;
  paymentReference?: string;
  pickupDate?: Date;
  pickupTime?: string;
  awbNumber?: string;
  courierName?: string;
  recipientName: string;
  recipientMobileNumber: string;
  recipientAddressType: string;
  recipientAddress: string;
  recipientCity: string;
  recipientState: string;
  recipientPincode: number;
  recipientHouseNumber: string;
  returnReason?: string;
}

export interface CustomerInfo {
  customerName?: string;
  customerEmail?: string;
}

export interface RefundInfo {
  refundAmount?: number;
  refundStatus?: string;
  refundReference?: string;
  refundDate?: Date;
}

export interface OrderAttributes extends BaseOrder, CustomerInfo, RefundInfo {
  id: number;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface OrderCreationAttributes
  extends BaseOrder,
  CustomerInfo,
  RefundInfo {
  id?: number;
}

export interface OrderDetails extends OrderAttributes {
  createdAt?: Date;
  updatedAt?: Date;
}

export interface OrderCreationAttributes
  extends BaseOrder,
  CustomerInfo,
  RefundInfo {
  id?: number;
}

export type NullableOrder = OrderAttributes | null;

export interface OrderConfirmation {
  orderId: number;
  recipientName: string;
  recipientMobileNumber: string;
  recipientAddressType: string;
  recipientAddress: string;
  recipientCity: string;
  recipientState: string;
  recipientPincode: number;
  recipientHouseNumber: string;
  products: ProductDetails[];
  customerName?: string;
  customerEmail?: string;
  customerMobile: string;
  placedOn?: string;
  message: string;
  imageUrl: string;
  orderLink: string;
  subTotal: number;
  deliveryCharge: number;
  totalAmount: number;
}

export interface OrderPageDetail extends CustomerInfo, BaseOrder, RefundInfo {
  id: number;
  orderStatus: string;
  createdAt?: Date;
  updatedAt?: Date;
  dateArray?: string[];
  orderStatusHistory?: AdminStatusHistoryModel[];
  reviews?: ReviewModel[];
}

export enum OrdersByMobileNumber {
  Failed = "No orders found for the given mobile number",
  OrderFailed = "Error fetching orders by mobile number",
}

export interface OrderPageResponse {
  rows: OrderPageDetail[];
  count: number;
  products: ProductDetails[];
}

export enum Action {
  Return = "Return",
  Cancel = "Cancel",
}

export interface Message {
  message: string;
}

export enum ErrorMessageSendEmail {
  FailedRequest = "Failed to send help request email",
  Failed = "Failed to send an email.",
  success = "Email sent successfully",
  OrderNotFound = "Order not found",
  MailSent = "HelpRequest sent successfully",
}

export enum OrderErrorMessage {
  OrderNotFound = "Order not found",
  OrderNotCreated = "Order not created",
}

export const orderListColumns: TableColumn[] = [
  { key: "action", value: "Action", type: "action" },
  { key: "id", value: "Order ID", type: "number" },
  { key: "orderStatus", value: "Status", type: "text" },
  { key: "totalAmount", value: "Amount", type: "price" },
  { key: "createdAt", value: "Created At", type: "datetime" },
  { key: "updatedAt", value: "Updated At", type: "datetime" }
];

export interface OperationsModel {
  label: string;
  value: string;
  placeholder: string;
  controlName: string;
  type: string;
}

export interface pickupScheduledModel extends OrderConfirmation {
  pickupDate: string;
  pickupTime: string;
}

export interface status {
  title: string;
  display: boolean;
  highlight: boolean;
  belongsTo: string;
  date: string;
}

export { RefundStatus };
export interface OrderStatusItem {
  status: string;
  label: string;
  icon: string;
}

export enum OrderMessage {
  CreateFail = "Failed to create order",
  UpdateSuccess = "Updated successfully",
  UpdateFail = "Updation failed",
  FailFetchOrder = "Failed to fetch order",
  FailFetchOrders = "Failed to fetch orders",
  FailGetOrderDetail = "Fail to get the Order Details",
  FetchStatusFail = "Fail to fetch all the status",
  FailedRequest = "Failed to send help request email",
  ItemUnavailable = "Some items are currently unavailable. Please review in cart.",
  OrderAlreadyCancelled = "This order is already cancelled",
  OrderAlreadyDispatched = "This order is already dispatched",
  OrderAlreadyDelivered = "This order is already delivered",
  UnknownError = "An unknown error occurred",
  CancelSuccess = "Order cancelled successfully.",
  CancelFailed = "Failed to cancel the order. Please try again later",
  ReturnInitiationSuccess = "Return process initiated successfully.",
  ReturnInitiationFailed = "Failed to initiate the return process. Please try again later."
}

export interface DialogSaveDataModel {
  reason: string;
}
