import { Object3DUserData } from '@utils/shape';
import { Injectable } from '@angular/core';
import { ComponentInteractionSrevice } from 'src/app/services/component-interaction/component-interaction.service';
import { FaceType, Face, CountInputData, CountOutputData, InputObject, OutputObject } from '../../../../utils/count-calculator/count-calculator.model';
import * as THREE from 'three';
import { Sides } from 'src/app/shared/models/helper.model';

@Injectable({
  providedIn: 'root',
})
export class CountCalculatorService {
  constructor(private compIntSrv: ComponentInteractionSrevice) {}

  public calculate(objects: THREE.Object3D[]) {
      for (let i = 1; i < objects.length; i++) {
        for (let j = i + 1; j < objects.length; j++) {
          const counts: Record<string, number> = {};
          for (const key in objects[i].userData[Object3DUserData.faceType]) {
            const faceTypeValue = objects[i].userData[Object3DUserData.faceType][key];
            counts[faceTypeValue] = (counts[faceTypeValue] || 0) + 1;
          }

          const directionToFaceMap = {
            Bottom: Face.Top,
            Top: Face.Bottom,
            Right: Face.Left,
            Left: Face.Right,
            Front: Face.Back,
            Back: Face.Front,
          };

          for (const direction in directionToFaceMap) {
            if (objects[i].uuid === objects[j].userData[Object3DUserData.adjacentData]?.[direction]) {
               updateFaceType(objects[i], objects[j], counts, oppositeSide(direction));
              break;
            }
          }
        }
      }

      function updateFaceType(
        firstObj: THREE.Object3D,
        secondObj: THREE.Object3D,
        count: any,
        face: string
      ) {
        const faces = Object.keys(Face);
        const remainingFace = faces.filter((ele) => ele !== face);
        let oppositeFace = oppositeSide(face);
        const remainingFaceSecondobj = faces.filter(
          (ele) => ele !== oppositeFace
        );
        if (
          firstObj.userData[Object3DUserData.faceType]?.[face] === FaceType.Curved
        ) {
          let faceTypeArray: any[] = [];
          let faceType: Object;
          for (let i = 0; i < remainingFace.length; i++) {
            faceType = {
              [face]:
                firstObj.userData[Object3DUserData.faceType]?.[face] ===
                FaceType.Curved
                  ? FaceType.Curved
                  : firstObj.userData[Object3DUserData.faceType]?.[face],
              [remainingFace[i]]:
                firstObj.userData[Object3DUserData.faceType]?.[
                  remainingFace[i]
                ] === undefined
                  ? FaceType.Plane
                  : firstObj.userData[Object3DUserData.faceType]?.[
                      remainingFace[i]
                    ],
            };
            faceTypeArray.push(faceType);
          }
          const outputFirstObject = combineObjects(faceTypeArray);

          firstObj.userData[Object3DUserData.faceType] = outputFirstObject;
          let faceTypeAdjacentArray: any[] = [];
          let faceTypeAdjacent: Object;
          for (let i = 0; i < remainingFaceSecondobj.length; i++) {
            faceTypeAdjacent = {
              [remainingFaceSecondobj[i]]:
                secondObj.userData[Object3DUserData.faceType]?.[
                  remainingFaceSecondobj[i]
                ] === undefined
                  ? FaceType.Plane
                  : secondObj.userData[Object3DUserData.faceType]?.[
                      remainingFaceSecondobj[i]
                    ],
              [oppositeFace]:
                secondObj.userData[Object3DUserData.faceType]?.[oppositeFace] ===
                undefined
                  ? FaceType.Plane
                  : secondObj.userData[Object3DUserData.faceType]?.[oppositeFace],
            };
            faceTypeAdjacentArray.push(faceTypeAdjacent);
          }
          const outputSecondObject = combineObjects(faceTypeAdjacentArray);
          secondObj.userData[Object3DUserData.faceType] = outputSecondObject;
        } else if (
          secondObj.userData[Object3DUserData.faceType]?.[oppositeFace] ===
          FaceType.Curved
        ) {
          let faceTypeArray: any[] = [];
          let faceType;
          for (let i = 0; i < remainingFace.length; i++) {
            faceType = {
              [face]:
                firstObj.userData[Object3DUserData.faceType]?.[face] === undefined
                  ? FaceType.Plane
                  : firstObj.userData[Object3DUserData.faceType]?.[face],
              [remainingFace[i]]:
                firstObj.userData[Object3DUserData.faceType]?.[
                  remainingFace[i]
                ] === undefined
                  ? FaceType.Plane
                  : firstObj.userData[Object3DUserData.faceType]?.[
                      remainingFace[i]
                    ],
            };
            faceTypeArray.push(faceType);
          }
          const outputFirstObject = combineObjects(faceTypeArray);
          firstObj.userData[Object3DUserData.faceType] = outputFirstObject;
          let faceTypeAdjacentArray: any[] = [];
          let faceTypeAdjacent;
          for (let i = 0; i < remainingFaceSecondobj.length; i++) {
            faceTypeAdjacent = {
              [remainingFaceSecondobj[i]]:
                secondObj.userData[Object3DUserData.faceType]?.[
                  remainingFaceSecondobj[i]
                ] === undefined
                  ? FaceType.Plane
                  : secondObj.userData[Object3DUserData.faceType]?.[
                      remainingFaceSecondobj[i]
                    ],
              [oppositeFace]:
                secondObj.userData[Object3DUserData.faceType]?.[oppositeFace] ===
                undefined
                  ? FaceType.Curved
                  : secondObj.userData[Object3DUserData.faceType]?.[oppositeFace],
            };
            faceTypeAdjacentArray.push(faceTypeAdjacent);
          }
          const outputSecondObject = combineObjects(faceTypeAdjacentArray);
          secondObj.userData[Object3DUserData.faceType] = outputSecondObject;
        } else {
          if (
            (count.Node > 0 && count.Hole === undefined) ||
            count.Node > count.Hole
          ) {
            let faceTypeArray: any[] = [];
            let faceType;
            for (let i = 0; i < remainingFace.length; i++) {
              faceType = {
                [face]:
                  firstObj.userData[Object3DUserData.faceType]?.[face] ===
                    undefined ||
                  FaceType.Plane ||
                  FaceType.Node ||
                  FaceType.Hole
                    ? FaceType.Hole
                    : firstObj.userData[Object3DUserData.faceType]?.[face],
                [remainingFace[i]]:
                  firstObj.userData[Object3DUserData.faceType]?.[
                    remainingFace[i]
                  ] === undefined
                    ? FaceType.Plane
                    : firstObj.userData[Object3DUserData.faceType]?.[
                        remainingFace[i]
                      ],
              };
              faceTypeArray.push(faceType);
            }
            const outputFirstObject = combineObjects(faceTypeArray);
            firstObj.userData[Object3DUserData.faceType] = outputFirstObject;
            let faceTypeAdjacentArray: any[] = [];
            let faceTypeAdjacent;
            for (let i = 0; i < remainingFaceSecondobj.length; i++) {
              faceTypeAdjacent = {
                [remainingFaceSecondobj[i]]:
                  secondObj.userData[Object3DUserData.faceType]?.[
                    remainingFaceSecondobj[i]
                  ] === undefined
                    ? FaceType.Plane
                    : secondObj.userData[Object3DUserData.faceType]?.[
                        remainingFaceSecondobj[i]
                      ],
                [oppositeFace]:
                  secondObj.userData[Object3DUserData.faceType]?.[
                    oppositeFace
                  ] === undefined ||
                  FaceType.Plane ||
                  FaceType.Node ||
                  FaceType.Hole
                    ? FaceType.Node
                    : secondObj.userData[Object3DUserData.faceType]?.[
                        oppositeFace
                      ],
              };
              faceTypeAdjacentArray.push(faceTypeAdjacent);
            }
            const outputSecondObject = combineObjects(faceTypeAdjacentArray);
            secondObj.userData[Object3DUserData.faceType] = outputSecondObject;
          } else {
            let faceType;
            let faceTypeArray: any[] = [];
            for (let i = 0; i < remainingFace.length; i++) {
              faceType = {
                [face]:
                  firstObj.userData[Object3DUserData.faceType]?.face ===
                    undefined ||
                  FaceType.Plane ||
                  FaceType.Node ||
                  FaceType.Hole
                    ? FaceType.Node
                    : firstObj.userData[Object3DUserData.faceType]?.Top,
                [remainingFace[i]]:
                  firstObj.userData[Object3DUserData.faceType]?.[
                    remainingFace[i]
                  ] === undefined
                    ? FaceType.Plane
                    : firstObj.userData[Object3DUserData.faceType]?.[
                        remainingFace[i]
                      ],
              };
              faceTypeArray.push(faceType);
            }
            const outputFirstObject = combineObjects(faceTypeArray);
            firstObj.userData[Object3DUserData.faceType] = outputFirstObject;
            let faceTypeAdjacentArray: any[] = [];
            let faceTypeAdjacent;
            for (let i = 0; i < remainingFaceSecondobj.length; i++) {
              faceTypeAdjacent = {
                [remainingFaceSecondobj[i]]:
                  secondObj.userData[Object3DUserData.faceType]?.[
                    remainingFaceSecondobj[i]
                  ] === undefined
                    ? FaceType.Plane
                    : secondObj.userData[Object3DUserData.faceType]?.[
                        remainingFaceSecondobj[i]
                      ],
                [oppositeFace]:
                  secondObj.userData[Object3DUserData.faceType]?.Bottom ===
                    undefined ||
                  FaceType.Plane ||
                  FaceType.Node ||
                  FaceType.Hole
                    ? FaceType.Hole
                    : secondObj.userData[Object3DUserData.faceType]?.Bottom,
              };
              faceTypeAdjacentArray.push(faceTypeAdjacent);
            }
            const outputSecondObject = combineObjects(faceTypeAdjacentArray);
            secondObj.userData[Object3DUserData.faceType] = outputSecondObject;
          }
        }
      }

      function oppositeSide(side: string): string {
        switch (side) {
          case Sides.Right:
            return Sides.Left;
          case Sides.Left:
            return Sides.Right;
          case Sides.Front:
            return Sides.Back;
          case Sides.Back:
            return Sides.Front;
          case Sides.Top:
            return Sides.Bottom;
          case Sides.Bottom:
            return Sides.Top;
          default:
            return '';
        }
      }



      function combineObjects(inputObjects: InputObject[]): OutputObject {
        const result: OutputObject = {
          Top: '',
          Bottom: '',
          Right: '',
          Left: '',
          Front: '',
          Back: '',
        };

        inputObjects.forEach((inputObj) => {
          for (const key in inputObj) {
            if (key in result) {
              result[key as keyof OutputObject] = inputObj[key]!;
            }
          }
        });

        return result;
      }


      for (let i = 1; i < objects.length; i++) {
        const order = [
          Face.Right,
          Face.Left,
          Face.Top,
          Face.Bottom,
          Face.Front,
          Face.Back,
        ];

        const connectionData: {
          face: string;
          connectionType: string;
        }[] = []

        let output = ''

        order.forEach((key) => {
          output += objects[i].userData[Object3DUserData.faceType][key][0];
          connectionData.push({
            face: key,
            connectionType: objects[i].userData[Object3DUserData.faceType][key][0]
          })
        });
        objects[i].userData[Object3DUserData.facePattern] = output;
        objects[i].userData[Object3DUserData.connectionData] = connectionData;
      }

      let facePatterns: any[] = [];
      for (let i = 1; i < objects.length; i++) {
        let object = {
          name: objects[i].name,
          facePattern: objects[i].userData[Object3DUserData.facePattern],
          color: objects[i].userData[Object3DUserData.colour],
          connectionData: objects[i].userData[Object3DUserData.connectionData]
        };
        facePatterns.push(object);
      }

      function transformInputToOutput(inputArray: CountInputData[]): CountOutputData[] {
        const outputMap = new Map<string, CountOutputData>();

        for (const item of inputArray) {
          const key = `${item.name}-${item.facePattern}-${item.color}`;
          if (outputMap.has(key)) {
            // If the key exists in the map, increment the count
            outputMap.get(key)!.count++;
          } else {
            // If the key doesn't exist, create a new entry
            outputMap.set(key, {
              name: item.name,
              facePattern: item.facePattern,
              color: item.color,
              count: 1,
              connectionData: item.connectionData
            });
          }
        }

        // Convert the map values to an array
        const outputArray = Array.from(outputMap.values());
        return outputArray;
      }

      const outputPattern: CountOutputData[] = transformInputToOutput(facePatterns);
      this.compIntSrv.setOutputPattern(outputPattern);
  }
}
