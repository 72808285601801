import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { DialogService } from "src/app/services/dialog/dialog.service";
import { AddressComponent } from "src/app/user-details/address/address.component";
import { ProfileDetailsComponent } from "src/app/user-details/profile-details/profile-details.component";
import {
  AddressDetailsCreationAttributes,
  NullableAddresses,
  UserDetailsCreationAttributes
} from "@models/user.model";
import { OperationsModel } from "@models/order.model";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ReviewStatus } from "@models/admin.model";
import { noSpacesValidator } from "../shared/no-leading-space-validator.directive";
import { DialogInvokingComponents } from "@models/generic-dialog.model";
import { StructureStatus, StructureUpdateModel } from "@models/structure.model";

@Component({
  selector: "app-generic-dialog",
  templateUrl: "./generic-dialog.component.html",
  styleUrls: ["./generic-dialog.component.scss"]
})
export class GenericDialogComponent implements OnInit {
  public DialogInvokingComponents = DialogInvokingComponents;
  public address!: AddressDetailsCreationAttributes;
  public profile!: UserDetailsCreationAttributes;
  public courierForm!: FormGroup;
  public saveStructureForm!: FormGroup;
  public todayDate = new Date();
  public sliderImageSize = { width: "100%", height: "400px" };
  public myStructureListForm: FormGroup;
  public myStructureStatus = [
    { name: "Saved", value: StructureStatus.Saved },
    { name: "Publish", value: StructureStatus.Published },
    { name: "Approved", value: StructureStatus.Approved},
    { name: "Rejected", value: StructureStatus.Rejected}
  ];
  @Output() saveClicked: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(AddressComponent) addressDetailsRef: AddressComponent | undefined;
  @ViewChild(ProfileDetailsComponent) profileDetailRef:
    | ProfileDetailsComponent
    | undefined;
  @HostBinding("class.dialog-mode") isInDialog: boolean = true;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      componentName: string;
      content: string;
      inputs: OperationsModel[];
      showTextArea: boolean;
      firstBtn: string;
      secondBtn: string;
      image: string;
      addresses: NullableAddresses;
      selectedAddressId: number;
      withUserProfileDetails?: boolean;
      hideAgeAndShowMobile: boolean;
      imageSlider: Array<object>;
      structureName: string;
      structureDescription: string;
      status: string;
      message:string
      isPublish: boolean;
    },
    public dialogRef: MatDialogRef<GenericDialogComponent>,
    private dialogService: DialogService,
    private formBuilder: FormBuilder,
    private dialog:MatDialog
  ) {
    this.data.withUserProfileDetails = this.data.withUserProfileDetails ?? true;
    this.data.hideAgeAndShowMobile = this.data.hideAgeAndShowMobile ?? false;
    this.myStructureListForm = this.formBuilder.group({
      myStructureName: ["", [Validators.required, noSpacesValidator()]],
      myStructureDescription: ["", [Validators.required, noSpacesValidator()]],
      status: [""]
    });
  }

  ngOnInit(): void {
    this.courierForm = this.formBuilder.group({});
    this.data.inputs?.forEach(input => {
      const validators = [Validators.required];
      if (input.type !== "date") {
        validators.push(noSpacesValidator());
      }
      this.courierForm.addControl(
        input.controlName,
        this.formBuilder.control(input.value, validators)
      );
    });

    if (this.data.componentName === DialogInvokingComponents.StructureDetails) {
      this.saveStructureForm = this.formBuilder.group({
        structureName: [
          this.data.structureName ? this.data.structureName : "",
          [Validators.required, noSpacesValidator()]
        ],
        structureDescription: [
          this.data.structureDescription ? this.data.structureDescription : "",
          [Validators.required, noSpacesValidator()]
        ]
      });
    } else if (this.data.componentName === DialogInvokingComponents.MyStructuresListProperties) {
      if ([StructureStatus.Published, StructureStatus.Approved, StructureStatus.Rejected].includes(this.data.status as StructureStatus)) {
        this.myStructureListForm.disable();
      }
      this.myStructureListForm
        .get("myStructureName")
        ?.setValue(this.data?.structureName);
      this.myStructureListForm
        .get("myStructureDescription")
        ?.setValue(this.data?.structureDescription);
      this.myStructureListForm.get("status")?.setValue(this.data?.status);
    }
  }

  public onAddressChange(): void {
    this.dialogRef.close(this.data.selectedAddressId);
  }

  public onAddAddress(): void {
    const data = {
      componentName: DialogInvokingComponents.AddAddress,
      title: "Add Delivery Address",
      withUserProfileDetails: false
    };
    this.dialogService.openDialog(data);
  }

  public closeDialog(): void {
    this.dialogRef.close("");
  }

  public receiveAddressData(address: AddressDetailsCreationAttributes): void {
    this.address = address;
  }

  public receiveProfileData(profile: UserDetailsCreationAttributes): void {
    this.profile = profile;
  }

  public isFormDataValid(): boolean {
    if (this.data.withUserProfileDetails) {
      return !!(
        this.profileDetailRef?.profileForm.valid &&
        this.addressDetailsRef?.addressForm.valid
      );
    } else {
      return !!this.addressDetailsRef?.addressForm.valid;
    }
  }

  private trimSpaces(): void {
    const trimControl = (controlName: string) => {
      const control = this.courierForm.get(controlName);
      if (control) {
        control.setValue(control.value.trim());
      }
    };

    this.data.inputs.forEach(input => {
      if (input.type !== "date") {
        trimControl(input.controlName);
      }
    });
  }

  public isInvalid(
    form: FormGroup,
    controlName: string,
    validatorName?: string
  ): boolean {
    const control = form.get(controlName);
    if (validatorName) {
      return control!.errors?.[validatorName];
    }
    return control!.invalid && control!.touched;
  }

  public onApprove(): void {
    this.saveClicked.emit({ action: ReviewStatus.Approved });
    this.dialogRef.close();
  }

  public onReject(): void {
    this.trimSpaces();
    if (this.courierForm.valid) {
      const reason = this.courierForm.value.reason;
      this.saveClicked.emit({ action: ReviewStatus.Rejected, reason: reason });
      this.dialogRef.close();
    }
  }

  public save(): void {
    this.trimSpaces();
    if (this.courierForm.valid) {
      const data = this.courierForm.value;
      this.saveClicked.emit(data);
      this.dialogRef.close();
    }
  }

  public updateStructureData(): void {
    const structureData: StructureUpdateModel = {
      structureName: this.myStructureListForm.get('myStructureName')?.value.trim(),
      structureDescription: this.myStructureListForm.get('myStructureDescription')?.value.trim(),
      status: this.myStructureListForm.get('status')?.value.trim()
    };
    this.dialogRef.close(structureData);
  }

  public disableStatus(statusValue:StructureStatus):boolean {
    if (this.data.status === StructureStatus.Saved) {
      return statusValue !== StructureStatus.Published;
    }
    return true;
  }

  public isStatusDisabled(): boolean {
    return [StructureStatus.Published, StructureStatus.Approved, StructureStatus.Rejected].includes(this.data.status as StructureStatus);
  }

  public closeDialogPublish(): void {
    this.dialogRef.close(true);
  }

  public confirmationDialog(): void {
    if(this.data.componentName === DialogInvokingComponents.StructureDetails && this.data.isPublish){
      const dialogRef = this.dialog.open(GenericDialogComponent, {
        data: {
          componentName: DialogInvokingComponents.MyStructuresList,
          title: "Publish My Structure",
          content:
            "Are you sure you want to publish this structure? Once published, it will be visible to all users after admin approval.",
          firstBtn: "Publish",
          secondBtn: "Cancel",
          isActionOnEnter:true
        },
        autoFocus:false,
        restoreFocus:false,
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.dialogRef.close(this.saveStructureForm.value);
          this.saveStructureForm.reset();
        }
        })
    }else{
      this.dialogRef.close(this.saveStructureForm.value);
      this.saveStructureForm.reset();
    }
  }
}
