<button mat-icon-button aria-label="Back button" (click)="redirectBack()">
  <mat-icon>arrow_back</mat-icon>
</button>

<div class="container">
  <div class="left-panel">
    <div class="aspect-wrapper">
       <designer [isViewMode]=true></designer>
    </div>

    <div class="preview" *ngIf="captureImages.length || loading">
      <div *ngFor="let img of captureImages; let i = index" class="preview-item">
        <div class="preview-image">
          <img [src]="img" [alt]="'Image' + (i + 1)">
        </div>
        <mat-icon mat-button 
        class="del-btn" 
        (click)="removeImageFromS3(i)" 
        *ngIf="structure.status === structureStatus.Published">
        delete
        </mat-icon>
      </div>
      
      <div *ngIf="loading" class="spinner-overlay">
        <mat-spinner diameter="45"></mat-spinner>
      </div>
    </div>
  </div>

  <div class="right-panel">
    <div class="product-info">
  
      <form [formGroup]="structureForm">
        <!-- Structure Name -->
        <div class="form-group">
          <label for="structureName" class="product-name-label"
            >Structure Name:<span class="required">*</span></label
          >
          <input
            id="structureName"
            formControlName="structureName"
            class="product-name"
            required
            [readOnly]="isReadOnly"
          />
        </div>
        <!-- Description -->
        <div class="form-group">
          <label for="structureDescription" class="description-label"
            >Description:<span class="required">*</span></label
          >
          <textarea
            id="structureDescription"
            formControlName="structureDescription"
            class="description"
            rows="3"
            [readOnly]="isReadOnly"
          ></textarea>
        </div>
        <!-- Actual Price  -->
        <div class="form-group">
          <label for="actualPrice" class="actual-price-label"
            >Actual Price:<span class="required">*</span></label
          >
          <input
            id="actualPrice"
            formControlName="actualPrice"
            class="actualPrice"
            type="number"
            required
            [readOnly]="isReadOnly"
          />
          <div
            *ngIf="
              structureForm.get('actualPrice')?.hasError('nonNegative') &&
              structureForm.get('actualPrice')?.touched
            "
          >
            <small class="error-text">Actual price cannot be negative</small>
          </div>
        </div>
        <!-- Discount Price -->
        <div class="form-group">
          <label for="discountedPrice" class="discounted-price-label"
            >Discounted Price:</label
          >
          <input
            id="discountedPrice"
            formControlName="discountPrice"
            class="discountedPrice"
            type="number"
            [readOnly]="isReadOnly"
          />
          <div
            *ngIf="
              structureForm.get('discountPrice')?.hasError('nonNegative') &&
              structureForm.get('discountPrice')?.touched
            "
          >
            <small class="error-text">Discount price cannot be negative</small>
          </div>
        </div>
        <!-- Model 3D JSON -->
        <div class="form-group">
          <label for="model3DJson" class="model3dJson-label">Model 3D JSON:</label>
          <textarea
            id="model3DJson"
            formControlName="model3DJson"
            (blur)="onModel3DJsonBlur($event)"
            class="model3dJson"
            rows="3"
            readonly
          ></textarea>
          <div
            *ngIf="structureForm.get('model3DJson')?.errors?.['invalidJson'] && structureForm.get('model3DJson')?.touched"
            class="text-red"
          >
            Please enter a valid json.
          </div>
        </div>
  
        <!-- Model Quantity JSON -->
        <div class="form-group">
          <label for="modelQuantityJson" class="modelQuantityJson-label">Model Quantity JSON:</label>
          <textarea
            id="modelQuantityJson"
            formControlName="modelQuantityJson"
            class="modelQuantityJson"
            rows="3"
            readonly
          ></textarea>
        </div>
  
        <!-- blocks Quantity -->
        <div class="form-group">
          <label for="blocksQuantity" class="blocks-quantity-label"
            >Blocks Quantity:</label
          >
          <input
            id="blocksQuantity"
            formControlName="blocksQuantity"
            class="blocks-quantity"
            [value]="structureForm.get('blocksQuantity')?.value"
            readonly
          />
        </div>
  
        <!-- Category -->
        <div class="form-group">
          <label for="category" class="category-label">Category:<span class="required">*</span></label>
          <input *ngIf="isReadOnly" class="category" mat-input formControlName="category" [readonly]="isReadOnly">
          <mat-select *ngIf="!isReadOnly" formControlName="category" multiple  class="category">
            <mat-option *ngFor="let category of categories" [value]="category">
              {{ category }}
            </mat-option>
          </mat-select>
        </div>
        <!-- GST Rate -->
        <div class="form-group">
          <label for="gstRate" class="gstRate-label">GST Rate (%):</label>
          <input *ngIf="isReadOnly" class="gstRate" mat-input formControlName="gstRate" [readonly]="isReadOnly">
          <mat-select id="gstRate" *ngIf="!isReadOnly" formControlName="gstRate" class="gstRate">
            <mat-option *ngFor="let rate of gstRates" [value]="rate"
              >{{ rate }}%
            </mat-option>
          </mat-select>
        </div>
      </form>
  
      <div class="btnContainer" *ngIf="structure?.status === structureStatus.Published">
        <button mat-raised-button class="save-btn" [disabled]="!isCheckApprove()"
          (click)="approveStructure()">Approve</button>
        <button mat-raised-button class="cancel-btn" (click)="rejectStructure()">Reject</button>
      </div>
  
    </div>
  </div>

</div>
