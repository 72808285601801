import { Component, OnDestroy, OnInit } from '@angular/core';
import * as THREE from 'three';
import { GenerateNewShapes } from '../shared/generate-new-shapes';
import {
  AlteredShapeConfiguration,
  Object3DUserData,
  selectedShapeColor,
  MaxMinCoordinatesConfiguiration,
} from '@utils/shape';
import { ComponentInteractionSrevice } from '../services/component-interaction/component-interaction.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HelperService } from '../shared/helpers/helper.service';
import { Vector2 } from 'three';
import { DynamicGenerateShape } from '../shared/face-identifier/face-identifier.model';
import { MatSnackBarConfg } from '../shared/mat-snackbar/mat-snackbar.model';
import { FaceIdentifierService } from '../shared/face-identifier/face-identifier.service';
import { AvailableShapes, setFaceTypeForShape, tiltedShapeConfiguration } from '@utils/shape-facetype';
import { Subscription } from "rxjs";

@Component({
  selector: 'generate-shape',
  templateUrl: './generate-shape.component.html',
  styleUrls: ['./generate-shape.component.scss'],
})
export class GenerateShapeComponent implements OnInit, OnDestroy {
  private intersects: THREE.Intersection<THREE.Object3D<THREE.Event>>[] = [];
  private selectedShape = '';
  private coOrdinates: THREE.Vector3 = new THREE.Vector3();
  private locationX = 0;
  private locationY = 0;
  private locationZ = 0;
  private nearestGridCords: THREE.Vector2 = new THREE.Vector2();
  private invalidJoin = false;
  private invalidPointGeneration = false;
  private generatedShape: THREE.Mesh<
    THREE.BufferGeometry,
    THREE.Material | THREE.Material[]
  > = new THREE.Mesh();
  private joinedToRightFaceOfShape = false;
  private joinedToLeftFaceOfShape = false;
  private joinedToTopFaceOfShape = false;
  private joinedToBottomFaceOfShape = false;
  private joinedToFrontFaceOfShape = false;
  private joinedToBackFaceOfShape = false;
  public selectedColor: THREE.ColorRepresentation | undefined;
  public isTilted!: boolean
  private interceptInfoSub!: Subscription;
  private selectedShapeSub!: Subscription;
  private selectedColorSub!: Subscription;

  constructor(
    private generateNewShapes: GenerateNewShapes,
    private componetInteractionSrv: ComponentInteractionSrevice,
    private snackBar: MatSnackBar,
    private helperService: HelperService,
    private faceIdentifierSrv: FaceIdentifierService
  ) { }

  ngOnInit(): void {
    this.interceptInfoSub = this.componetInteractionSrv.getInterceptInfo().subscribe((intersects) => {
      this.invalidJoin = false;
      this.invalidPointGeneration = false;
      this.intersects = intersects;
      if (this.intersects[0]) {
        this.addShapeOnPlane();
      }
    });
    this.selectedShapeSub = this.componetInteractionSrv.getSeletedShape().subscribe((shape) => {
      this.selectedShape = shape;
      this.invalidJoin = false;
    });
    this.selectedColorSub = this.componetInteractionSrv.getSelectedColor().subscribe((color) => {
      this.selectedColor = color;
    });
  }

  private addShapeOnPlane(): void {
    let position = this.intersects[0].object.position;
    let point = this.intersects[0].point;

    let objectName = this.intersects[0].object.name;
    if (objectName === AvailableShapes.Plane) {
      this.locationX = point.x; // GIVES THE POINT OF INTERSECTION IN WORLD COORDS
      this.locationY = point.y;
      this.locationZ = point.z;

      // Here we make the shape sit on the grid avoiding them to go inside.
      this.locationY = this.helperService.getGroundLocationY(this.locationY);
    } else {
      this.locationX = point.x; // GIVES THE POINT OF INTERSECTION IN WORLD COORDS
      this.locationY = point.y;
      this.locationZ = point.z;

      if (this.faceIdentifierSrv.invalidPointOfGeneration) {
        this.invalidPointGeneration = true;
      } else if (this.faceIdentifierSrv.rightFace) {
        if (this.faceIdentifierSrv.invalidJoin) {
          this.invalidJoin = true;
        } else {
          this.invalidJoin = false;
          this.invalidPointGeneration = false;
          this.joinedToRightFaceOfShape = true;
          this.joinedToLeftFaceOfShape = false;
          this.joinedToFrontFaceOfShape = false;
          this.joinedToBackFaceOfShape = false;
          this.joinedToBottomFaceOfShape = false;
          this.joinedToTopFaceOfShape = false;
          this.locationX =
            this.locationX + DynamicGenerateShape.AdjustmentConstant;
        }
        this.locationY = position.y;
      } else if (this.faceIdentifierSrv.leftFace) {
        if (this.faceIdentifierSrv.invalidJoin) {
          this.invalidJoin = true;
        } else {
          this.invalidJoin = false;
          this.invalidPointGeneration = false;
          this.locationX =
            this.locationX - DynamicGenerateShape.AdjustmentConstant;
          this.locationY = position.y;
          this.joinedToRightFaceOfShape = false;
          this.joinedToLeftFaceOfShape = true;
          this.joinedToFrontFaceOfShape = false;
          this.joinedToBackFaceOfShape = false;
          this.joinedToBottomFaceOfShape = false;
          this.joinedToTopFaceOfShape = false;
        }
      } else if (this.faceIdentifierSrv.topFace) {
        if (this.faceIdentifierSrv.invalidJoin) {
          this.invalidJoin = true;
        } else {
          this.invalidJoin = false;
          this.invalidPointGeneration = false;
          this.locationY =
            this.locationY + DynamicGenerateShape.AdjustmentConstant;
          this.joinedToRightFaceOfShape = false;
          this.joinedToLeftFaceOfShape = false;
          this.joinedToFrontFaceOfShape = false;
          this.joinedToBackFaceOfShape = false;
          this.joinedToBottomFaceOfShape = false;
          this.joinedToTopFaceOfShape = true;
        }
      } else if (this.faceIdentifierSrv.bottomFace) {
        if (this.faceIdentifierSrv.invalidJoin) {
          this.invalidJoin = true;
        } else {
          this.invalidJoin = false;
          this.invalidPointGeneration = false;
          this.locationY =
            this.locationY - DynamicGenerateShape.AdjustmentConstant;
          this.joinedToRightFaceOfShape = false;
          this.joinedToLeftFaceOfShape = false;
          this.joinedToFrontFaceOfShape = false;
          this.joinedToBackFaceOfShape = false;
          this.joinedToBottomFaceOfShape = true;
          this.joinedToTopFaceOfShape = false;
        }
      } else if (this.faceIdentifierSrv.frontFace) {
        if (this.faceIdentifierSrv.invalidJoin) {
          this.invalidJoin = true;
        } else {
          this.invalidJoin = false;
          this.invalidPointGeneration = false;
          this.locationZ =
            this.locationZ + DynamicGenerateShape.AdjustmentConstant;
          this.locationY = position.y;
          this.joinedToRightFaceOfShape = false;
          this.joinedToLeftFaceOfShape = false;
          this.joinedToFrontFaceOfShape = true;
          this.joinedToBackFaceOfShape = false;
          this.joinedToBottomFaceOfShape = false;
          this.joinedToTopFaceOfShape = false;
        }
      } else if (this.faceIdentifierSrv.backFace) {
        if (this.faceIdentifierSrv.invalidJoin) {
          this.invalidJoin = true;
        } else {
          this.invalidJoin = false;
          this.invalidPointGeneration = false;
          this.locationZ =
            this.locationZ - DynamicGenerateShape.AdjustmentConstant;
          this.locationY = position.y;
          this.joinedToRightFaceOfShape = false;
          this.joinedToLeftFaceOfShape = false;
          this.joinedToFrontFaceOfShape = false;
          this.joinedToBackFaceOfShape = true;
          this.joinedToBottomFaceOfShape = false;
          this.joinedToTopFaceOfShape = false;
        }
      } else if (this.faceIdentifierSrv.invalidJoin) {
        this.invalidJoin = true;
      }
    }
    this.nearestGridCords = this.helperService.getNearestGridCords(
      this.selectedShape,
      new Vector2(this.locationX, this.locationZ)
    );

    if (this.selectedShape === AvailableShapes.CurvedCube2) {
      this.nearestGridCords.x + 1;
    }
    if (
      this.intersects[0].object.name === AvailableShapes.Plane &&
      this.selectedShape === AvailableShapes.HalfCylinder
    ) {
      this.locationY -= AlteredShapeConfiguration.yOffsetForHalfcylinder;
    } else if (
      this.intersects[0].object.name === AvailableShapes.Plane &&
      this.selectedShape === AvailableShapes.Prism
    ) {
      this.locationY -= AlteredShapeConfiguration.yOffsetForPrism;
    } else if (
      this.intersects[0].object.name !== AvailableShapes.Plane &&
      this.intersects[0].object.name !== AvailableShapes.HalfCylinder &&
      this.selectedShape === AvailableShapes.HalfCylinder
    ) {
      this.locationY -= AlteredShapeConfiguration.yOffsetForHalfcylinder;
    } else if (
      this.intersects[0].object.name !== AvailableShapes.Plane &&
      this.intersects[0].object.name !== AvailableShapes.Prism &&
      this.selectedShape === AvailableShapes.Prism
    ) {
      this.locationY -= AlteredShapeConfiguration.yOffsetForPrism;
    }

    this.coOrdinates = new THREE.Vector3(
      this.nearestGridCords.x,
      this.locationY,
      this.nearestGridCords.y
    );

    let selectedShapeColour = this.selectedColor
      ? this.selectedColor
      : selectedShapeColor.code;
    if (
      this.coOrdinates.z >= -MaxMinCoordinatesConfiguiration.constant &&
      this.coOrdinates.z <= MaxMinCoordinatesConfiguiration.constant &&
      this.coOrdinates.x >= -MaxMinCoordinatesConfiguiration.constant &&
      this.coOrdinates.x <= MaxMinCoordinatesConfiguiration.constant
    ) {
      if (this.invalidJoin) {
        this.snackBar.open(
          MatSnackBarConfg.ErrorMessage2,
          MatSnackBarConfg.CloseButton,
          {
            duration: MatSnackBarConfg.MessageDisplayDuration,
          }
        );
      } else if (this.invalidPointGeneration) {
        this.snackBar.open(
          MatSnackBarConfg.ErrorMessage3,
          MatSnackBarConfg.CloseButton,
          {
            duration: MatSnackBarConfg.MessageDisplayDuration,
          }
        );
      } else if (
        !this.invalidJoin &&
        this.selectedShape &&
        !this.invalidPointGeneration
      ) {
        this.generateShape(
          this.coOrdinates,
          this.selectedShape,
          selectedShapeColour
        );
      } else {
        this.snackBar.open(
          MatSnackBarConfg.ErrorMessage1,
          MatSnackBarConfg.CloseButton,
          {
            duration: MatSnackBarConfg.MessageDisplayDuration,
          }
        );
      }
    } else {
      this.snackBar.open(
        MatSnackBarConfg.ErrorMessage4,
        MatSnackBarConfg.CloseButton,
        {
          duration: MatSnackBarConfg.MessageDisplayDuration,
        }
      );
    }
  }

  private generateShape(
    coOrdinates: THREE.Vector3,
    shapeType: string,
    shapeColor: THREE.Color | string | number,
  ): void {
    this.handlegenerationOfShapes(coOrdinates, shapeType, shapeColor);
    if (
      this.faceIdentifierSrv.objectHit &&
      this.faceIdentifierSrv.objectHit.name === AvailableShapes.Prism
    ) {
      if (this.faceIdentifierSrv.rightFace) {
        this.generatedShape.rotateZ(2.02);
        this.generatedShape.position.y = this.generatedShape.position.y + 7;
        this.generatedShape.position.x = this.generatedShape.position.x - 6;
        this.generatedShape.userData[Object3DUserData.isTilted] = true;
        this.generatedShape.userData[Object3DUserData.initialPosition] = this.generatedShape.position
      } else if (this.faceIdentifierSrv.leftFace) {
        this.generatedShape.rotateZ(-2.02);
        this.generatedShape.position.y = this.generatedShape.position.y + 7;
        this.generatedShape.position.x = this.generatedShape.position.x + 6;
        this.generatedShape.userData[Object3DUserData.isTilted] = true;
        this.generatedShape.userData[Object3DUserData.initialPosition] = this.generatedShape.position
      }
    }

    const { row, column, cell } = this.helperService.calculateRowAndColumn(
      this.generatedShape as THREE.Mesh
    );

    this.generatedShape.userData[Object3DUserData.colour] = shapeColor;
    this.generatedShape.userData[
      Object3DUserData.initialPosition
    ] = new THREE.Vector3();
    this.generatedShape.userData[Object3DUserData.initialPosition].copy(
      this.generatedShape.position
    );

    this.generatedShape.userData[Object3DUserData.isDraggable] = true;
    this.generatedShape.userData[
      Object3DUserData.initialMaterial
    ] = this.generatedShape.material;
    this.generatedShape.userData[Object3DUserData.isInCollision] = false;
    this.generatedShape.userData[Object3DUserData.collidedObjectId] = ""; // initially we wont be having any collided object
    const cellReference = {
      row: row,
      column: column,
      cell: cell
    };

    const shapeName = this.generatedShape.name;
    const faceType = setFaceTypeForShape(shapeName);

    if (Object.keys(faceType).length > 0) {
      this.generatedShape.userData[Object3DUserData.faceType] = faceType;
    }
    this.generatedShape.userData[
      Object3DUserData.cellReference
    ] = cellReference;
    this.componetInteractionSrv.setGeneratedShapeInfo(this.generatedShape);
  }

  private handlegenerationOfShapes(
    coOrdinates: THREE.Vector3,
    shapeType: string,
    shapeColor: any
  ): void {
    const objectHit = this.faceIdentifierSrv.objectHit;

    const generateAndTiltShape = (
      offsetX: number,
      offsetY: number,
      rotation: number
    ) => {
      if (objectHit) {
        coOrdinates.x = objectHit.position.x + offsetX;
        coOrdinates.y = objectHit.position.y + offsetY;
      }
      this.generatedShape = this.generateNewShapes.generateNewShapes(
        coOrdinates,
        shapeType,
        shapeColor
      );
      this.generatedShape.rotateZ(rotation);
      this.generatedShape.userData[Object3DUserData.isTilted] = true;
      this.generatedShape.userData[Object3DUserData.initialPosition] = this.generatedShape.position
      this.generatedShape.userData[Object3DUserData.rotationData]
    };

    if (
      objectHit &&
      objectHit.name === AvailableShapes.Cube &&
      objectHit.userData[Object3DUserData.isTilted]
    ) {
      const offset = {
        x: 0,
        y: tiltedShapeConfiguration.defaultOffsetY,
        rotation: 0
      };

      if (this.faceIdentifierSrv.rightFace) {
        generateAndTiltShape(
          tiltedShapeConfiguration.offsetY,
          offset.y,
          tiltedShapeConfiguration.rotationforRightAndLeftFace
        );
      } else if (this.faceIdentifierSrv.leftFace) {
        generateAndTiltShape(
          -tiltedShapeConfiguration.offsetY,
          offset.y,
          -tiltedShapeConfiguration.rotationforRightAndLeftFace
        );
      } else if (this.faceIdentifierSrv.topFace) {
        generateAndTiltShape(
          objectHit.rotation.z ===
            tiltedShapeConfiguration.rotationforRightAndLeftFace
            ? -tiltedShapeConfiguration.offsetX
            : tiltedShapeConfiguration.offsetX,
          tiltedShapeConfiguration.offsetY,
          objectHit.rotation.z
        );
      } else if (this.faceIdentifierSrv.bottomFace) {
        generateAndTiltShape(
          objectHit.rotation.z ===
            tiltedShapeConfiguration.rotationforRightAndLeftFace
            ? tiltedShapeConfiguration.offsetX
            : -tiltedShapeConfiguration.offsetX,
          -tiltedShapeConfiguration.offsetY,
          objectHit.rotation.z
        );
      } else if (
        this.faceIdentifierSrv.frontFace ||
        this.faceIdentifierSrv.backFace
      ) {
        generateAndTiltShape(0, 0, objectHit.rotation.z);
      }
    } else {
      this.generatedShape = this.generateNewShapes.generateNewShapes(
        coOrdinates,
        shapeType,
        shapeColor
      );
    }
  }

  ngOnDestroy(): void {
    this.interceptInfoSub.unsubscribe();
    this.selectedShapeSub.unsubscribe();
    this.selectedColorSub.unsubscribe();
  }
}
