<div>
  <!-- Header -->
   <div class="my-structure-container">
    <div class="structure-heading">
      <p>My Structures</p>
    </div>
      
     <div class="header-container">
       <!-- Search Structures -->
       <div class="search-content">
         <div class="search-product">
           <input 
             placeholder="Search" 
             class="search-input" 
             autocomplete="off" 
             #searchInput 
             (input)="sendDataToFilterStructure(searchInput.value)"
             (keydown.enter)="filterStructures(searchInput.value)"
           />
           <span><mat-icon class="search-icon" (click)="filterStructures(searchInput.value)">search</mat-icon></span>
         </div>
       </div>
       <!-- Select -->
       <div class="checkbox-container">
         <mat-checkbox 
         [(ngModel)]="selectAllCheckbox"
         [indeterminate]="isIndeterminate"
         (change)="toggleAllCheckboxes($event)"></mat-checkbox>
         <span *ngIf="!showCheckboxes" class="s-text">Select</span>
         <select *ngIf="showCheckboxes" class="delete-or-publish" (change)="onSelectionChange($event)">
          <option value="0">Select</option>
          <option value="1">Publish</option>
          <option value="2">Delete</option>
        </select>
       </div>
       <!-- Filter -->
       <div class="filter-menu" *ngIf="!showCheckboxes">
         <mat-icon [matMenuTriggerFor]="status">filter_alt</mat-icon>
         <span class="small-text">Filter</span>
        </div>
        <mat-menu #status="matMenu">
          <button mat-menu-item (click)="filterStatus('All')">All</button>
          <button mat-menu-item (click)="filterStatus('Saved')">Saved</button>
          <button mat-menu-item (click)="filterStatus('Published')">Published</button>
          <button mat-menu-item (click)="filterStatus('Approved')">Approved</button>
          <button mat-menu-item (click)="filterStatus('Rejected')">Rejected</button>
         </mat-menu>
      </div>
   </div>
  <!-- Cards -->
  <mat-spinner *ngIf="loading" class="loading-spinner" [diameter]="70"></mat-spinner>
  <app-my-structures-list
   *ngIf="myStructuresData"
    [structuresData]="structures"
    [showCheckboxes]="showCheckboxes"
    (loadMore)="loadMoreMyStructures($event)"
    (checkboxChange)="onChildCheckboxChange($event)"
  ></app-my-structures-list>
</div>
