<mat-spinner *ngIf="loading" class="loading-spinner" [diameter]="70"></mat-spinner>
<div *ngIf="!loading && visibleOrders && visibleOrders.length > 0; else noOrders">
  <div
    *ngFor="let order of visibleOrders; let i = index"
    class="order-container"
  >
    <div class="order-details" (click)="viewOrderDetails(order)">
      <div class="content">
        <div class="main-card">
          <div class="left-section">
            <span><strong>Order ID: </strong>#{{ order.id }}</span>
            <a [routerLink]="['/orderDetails', order.id]" class="view"
              >View details</a
            >
          </div>
          <div class="right-section">
            <div class="status">
              <p>
                Order Status:
                <span
                  [ngClass]="{
                    failed: order.orderStatus === orderStatus.Failed,
                    pending: order.orderStatus === orderStatus.Pending,
                    ordered: order.orderStatus === orderStatus.Ordered,
                    packed: order.orderStatus === orderStatus.Packed,
                    cancelled: order.orderStatus === orderStatus.Cancelled,
                    dispatched: order.orderStatus === orderStatus.Dispatched,
                    delivered: order.orderStatus === orderStatus.Delivered,
                    returnInitiated: order.orderStatus === orderStatus.ReturnInitiated,
                    pickupScheduled: order.orderStatus === orderStatus.PickupScheduled,
                    returned: order.orderStatus === orderStatus.Returned,
                  }"
                  >{{ order.orderStatus }}</span
                >
              </p>
            </div>
            <p>
              Payment Status:
              <span
                [ngClass]="{
                  pending: order.paymentStatus === paymentStatus.Pending,
                  success: order.paymentStatus === paymentStatus.Success,
                  failed: order.paymentStatus === paymentStatus.Failed
                }"
                >{{ order.paymentStatus }}</span
              >
            </p>
            <p *ngIf="order.refundStatus">
              Refund Status :
              <span
                [ngClass]="{
                  refundInitiated:
                    order.refundStatus === refundStatus.RefundInitiated,
                  refundProcessed:
                    order.refundStatus === refundStatus.RefundProcessed,
                  refundSuccessful:
                    order.refundStatus === refundStatus.RefundSuccessful,
                  refundFailed: order.refundStatus === refundStatus.RefundFailed
                }"
                >{{ order.refundStatus }}</span
              >
            </p>
          </div>
        </div>
        <div>
          <div
            *ngIf="order.paymentStatus === orderStatus.Pending"
            class="info-text"
          >
            The payment status will update within 20 minutes.
          </div>
          <div
            *ngIf="order.paymentStatus === orderStatus.Failed"
            class="info-text"
          >
            If the amount is deducted from your account, it will be refunded to
            your account within 5-7 working days.
          </div>
        </div>
      </div>

      <div class="sub-card">
        <div class="sub-left">
          <p>
            {{ order.products[0].name }}
            <span *ngIf="order.products.length > 1"
              >+ {{ getTotalItemsLeft(order) }}</span
            >
          </p>
          <div class="button-container">
            <button
              class="save-btn"
              mat-raised-button
              (click)="returnOrder($event, order)"
              *ngIf="isCancellableOrReturnOrder(order)"
            >
              {{ isCancellableOrReturnOrder(order) }}
            </button>
            <button
              class="help-btn"
              mat-raised-button
              (click)="openHelpDialog($event, order)"
            >
              Help
            </button>
          </div>
        </div>
        <div class="sub-right">Total Amount: ₹{{ order.totalAmount }}</div>
      </div>
    </div>
  </div>
</div>

<button
  class="view"
  *ngIf="showViewMoreButton()"
  mat-raised-button
  (click)="loadMoreOrders()"
>
  View More
</button>

<ng-template #noOrders>
  <div class="no-orders-card" *ngIf="!loading">
    <div class="no-orders-container">
      <img
        class="search"
        src="https://funfigz-assets-public.s3.ap-south-1.amazonaws.com/seach-lens.png"
      />
      <h2>NO ORDER FOUND</h2>
      <p class="no-orders">Looks like you haven't made your order yet.</p>
      <button
        class="save-btn"
        mat-raised-button
        (click)="goBackToProducts()"
      >
        Back to Products
      </button>
    </div>
  </div>
</ng-template>
