import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ProductService } from "../../services/product/product.service";
import { Images, ReviewValues } from "@models/product.model";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ReviewStatus } from "@models/admin.model";
import { MatSnackBar } from "@angular/material/snack-bar";
import { S3UploadService } from "../../services/s3-upoad/s3-upload.service";
import { S3UploadCategoryName } from "@models/fileUpload.model";

@Component({
  selector: "app-rating-and-review",
  templateUrl: "./rating-and-review.component.html",
  styleUrls: ["./rating-and-review.component.scss"]
})
export class RatingAndReviewComponent {
  public ratingAndReview: FormGroup;
  public images: Images = { imagesUrl: [] };
  public loading: boolean = false;
  public stars: number[] = [1, 2, 3, 4, 5];

  constructor(
    public _dialogRef: MatDialogRef<RatingAndReviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public productService: ProductService,
    public fromBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private s3UploadService: S3UploadService
  ) {
    this.ratingAndReview = this.fromBuilder.group({
      review: [""],
      rating: ["", Validators.required]
    })
  }

  // upload image
  public onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (!input.files) return;

    const files = Array.from(input.files);
    const newImages = files.slice(0, 5 - this.images.imagesUrl.length);

    newImages.forEach(file => {
      const fileUrl = URL.createObjectURL(file);
      if (!this.images.imagesUrl.includes(fileUrl)) {
        this.images.imagesUrl.push(fileUrl);
      }
    });
    input.value = '';
  }

  public removeImage(index: number): void {
    this.images.imagesUrl.splice(index, 1);
    const input = document.querySelector('input[type="file"]') as HTMLInputElement;
    input.value = '';
  }

  public async submitReview(): Promise<void> {
    const reviewValue = this.ratingAndReview.get('review')?.value.trim();
    const reviewStatus: string = reviewValue !== '' ? ReviewStatus.Pending : ReviewStatus.Approved;
    let s3ImageUrls: string[] = [];
    try {
      this.loading = true;
      if (this.images.imagesUrl.length) {
        const imageFiles = await Promise.all(
          this.images.imagesUrl.map(async (url) =>
            this.urlToFile(url))
        );
        s3ImageUrls = await this.s3UploadService.uploadImagesToS3(imageFiles, S3UploadCategoryName.RatingAndReview);

      }
      this.images.imagesUrl = s3ImageUrls;

      let reviewValues: ReviewValues = {
        productId: this.data.product_details?.id,
        orderId: this.data.order_details?.id,
        rating: this.ratingAndReview.get('rating')?.value,
        review: reviewValue,
        status: reviewStatus,
        name: this.data.order_details?.customerName,
        images: this.images,
        userId: this.data.userId,
      };

      this.productService.addReview(reviewValues).subscribe({
        next: response => {
          this.snackBar.open(response.message, "Okay", {
            duration: 3000
          });
          this.loading = false;
          this._dialogRef.close(true);
        }, error: error => {
          this.loading = false;
          this.snackBar.open(error.message, "Okay", {
            duration: 3000
          });
        }
      })
    } catch (error) {
      this.loading = false;
    }
  }

  private urlToFile(url: string): Promise<File> {
    return new Promise((resolve, reject) => {
      fetch(url)
        .then(response => response.blob())
        .then(blob => {
          const file = new File([blob], url.split('/').pop() || 'image.jpg');
          resolve(file);
        })
        .catch(reject);
    });
  }

  public selectRating(rating: number): void {
    const currentRating = this.ratingAndReview.get('rating')?.value;
    if (currentRating === rating) {
      this.ratingAndReview.get('rating')?.setValue(null);
    } else {
      this.ratingAndReview.get('rating')?.setValue(rating);
    }
  }
}
