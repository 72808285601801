<div class="wrapper">
  <div class="top-container">
    <!-- LeftSection -->
    <div class="left-section">
      <!-- Personal Info -->
      <div class="card" *ngIf="!isLoggedIn">
        <div class="already-registered">
          <span
            >Already have an account?
            <span class="login-text" (click)="onLogin()"> Login </span></span
          >
        </div>
      </div>

      <!-- Shipping Address Card -->
      <div class="card">
        <div class="shipping-address-container">
          <div class="row card-title">
            <div
              class="address-content-heading"
              *ngIf="selectedAddress; else noAddress"
            >
              <span>
                Deliver to:
              </span> &nbsp;
              <span class="recipient-info">
                {{ selectedAddress!.name }}, {{ selectedAddress!.pincode }}
                <span class="address-type">{{
                  selectedAddress!.addressType
                }}</span></span
              >
            </div>
            <ng-template #noAddress
              ><p class="card-title">Shipping Address:</p>
            </ng-template>
            <div
              class="add-address-button-container"
              *ngIf="selectedAddress; else addAddress"
            >
              <button
                mat-flat-button
                class="save-btn"
                (click)="onManageAddress()"
              >
                Change
              </button>
            </div>
            <ng-template #addAddress>
              <div class="change-address-button-container">
                <button
                  mat-flat-button
                  class="save-btn"
                  (click)="onManageAddress()"
                >
                  Add
                </button>
              </div>
            </ng-template>
          </div>

          <div class="row">
            <div class="shipping-address-body" *ngIf="selectedAddress">
              <p>
                {{ selectedAddress.houseNumber }},
                {{ selectedAddress.address }}
              </p>
              <p>{{ selectedAddress.city }}, {{ selectedAddress.state }}</p>
              <p>{{ selectedAddress.mobileNumber }}</p>
            </div>
          </div>
        </div>
      </div>

      <!-- Items Card -->
      <div class="card">
        <div class="items-container">
          <p class="card-title">{{ title }}</p>
          <div class="product-card-container">
            <ng-container *ngFor="let item of cartItems; let last = last">
              <div class="checkout-product-card">
                <div class="product-main">
                  <p
                    class="product-name"
                    [matTooltip]="item.product!.name"
                    [matTooltipShowDelay]="500"
                    [matTooltipHideDelay]="500"
                    [matTooltipDisabled]="!isOverflow(productName)"
                    #productName
                  >
                    {{ item.product!.name }}
                  </p>
                  <p>Qty: {{ item.quantity }}</p>
                </div>
                <div class="product-price">
                  <p *ngIf="item.product!.discountPrice; else noDiscount">
                    <s>₹ {{ item.product!.price }} </s>

                    <span> &nbsp; ₹ {{ item.product!.discountPrice }}</span>
                  </p>
                  <ng-template #noDiscount>
                    <p>₹ {{ item.product!.price }}</p>
                  </ng-template>
                </div>
              </div>
              <!-- Divider -->
              <div class="divider" *ngIf="!last"></div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <!-- Right Section -->
    <div class="right-section">
      <div class="card sticky">
        <div class="order-summary-container">
          <p class="card-title">Order Summary:</p>
          <div class="column">
            <div class="payment-details-container">
              <div class="payment-details-row">
                <p class="label">Subtotal</p>
                <p class="value">₹ {{ subtotal }}</p>
              </div>

              <div class="payment-details-row">
                <p class="label">Discount</p>
                <p class="value">- ₹ {{ discount }}</p>
              </div>

              <div class="payment-details-row">
                <p class="label">Shipping</p>
                <p class="value">
                  {{ deliveryCharge ? "₹ " + deliveryCharge : "FREE" }}
                </p>
              </div>

              <!-- Divider -->
              <div class="divider"></div>

              <!-- Estimated Total -->
              <div class="payment-details-row total-amount">
                <p class="label">Total</p>
                <p class="value">₹ {{ total }}</p>
              </div>

              <!-- Divider -->
              <div class="divider"></div>

              <div class="payment-details-row savings" *ngIf="discount">
                <p>You will save ₹ {{ discount }} on this order</p>
              </div>

              <!-- Agreement -->
              <div class="agreement">
                <p>By placing an order, you agree to the funfigz's</p>
                <p>
                  <span class="link" (click)="onTermsAndCondition()"
                    >Terms and Conditions</span
                  >
                  and
                  <span class="link" (click)="onPrivacyPolicy()"
                    >Privacy Policy</span
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="bottom-container sticky">
    <div class="left-section">
      <div class="pay-now-panel"
      [ngStyle]="{ 'justify-content': isPayNowEnabled ? 'flex-end' : 'space-between' }">
        <div *ngIf="!isPayNowEnabled" class="min-purchase-message">
          The minimum purchase amount is Rs {{ minOrderValue }}. Please add more items to your cart.
        </div>
        <button
          mat-flat-button
          class="pay-now-button"
          (click)="
            paymentMode === 'paytm' ? makePaytmPayment() : makePhonepePayment()
          "
          [disabled]="!(currentUser && selectedAddress) || !isPayNowEnabled"
        >
          Pay Now
        </button>
      </div>
    </div>
    <div class="right-section"></div>
  </div>
</div>
