import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { tap, switchMap, catchError, of, Observable, forkJoin } from "rxjs";
import { OrderService } from "src/app/services/order/order.service";
import { PaymentService } from "src/app/payment/services/payment/payment.service";
import { CheckoutService } from "src/app/services/checkout-page/checkout.service";
import { environment } from "src/environments/environment";
import {
  OrderAttributes,
  OrderErrorMessage,
  OrderInput
} from "@models/order.model";
import {
  InitiatePaymentInput,
  InitiatePaymentResponse
} from "@models/payment.model";
import { AuthService } from "src/app/services/auth/auth.service";
import { UserService } from "src/app/services/user/user.service";
import {
  AddressDetailsCreationAttributes,
  NullableAddress,
  NullableAddresses,
  NullableUser,
  UserDetailStatus,
  UserDetailsCreationAttributes
} from "@models/user.model";
import { DialogService } from "src/app/services/dialog/dialog.service";
import { v4 as uuidv4 } from "uuid";
import { ProductDetails } from "src/models/product.model";
import { InitiatePhonePePaymentInput } from "@models/phonepe.model";
import { CartItemService } from "../../services/cart-item/cart-item.service";
import { CartItem } from "@models/cart.model";
import { DialogInvokingComponents } from "@models/generic-dialog.model";
import { appConfig } from '../../../environments/app.config';

@Component({
  selector: "app-checkout-page",
  templateUrl: "./checkout-page.component.html",
  styleUrls: ["./checkout-page.component.scss"]
})
export class CheckoutPageComponent implements OnInit {
  public isLoggedIn = false;
  public currentUser?: NullableUser;
  public currentUserAddresses?: NullableAddresses;
  public selectedAddress?: NullableAddress;
  public cartItems: CartItem[] = [];
  public cartProducts: ProductDetails[] = [];
  public loading = false; // State to control spinner
  public orderData!: OrderInput;
  public subtotal: number = 0;
  public discount: number = 0;
  public deliveryCharge: number = 0;
  public total: number = 0;
  public paymentMode = environment.payment.mode;
  public title!: string;
  public isPayNowEnabled = false;
  public minOrderValue = appConfig.minOrderValue;

  constructor(
    private router: Router,
    private paymentService: PaymentService,
    private snackBar: MatSnackBar,
    private orderService: OrderService,
    private authService: AuthService,
    private userService: UserService,
    private checkoutService: CheckoutService,
    private cdr: ChangeDetectorRef,
    private dialogService: DialogService,
    private cartService: CartItemService,
  ) {}

  ngOnInit() {
    // Check whether it is logged in or not
    this.isLoggedIn = this.authService.isUserLoggedIn;
    this.isLoggedIn
      ? this.getRegisteredUserDetails()
      : this.getGuestUserDetails();

    //Retrieve cart products
    const product = history.state.productData;
    
    if (product) {
      this.loadPassedItem();
    }
    else {
      this.loadCartItems();
    }

    this.title = product ? "Item(s)" : "Items in Cart";
  }

  private loadPassedItem(): void {
    const productData = history.state.productData;
    if (productData && productData.length > 0) {
      this.cartItems = productData;
      this.cartProducts = this.cartItems.map((item) => item.product!);

      this.cdr.detectChanges();
    }

  // Ensure cartProducts are initialized before calculating summary
    if (this.cartProducts && this.cartProducts.length > 0) {
      this.calculateOrderSummary();
    }
  }

  private loadCartItems(): void {
    this.cartService.getCartItems().subscribe((items) => {
      this.cartItems = items;
      this.cartProducts = this.cartItems.map((item) => item.product!);
      this.cdr.detectChanges();

      this.calculateOrderSummary();
    })
  }

  private calculateOrderSummary(): void {
    this.cartService.calculateTotalMRP(this.cartItems);
    this.subtotal = this.cartService.totalMRP;
    this.discount = this.cartService.totalDiscount;
    this.total = this.cartService.totalAmount;
    this.isPayNowEnabled = this.total >= appConfig.minOrderValue;
  }

  private getGuestUserDetails(): void {
    const address = this.checkoutService.getAddressFromLocalStorage();
    if (address) this.selectedAddress = address;
    this.currentUser = this.checkoutService.getCurrentUserFromLocalStorage();
  }

  // Registered Users: Get Details from Database
  private getRegisteredUserDetails(): void {
    this.userService.getUserDetails().subscribe(response => {
      if (response) {
        this.currentUser = response.user;
        this.currentUserAddresses = response.addresses;
        this.selectedAddress = response.addresses![
          response.addresses?.length! - 1
        ];
      }
    });
  }

  private prepareOrderData(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const products = this.cartItems.map(item => ({
        id: item.product!.id,
        quantity: item.quantity
      }));

      this.orderData = {
        products,
        totalAmount: this.total,
        deliveryCharge: this.deliveryCharge,
        customerName: this.currentUser!.name,
        customerEmail: this.currentUser!.email,
        customerMobile: this.currentUser!.userMobileNumber!,
        recipientName: this.selectedAddress?.name || "",
        recipientMobileNumber: this.selectedAddress?.mobileNumber || "",
        recipientAddressType: this.selectedAddress?.addressType || "",
        recipientAddress: this.selectedAddress?.address || "",
        recipientCity: this.selectedAddress?.city || "",
        recipientState: this.selectedAddress?.state || "",
        recipientPincode: this.selectedAddress?.pincode || 0,
        recipientHouseNumber: this.selectedAddress?.houseNumber || ""
      };
      resolve();
    });
  }

  // Create Order Record and inititate payment
  public makePaytmPayment(): void {
    this.loading = true;

    this.prepareOrderData().then(() => {
      this.orderService
        .createOrder(this.orderData)
        .pipe(
          tap(this.validateOrderCreation),
          switchMap(newOrder => this.preparePaymentData(newOrder)),
          switchMap(paymentData =>
            this.paymentService.initiatePayment(paymentData)
          ),
          tap(this.handleInitiatePaymentResponse),
          catchError(this.handleError)
        )
        .subscribe({
          next: () => {
            this.loading = false;
          },
          error: () => {
            this.loading = false; // Stop spinner in case of subscription error
          }
        });
    });
  }

  public makePhonepePayment(): void {
    this.loading = true;

    this.prepareOrderData().then(() => {
      this.orderService
        .createOrder(this.orderData)
        .pipe(
          tap(this.validateOrderCreation),
          switchMap(newOrder => this.preparePhonePePaymentData(newOrder)),
          switchMap(paymentData =>
            this.paymentService.initiatePhonePePayment(paymentData)
          )
        )
        .subscribe({
          next: response => {
            this.loading = false;
            const payPageUrl = response.data!.instrumentResponse.redirectInfo
              .url;
            window.location.href = payPageUrl!;
          },
          error: (error) => {
            alert(error.error)
            this.loading = false;
          }
        });
    });
  }

  private validateOrderCreation = (response: OrderAttributes): void => {
    if (!response.id) {
      this.loading = false;
      throw new Error(OrderErrorMessage.OrderNotCreated); // Custom error message
    }
  };

  preparePhonePePaymentData(
    newOrder: OrderAttributes
  ): Observable<InitiatePhonePePaymentInput> {
    const paymentData: InitiatePhonePePaymentInput = {
      orderId: newOrder.id.toString(),
      mobileNumber: this.currentUser?.userMobileNumber!,
      amount: this.total
    };

    return of(paymentData);
  }

  private preparePaymentData(
    newOrder: OrderAttributes
  ): Observable<InitiatePaymentInput> {
    const generatedGuid: string = uuidv4();
    const paymentData: InitiatePaymentInput = {
      orderId: `${environment.name}-${newOrder.id.toString()}-${generatedGuid}`,
      userInfo: {
        custId: newOrder.customerMobile
      },
      callbackUrl:
        environment.apiUrlBasePath +
        environment.payment.paytmConfig.merchant.callbackUrl,
      txnAmount: {
        value: newOrder.totalAmount.toString(),
        currency: "INR"
      }
    };

    return of(paymentData);
  }

  private handleInitiatePaymentResponse = (
    response: InitiatePaymentResponse
  ): void => {
    if (response.body.txnToken) {
      this.navigateToCheckout(response);
    } else {
      this.loading = false; // Stop spinner
      throw new Error(response.body.resultInfo.resultMsg); // Display error message
    }
  };

  private navigateToCheckout(response: InitiatePaymentResponse): void {
    this.router.navigate(["payment"], {
      queryParams: {
        orderId: response.body.orderId,
        txnToken: response.body.txnToken,
        totalAmount: response.body.amount
      }
    });
  }

  private handleError = (error: Error): Observable<never> => {
    this.loading = false;
    this.openSnackBar(error.message, "Okay");
    return of();
  };

  private openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      horizontalPosition: "center",
      verticalPosition: "top",
      duration: 5000
    });
  }

  public numericOnly(event: KeyboardEvent): boolean {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }

  public onLogin(): void {
    this.router.navigate(["login"]);
  }

  public onManageAddress(): void {
    if (!this.isLoggedIn) {
      this.openDialogForGuestUser();
      return;
    }

    if (this.selectedAddress) {
      this.openChangeAddressDialog();
    } else {
      this.openAddAddressDialog(!this.currentUser);
    }
  }

  private openChangeAddressDialog(): void {
    const data = {
      componentName: DialogInvokingComponents.ChangeAddress,
      title: "Select Delivery Address",
      addresses: this.currentUserAddresses,
      selectedAddressId: this.selectedAddress?.id
    };
    this.dialogService
      .openDialog(data)
      .afterClosed()
      .subscribe(response => this.handleDialogResponse(response));
  }

  private openAddAddressDialog(withUserProfileDetails: boolean): void {
    const data = {
      componentName: DialogInvokingComponents.AddAddress,
      title: "Add Delivery Address",
      withUserProfileDetails,
      hideAgeAndShowMobile: false
    };
    this.dialogService
      .openDialog(data)
      .afterClosed()
      .subscribe(response => this.handleDialogResponse(response));
  }

  private openDialogForGuestUser(): void {
    const data = {
      componentName: DialogInvokingComponents.AddAddress,
      title: "Add Delivery Address",
      withUserProfileDetails: true,
      hideAgeAndShowMobile: true
    };
    this.dialogService
      .openDialog(data)
      .afterClosed()
      .subscribe(response => {
        if (response) {
          this.checkoutService.setUserInLocalStorage(response.userDetails);
          this.checkoutService.setAddressInLocalStorage(
            response.addressDetails
          );
          this.getGuestUserDetails();
        }
      });
  }

  private handleDialogResponse(
    response:
      | number
      | {
          userDetails?: UserDetailsCreationAttributes;
          addressDetails: AddressDetailsCreationAttributes;
        }
  ): void {
    if (!response) return;

    if (typeof response === "number") {
      this.selectAddressById(response);
    } else if (response.userDetails && response.addressDetails) {
      this.addUserDetailsAndSelectNewAddress(
        response.userDetails,
        response.addressDetails
      );
    } else {
      this.addAddressAndSelectNewAddress(response.addressDetails);
    }
  }

  private selectAddressById(id: number): void {
    const address = this.currentUserAddresses!.find(
      address => address!.id === id
    );
    if (address) {
      this.selectedAddress = address;
    }
  }

  private addUserDetailsAndSelectNewAddress(
    profile: UserDetailsCreationAttributes,
    newAddress: AddressDetailsCreationAttributes
  ): void {
    this.userService
      .addUserDetails({
        userDetails: profile,
        addressDetails: newAddress
      })
      .subscribe(response => {
        if (response) {
          this.openSnackBar(UserDetailStatus.Add, "Close");
        }
        this.getRegisteredUserDetails();
      });
  }

  private addAddressAndSelectNewAddress(
    newAddress: AddressDetailsCreationAttributes
  ): void {
    this.userService.addAddress(newAddress).subscribe(newAddress => {
      if (newAddress) {
        this.openSnackBar(UserDetailStatus.Add, "Close");
      }
      this.getRegisteredUserDetails();
    });
  }

  public isOverflow(el: HTMLElement): boolean {
    return el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;
  }

  //For future use
  public onTermsAndCondition(): void {
    this.router.navigate(["/termCondition"]);
  }

  //For future use
  public onPrivacyPolicy(): void {
    this.router.navigate(["/privacy"]);
  }
}
