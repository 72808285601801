export const CanvasConfig = {
  RenderConfig: {
    width: 300,
    height: 200,
    cameraPositionZ: 20,
  },
  cameraConfig: {
    fov: 45, // filed of view
  },
  transparentOpacity: 0.3,
  solidOpacity: 1,
  gridSize: 500,
  cellSize: 20
};

export const AnimationConfig = {
  rotation: {
    x: 0.01,
    y: 0.01,
    z: 0.01
  },
};

export interface ShapeJson {
  object: {
    name:string,
    userData: {
      faceMesh: THREE.Mesh;
    };
  };
}

export const SmallScreenCanvasConfig = {
  RenderConfig: {
    width: 150, // Adjusted for smaller screen
    height: 100, // Adjusted for smaller screen
    cameraPositionZ: 10, // Closer camera for smaller screen
  },
  cameraConfig: {
    fov: 60, // Wider field of view for smaller screens
  },
  transparentOpacity: 0.3,
  solidOpacity: 1,
  gridSize: 250, // Reduced grid size for smaller screen
  cellSize: 10, // Smaller cell size for more detail
};

export const SmallScreenAnimationConfig = {
  rotation: {
    x: 0.005, // Slower rotation for smaller screens
    y: 0.005,
    z: 0.005
  },
};
