<div>
    <button mat-icon-button class="close-icon" (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>
  
    <mat-dialog-content class="dialog-container">
      <div class="preview-container">
        <div class="review-slider">
          <ng-image-slider #nav [images]="sliderImages()" [infinite]="false" [autoSlide]="1"
            [imageSize]="{width: '100%', height: 200}" slideImage="1"></ng-image-slider>
        </div>
  
        <div class="review-container">
          <div class="review-header">
            <mat-icon>account_circle</mat-icon>
            <span class="reviewer-name">{{data.reviewProductData.name}}</span>
          </div>
  
          <div class="review-rating">
            <!-- Filled stars -->
            <ng-container *ngFor="let _ of [].constructor(data?.reviewProductData?.rating)">
              <mat-icon class="filled-star">star</mat-icon>
            </ng-container>
  
            <!-- Unfilled stars -->
            <ng-container *ngFor="let _ of [].constructor(5 - data?.reviewProductData?.rating!)">
              <mat-icon class="unfilled-star">star_border</mat-icon>
            </ng-container>
          </div>
  
          <p class="review-date">{{data.reviewProductData.createdAt | date:'dd MMM yy'}}</p>
  
          <div class="review-content">
            {{data.reviewProductData.review}}
          </div>
  
          <div class="images-container">
            <img *ngFor="let image of data?.reviewProductData?.images?.imagesUrl" [src]="image">
          </div>
        </div>
  
      </div>
    </mat-dialog-content>
  </div>