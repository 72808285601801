import { Injectable } from '@angular/core';
import { debounceTime, fromEvent, Subject, Subscription } from 'rxjs';
import { ComponentInteractionSrevice } from 'src/app/services/component-interaction/component-interaction.service';
import { DeleteShapeService } from 'src/app/services/delete-shape/delete-shape.service';
import { DragAndDropService } from 'src/app/services/drag-and-drop/drag-and-drop.service';
import { Object3DUserData, PlaneCustomProperties } from '@utils/shape';
import * as THREE from 'three';
import { Mesh } from 'three';
import { HelperService } from './helper.service';
import { SetShapeUserDataService } from './set-shape-userdata.service';
import { ColorHEX, Events } from '@utils/action';
import { AvailableShapes } from '@utils/shape-facetype';
import { ExpandOrCompressShapesService } from '../../services/expand-or-compress-shapes/expand-or-compress-shapes.service';
import { TransformShapeOperations } from '../../../../../utils/shape';
import { MatDialog } from '@angular/material/dialog';

interface selectedObject {
  uuid: string;
  color: string;
}

@Injectable({
  providedIn: 'root',
})

/*
  All the @hostlistners or window events are written here and this service handles
  rendering also.
*/
export class InteractionService {
  private mouseClickPosition = new THREE.Vector2();
  private intersects: THREE.Intersection<THREE.Object3D<THREE.Event>>[] = [];
  private draggableObject: THREE.Object3D | null = null; // Because initially we wont be having any dragable shapes until generated and selected.
  private renderer!: THREE.WebGLRenderer;
  private camera!: THREE.PerspectiveCamera;
  private objects!: THREE.Object3D[];
  private mouseMovePosition = new THREE.Vector2();
  private scene!: THREE.Scene;
  private isDoubleClick = false;
  private selectedColor: THREE.ColorRepresentation = '#FFFFFF';
  private selectedObjects: THREE.Object3D[] = [];
  private selectedObjectsUUID: string[] = [];
  private selectedObjectDetails: selectedObject[] = [];
  private isKeyCPressed: boolean = false;
  private userSeletedColor: boolean = false;
  private subscriptions: Subscription[] = [];
  private isEdittedSubject = new Subject<boolean>();
  public isEditted$ = this.isEdittedSubject.asObservable(); 

  constructor(
    private componentInteractionSrv: ComponentInteractionSrevice,
    private dragAndDropService: DragAndDropService,
    private helperService: HelperService,
    private deleteShapeService: DeleteShapeService,
    private setShapeUserDataService: SetShapeUserDataService,
    private expandOrCompressShapesService: ExpandOrCompressShapesService,
    private dialog: MatDialog,
  ) {
    this.componentInteractionSrv.getSelectedColor().subscribe((color) => {
      this.userSeletedColor = true;
      this.selectedColor = color;
    });
  }

  // Call this method wherever you modify `isEditted`
  private markAsEdited() {
    this.isEdittedSubject.next(true);
  }

  public subscribeToEvents(
    renderer: THREE.WebGLRenderer,
    camera: THREE.PerspectiveCamera,
    scene: THREE.Scene,
    objects: THREE.Object3D[]
  ): void {
    this.renderer = renderer;
    this.camera = camera;
    this.objects = objects;
    this.scene = scene;
    let isMetaKeyPressed = false;

    // We delay few miliseconds so that doubleclick and click wont conflict.
    this.subscriptions.push(
      fromEvent(document, Events.contextmenu)
      .pipe(debounceTime(150))
      .subscribe((event: Event) => {
        if (!this.isDoubleClick) {
          this.onContextMenu(event as MouseEvent);
        }
      })
    );
    
    this.subscriptions.push(
      fromEvent(document, Events.mousemove).subscribe((event: Event) => {
        this.onMouseMove(event as MouseEvent);
      })
    );
    
    this.subscriptions.push(
      fromEvent(document, Events.click).subscribe((event: any) => {
        this.getObjectHit(event.clientX, event.clientY);
        this.onClick(event as MouseEvent);
  
        if (isMetaKeyPressed) {
          if (this.intersects.length > 0 && !this.userSeletedColor) {
            const selectedObject = this.intersects[0].object as THREE.Object3D;
            if (
              selectedObject.name &&
              selectedObject.name !== AvailableShapes.Plane &&
              selectedObject.name !== AvailableShapes.Outline
            ) {
              const indexuuid = this.selectedObjectsUUID.includes(
                selectedObject.uuid
              );
              if (indexuuid) {
                let index = this.selectedObjectsUUID.indexOf(selectedObject.uuid);
                const updatedColor = new THREE.Color(this.selectedColor);
                (selectedObject as any).material.color = updatedColor;
                this.selectedObjectsUUID.splice(index, 1);
                this.selectedObjects.splice(index, 1);
              } else {
                this.selectedColor =
                  selectedObject.userData[Object3DUserData.colour];
                const updatedColor = new THREE.Color(ColorHEX.defaultColour);
                (selectedObject as any).material.color = updatedColor;
                this.selectedObjects.push(selectedObject);
                this.selectedObjectsUUID.push(selectedObject.uuid);
                this.selectedObjectDetails.push({
                  uuid: selectedObject.uuid,
                  color: selectedObject.userData[Object3DUserData.colour],
                });
              }
            } else {
              this.selectedObjects.forEach((obj) => {
                this.selectedObjectDetails.forEach(object =>{
                  if(obj.uuid === object.uuid){
                    const updatedColor = new THREE.Color(object.color as THREE.ColorRepresentation);
                    (obj as any).material.color = updatedColor;
                  }
                })
              });
              this.selectedObjects.length = 0;
              this.selectedObjectsUUID.length = 0;
            }
          } else if(this.userSeletedColor) {
            if (this.userSeletedColor) {
              this.selectedObjects.forEach((obj) => {
                    const updatedColor = new THREE.Color(this.selectedColor);
                    (obj as any).material.color = updatedColor;
                    obj.userData[Object3DUserData.colour] = this.selectedColor;
              });
              this.objects.forEach(object =>{
                this.selectedObjects.forEach(selectedObject=>{
                  if(object.uuid === selectedObject.uuid){
                    object.userData[Object3DUserData.colour] = selectedObject.userData[Object3DUserData.colour]
                  }
                })
              });
              this.markAsEdited();
              this.selectedObjects.length = 0;
              this.selectedObjectsUUID.length = 0;
              this.userSeletedColor = false;
            } else if (!this.userSeletedColor) {
              this.selectedColor = ColorHEX.defaultColour;
              this.selectedObjects.forEach((obj) => {
                const updatedColor = new THREE.Color(this.selectedColor);
                (obj as any).material.color = updatedColor;
              });
            } else {
              this.selectedObjects.forEach((obj) => {
                this.selectedObjectDetails.forEach((object) => {
                  if (obj.uuid === object.uuid) {
                    const updatedColor = new THREE.Color(object.color as THREE.ColorRepresentation);
                    (obj as any).material.color = updatedColor;
                  }
                });
              });
            }
          }
        } else if (!isMetaKeyPressed) {
          this.selectedObjectsUUID.length = 0;
          this.selectedObjects.forEach((obj) => {
            this.selectedObjectDetails.forEach((object) => {
              if (obj.uuid === object.uuid) {
                const updatedColor = new THREE.Color(object.color as THREE.ColorRepresentation);
                (obj as any).material.color = updatedColor;
              }
            });
          });
          this.selectedObjects.length = 0;
          this.selectedObjectsUUID.length = 0;
        }
      })
    );

    this.subscriptions.push(
      fromEvent(document, Events.keydown).subscribe((event) => {
        if (this.intersects) {
          if (this.objects[0] !== this.intersects[0]?.object) {
            if ((event as KeyboardEvent).key === Events.backspace) {
              if (this.intersects[0]?.object) {
                this.objects = this.deleteShapeService.delete(
                  this.selectedObjects,
                  this.objects,
                  this.scene
                );
                this.markAsEdited();
              }
            }
          }
        }
        if (
          (event as KeyboardEvent).key === Events.keyc &&
          (event as KeyboardEvent).key === Events.shiftKey
        ) {
          this.isKeyCPressed = true;
        } else if ((event as KeyboardEvent).key === Events.commandKey) {
          isMetaKeyPressed = true;
        }
      })
    );
    
    this.subscriptions.push(
      fromEvent(document, Events.keyup).subscribe(event => {
        const key = (event as KeyboardEvent).key;
        const shapeToScale = this.intersects[0]?.object;
        const canScaleShape = shapeToScale && shapeToScale.name !== PlaneCustomProperties.name;
  
        switch (key) {
          case Events.keyc:
            this.isKeyCPressed = false;
            break;
          case Events.plusKey:
            if (canScaleShape) {
              this.expandOrCompressShapesService.scaleShape(
                shapeToScale,
                TransformShapeOperations.expand
              );
            }
            break;
          case Events.minusKey:
            if (canScaleShape) {
              this.expandOrCompressShapesService.scaleShape(
                shapeToScale,
                TransformShapeOperations.compress
              );
            }
            break;
          default:
            break;
        }
      })
    );
    
    this.subscriptions.push(
      fromEvent(document, Events.doubleclick).subscribe((event: Event) => {
        this.isDoubleClick = true;
        this.onDoubleClick(event as MouseEvent);
  
        // This is to make the click event to wait so that it wont pick the newly generated shape.
        setTimeout(() => {
          this.isDoubleClick = false;
        }, 300);
      })
    );
  }

  public unsubscribeToEvents(): void {
    // Unsubscribe from all subscriptions
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  // For selecting the shapes for drag and drop.
  private onContextMenu(event: MouseEvent): void {
    this.getObjectHit(event.clientX, event.clientY);
    if (
      this.intersects.length &&
      this.intersects[0].object.userData[Object3DUserData.isDraggable] &&
      !this.draggableObject
    ) {
      const filterObjects = this.objects.filter(
        (object) => object.name !== PlaneCustomProperties.name
      );
      const isShapeDraggable = this.helperService.isShapeDraggable(
        this.intersects[0].object as Mesh,
        filterObjects
      );
      if (isShapeDraggable) {
        this.draggableObject = this.intersects[0].object;
        this.setShapeUserDataService.clearAdjacentDataOnDragStart(
          this.draggableObject as Mesh,
          this.objects
        );

      }
    }
  }

  // For dragging the selected shape.
  private onMouseMove(event: MouseEvent): void {
    this.mouseMovePosition = new THREE.Vector2(
      (event.clientX / this.renderer.domElement.clientWidth) * 2 - 1,
      -(event.clientY / this.renderer.domElement.clientHeight) * 2 + 1
    );
    this.dragAndDropService.dragObject(
      this.draggableObject,
      this.mouseMovePosition,
      this.camera,
      this.objects
    );
  }

  // For dropping the selected shape.
  private onClick(event: MouseEvent): void {

    if (this.isKeyCPressed) {
      this.componentInteractionSrv.setInterceptInfo2(this.intersects);
    }
    if (this.draggableObject) {
      this.draggableObject = this.dragAndDropService.dropObject(
        this.draggableObject,
        this.objects
      );
      // Mark as edited
      this.markAsEdited();
      this.draggableObject = null;
    }
    event.preventDefault(); // Prevent the default context menu from showing up
  }

  // For handeling the window resize, helps in responsive ui
  private onResize(): void {
    this.camera.aspect = window.innerWidth / innerHeight;
    this.renderer.setSize(window.innerWidth, window.innerHeight);
  }

  // Renders scene and camera to the screen
  render(): void {
    this.renderer.render(this.scene, this.camera);
  }

  private onDoubleClick(event: MouseEvent): void {
    if (!this.dialog.openDialogs.length) {
      if (!this.draggableObject) {
        this.getObjectHit(event.clientX, event.clientY);
        if (this.intersects.length > 0) {
          this.componentInteractionSrv.setInterceptInfo(this.intersects);
        }
      }
    }
  }

  // Gives the shapes that corresponds to the mouse co-ordinates.
  private getObjectHit(x: number, y: number): void {
    const raycaster = new THREE.Raycaster();
    this.mouseClickPosition.set(
      (x / this.renderer.domElement.clientWidth) * 2 - 1,
      -(y / this.renderer.domElement.clientHeight) * 2 + 1
    );
    raycaster?.setFromCamera(this.mouseClickPosition, this.camera);
    this.intersects = raycaster?.intersectObjects(this.objects);
  }

  public getScene(): THREE.Scene {
    return this.scene;
  }
}
