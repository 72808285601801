<div *ngIf="showButton" class="nav-container" [ngClass]="{'open': isOpen}" [@slideInOut]>
    <div fxLayout="row" fxLayoutAlign="end center">
        <mat-icon class="customiseCloseIcon" (click)="toggleNav()">{{closeIconLabel}}</mat-icon>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
        <div *ngFor="let actionBtn of actionsBtns, let i = index" [@slideInOut] class="customiseActionIcon">
            <mat-icon (click)="actions(i)">{{actionBtn.iconlabel}}</mat-icon><br>
            <mat-hint *ngIf="i !== selectedActionIndex || !showRevert ">{{actionBtn.iconHint1}}</mat-hint>
            <mat-hint *ngIf="selectedActionIndex === 1 && showRevert">{{actionBtn?.iconHint2}}</mat-hint>
        </div>
    </div>
    <hr>
    <div fxLayout="row"  fxLayoutAlign="center center">
       <colour-picker></colour-picker>
    </div>
    <div fxLayout="row" (click)="openDialogBox()" class="customiseCountBox" fxLayoutAlign="center center" >
        <a mat-button target="_blank">Get Count</a>
    </div>
    <hr>
    <div fxLayout="row" fxLayoutAlign="center center" class="save-buttons">
      <div *ngFor="let saveBtn of filteredSaveBtns, let i = index" [@slideInOut] class="customiseSaveActionIcon" [ngClass]="{'disabled': isDisabled(saveBtn)}" (click)="saveActions(saveBtn.iconlabel)">
          <mat-icon>{{ saveBtn.iconlabel }}</mat-icon>
          <mat-hint>{{ saveBtn.iconHint1 }}</mat-hint>
      </div>
    </div>

</div>
<a mat-button *ngIf="!showButton" (click)="toggleNav()" target="_blank" class="customiseBtn">Actions</a>
