<div class="container">
  <div class="personal-info">
    <div class="profile-container">
      <ng-container *ngIf="showProfileImage; else noProfileImage">
        <img
          class="profile-image"
          [src]="showProfileImage"
          alt="Image preview"
        />
      </ng-container>
      <ng-template #noProfileImage>
        <mat-icon class="profile-icon">person</mat-icon>
      </ng-template>
      <div
        class="overlay"
        [matMenuTriggerFor]="menu"
        [class.profile-no-image-overlay]="!showProfileImage"
      >
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="fileInput.click()">Upload</button>
          <button
            mat-menu-item
            (click)="uploadFile($event, '')"
            *ngIf="showProfileImage"
          >
            Remove
          </button>
        </mat-menu>
        <span class="change-photo-text">{{
          userDetails?.user?.profileImage
            ? profileLabel.ChangeProfileImage
            : profileLabel.AddProfileImage
        }}</span>
        <mat-icon class="camera-icon">photo_camera</mat-icon>
      </div>
    </div>
    <input
      type="file"
      #fileInput
      id="image"
      class="file-input"
      accept="image/jpeg, image/png, image/webp, image/gif"
      (change)="uploadFile($event, 'image')"
    />
    <h2 class="name">
      {{ userDetails?.user?.name }}
    </h2>
    <h2 class="mobile-number">{{ userDetails?.mobileNumber }}</h2>
    <div class="emai-inner-content" *ngIf="!showSubmitButton">
      <h3>{{ userDetails?.user?.email }}</h3>
      <div
        class="edit-icon-content"
        *ngIf="!showSubmitButton && userDetails?.user"
        (click)="this.showSubmitButton = true"
      >
        <mat-icon class="edit-icon">edit</mat-icon>
      </div>
    </div>
    <div class="email-content" *ngIf="showSubmitButton">
      <div [formGroup]="profileForm" class="emai-inner-content">
        <div class="email-input-content">
          <input
            type="text"
            formControlName="email"
            class="email-input"
            [class.show-border]="showSubmitButton"
            [readonly]="!showSubmitButton"
            required
          />
          <div *ngIf="profileForm.get('email')?.touched">
            <div *ngIf="emailError === 'required'" class="text-red">
              Enter the Email!
            </div>
            <div *ngIf="emailError === 'invalid'" class="text-red">
              Please enter a valid email address!
            </div>
          </div>
        </div>
      </div>
      <div class="btn-email-content" *ngIf="showSubmitButton">
        <button
          mat-raised-button
          type="button"
          (click)="onUpdateEmail()"
          (click)="showSubmitButton = false; showEditIcon = true"
          class="save-btn"
          [disabled]="profileForm.invalid"
        >
          Save
        </button>
        <button
          mat-raised-button
          type="button"
          (click)="showSubmitButton = false; showEditIcon = true"
          class="cancel-btn"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
  <div class="address-info">
    <mat-card>
      <mat-card-content>
        <h1>Address:</h1>
        <div class="add-address-content">
          <div
            class="add-address-text"
            *ngIf="!addMode"
            (click)="toggleAddAddressMode()"
          >
            <button mat-raised-button class="save-btn">
              <mat-icon class="add-icon">add</mat-icon>ADD NEW
            </button>
          </div>

          <div class="edit-address" *ngIf="addMode">
            <app-address
              (addressData)="receiveAddressData($event)"
              [showNameAndMobile]="true"
            ></app-address>
            <div class="btn-content">
              <button
                mat-raised-button
                class="save-btn"
                (click)="onAdd()"
                [disabled]="!isFormDataValid()"
              >
                Save
              </button>
              <button
                mat-raised-button
                class="cancel-btn"
                (click)="cancelAdd()"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
        <ng-container *ngFor="let address of userDetails?.addresses">
          <div *ngIf="!editMode || selectedAddress !== address" class="address">
            <p class="address-type">{{ address.addressType }}</p>
            <p>
              <b>{{ address.name }}</b> &nbsp; <b>{{ address.mobileNumber }}</b>
            </p>
            <p class="location">
              {{ address.address }}, {{ address.city }}, {{ address.state }} -
              {{ address.pincode }}
            </p>
            <mat-icon [matMenuTriggerFor]="menu" class="menu-icon"
              >more_vert</mat-icon
            >
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="editAddress(address)">Edit</button>
              <button mat-menu-item (click)="deleteAddress(address)">
                Delete
              </button>
            </mat-menu>
          </div>
          <div
            class="edit-address"
            *ngIf="editMode && selectedAddress === address"
          >
            <app-address
              [selectedAddress]="selectedAddress"
              (addressData)="receiveAddressData($event)"
              [showNameAndMobile]="true"
              #addressDetailRef
            ></app-address>
            <div class="btn-content">
              <button
                mat-raised-button
                class="save-btn"
                (click)="onUpdate()"
                [disabled]="!isFormDataValid()"
              >
                Save
              </button>
              <button
                mat-raised-button
                class="cancel-btn"
                (click)="cancelEdit()"
              >
                Cancel
              </button>
            </div>
          </div>
        </ng-container>
      </mat-card-content>
    </mat-card>
  </div>
</div>
