export enum Object3DUserData {
  initialPosition = 'initialPosition',
  isDraggable = 'isDraggable',
  initialMaterial = 'initialMaterial',
  isInCollision = 'isInCollision',
  collidedObjectId = 'collidedObjectId',
  adjacentData = 'adjacentData',
  cellReference = 'cellReference',
  faceType = 'faceType',
  facePattern = 'facePattern',
  colour = 'colour',
  scaleCount = 'scaleCount',
  connectionData = 'connectionData',
  isTilted = 'isTilted',
  rotationData ='rotation'
}

export enum TransformShapeOperations {
  expand = 'expand',
  compress = 'compress'
}

export enum PlaneCustomProperties {
  name = 'plane',
  orientationKey = 'transformOrientationObject',
  isDraggable = 'isDraggable'
}

/*
  We use this altered configuration because we are manually calculating the centroid of the custom shapes,
  This minor adjustment done for all the custom shape to match with that of the cube which is
  default given shape from three.js. And altered configurations are done only once for all the custom shapes.
*/
export enum AlteredShapeConfiguration {
  standardYCo_Ordinate = -94, // of the plane
  divisorAdjustYPosition = 2,
  gridHelperYCoOrdinatesAdjustment = 5.5, // this is to place the grid on the plane in a visible distance
  customShapeYOffset = 2.9, // this is for adjustmenst of the y coordinate with the plane when we generate.
  yOffset = 0.5, // this is for adjustmenst of the y coordinate with the plane when we generate.
  curvedCubeShapeXOffset = 5,
  curvedCube2ShapeXOffset = 7.5,
  customShapeXOffset = 7.5, // this is to match the custom shape x-axis with that of cube when it is on the grid.
  standardHeigthOfShape = 20, // as a base constant for offsetCalculation.
  standardThreshHoldAngle = 14, // For customShapes
  yOffsetForHalfcylinder = 4,
  yOffsetForPrism = 3.5,
}

export enum selectedShapeColor {
  code = '#117A65',
}

export enum MaxMinCoordinatesConfiguiration {
  constant = 240
}
