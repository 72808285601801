import { Injectable } from '@angular/core';
import { AlteredShapeConfiguration, Object3DUserData, TransformShapeOperations } from '../../../../../utils/shape';
import { HelperService } from '../../shared/helpers/helper.service';
import { Vector2 } from 'three';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: "root"
})
export class ExpandOrCompressShapesService {
  private expandLimitMessage = "You can expand upto 3 times only";
  private close = "close";
  private compressLimitMessage = "You cannot compress further more";
  constructor(
    private helperService: HelperService,
    private snackBar: MatSnackBar
  ) {}

  /*
    This method takes care of the expanding and compressing of any given shape.
    User can expand upto 3 times and compress upto 3 times.
  */
  public scaleShape(shape: THREE.Object3D, operation: string): void {
    if (shape.userData[Object3DUserData.scaleCount] === undefined) {
      shape.userData[Object3DUserData.scaleCount] = 0;
    }

    // 1 is expand and -1 is compress
    const scaleDirection =
      operation === TransformShapeOperations.expand ? 1 : -1;


    // This is where the code accepts exapnding upto 3 and if the user tries to compress beyond 0, we wont let it.
    if (
      (operation === TransformShapeOperations.expand &&
        shape.userData[Object3DUserData.scaleCount] >= 3) ||
      (operation === TransformShapeOperations.compress &&
        shape.userData[Object3DUserData.scaleCount] <= 0)
    ) {
      const message =
        operation === TransformShapeOperations.expand
          ? this.expandLimitMessage
          : this.compressLimitMessage;
      this.snackBar.open(message, this.close);
      return;
    }

    shape.userData[Object3DUserData.scaleCount] += scaleDirection;

    const shapePositionOnY = shape.position.y;
    shape.scale.x += scaleDirection;
    shape.scale.y += scaleDirection;
    shape.scale.z += scaleDirection;

    /*
      Because the y value of the shape has to match with plane.
      here we add the default height of the shape i.e., 20 to the scaled shape y
      logic is simple the scaling of the shape moves 20 values in all direction
      add 10 extra to bring it up to the plane level.
    */

    const adjustmentMent = (scaleDirection * AlteredShapeConfiguration.standardHeigthOfShape) / 2;
    shape.position.y =
      shapePositionOnY + adjustmentMent;

    /*
      Since scaling alters the position value of the shapes, it is hard for it to adjust to the
      grids present on the plane, below logic handels the adjustment of the scaled shape to
      the nearest grid.
    */
    const nearestGridCords = this.helperService.getNearestGridCords(
      shape.name,
      new Vector2(shape.position.x, shape.position.z)
    );

    // 2 here because when the shape is scaled for 2nd time the placement matches the grid, so we omit.
    if (
      shape.userData[Object3DUserData.scaleCount] !== 2 &&
      operation === TransformShapeOperations.expand
    ) {
      shape.position.x = nearestGridCords.x + adjustmentMent;
      shape.position.z = nearestGridCords.y + adjustmentMent;
    } else if (
      shape.userData[Object3DUserData.scaleCount] === 1 &&
      operation === TransformShapeOperations.compress
    ) {
      shape.position.x = nearestGridCords.x - adjustmentMent;
      shape.position.z = nearestGridCords.y - adjustmentMent;
    } else {
      shape.position.x = nearestGridCords.x;
      shape.position.z = nearestGridCords.y;
    }
  }
}
