import { Injectable } from '@angular/core';
import { ComponentInteractionSrevice } from 'src/app/services/component-interaction/component-interaction.service';
import { DynamicGenerateShape } from './face-identifier.model';
import { AvailableShapes, setFaceTypeForShape } from '@utils/shape-facetype';
import {
  Face,
  FaceType,
} from '@utils/count-calculator/count-calculator.model';
import { Object3DUserData } from '@utils/shape';

@Injectable({
  providedIn: 'root',
})
export class FaceIdentifierService {
  public rightFace = false;
  public leftFace = false;
  public topFace = false;
  public bottomFace = false;
  public frontFace = false;
  public backFace = false;
  private selectedShape: string = '';
  public invalidJoin = false;
  public invalidPointOfGeneration = false;
  public faceType: Record<string, string> | undefined;
  public objectHit!: THREE.Mesh;

  constructor(private compInteraction: ComponentInteractionSrevice) {
    this.compInteraction.getSeletedShape().subscribe((selectedShape) => {
      this.selectedShape = selectedShape;
      this.faceType = setFaceTypeForShape(selectedShape);
    });

    this.compInteraction.getInterceptInfo().subscribe((intersects) => {
      let position = intersects[0]?.object.position;
      this.objectHit = intersects[0]?.object as THREE.Mesh;
      if(this.selectedShape ===  AvailableShapes.HemiSphere){
        for(let i=0; i<intersects.length; i++){
          if(intersects[i].object.children.length === DynamicGenerateShape.IntersectChildrenLength){
            position = intersects[i].object.position
          }
        }
      }

      let point = intersects[0]?.point;
      let objectName = intersects[0]?.object.name;

      if (objectName === AvailableShapes.Cube) {
        this.rightFace =
          position?.x + DynamicGenerateShape.Min_Deviation < point?.x &&
          point?.x < position?.x + DynamicGenerateShape.X_Max_Deviation_Cube &&
          position?.y + DynamicGenerateShape.Deviation > point?.y &&
          point.y > position?.y - DynamicGenerateShape.Deviation &&
          position?.z + DynamicGenerateShape.Deviation > point?.z &&
          point?.z > position?.z - DynamicGenerateShape.Deviation;

        this.leftFace =
          position?.x - DynamicGenerateShape.Min_Deviation > point?.x &&
          point?.x > position?.x - DynamicGenerateShape.X_Max_Deviation_Cube &&
          position?.y + DynamicGenerateShape.Deviation > point?.y &&
          point.y > position.y - DynamicGenerateShape.Deviation &&
          position?.z + DynamicGenerateShape.Deviation > point?.z &&
          point?.z > position?.z - DynamicGenerateShape.Deviation;

        this.topFace =
          position?.x + DynamicGenerateShape.X_Deviation > point?.x &&
          point?.x > position?.x - DynamicGenerateShape.X_Deviation &&
          position?.y + DynamicGenerateShape.Min_Deviation < point?.y &&
          point?.y < position?.y + DynamicGenerateShape.Max_Deviation &&
          position?.z + DynamicGenerateShape.Deviation > point?.z &&
          point?.z > position?.z - DynamicGenerateShape.Deviation;

        this.bottomFace =
          position?.x + DynamicGenerateShape.Deviation > point?.x &&
          point?.x > position?.x - DynamicGenerateShape.Deviation &&
          position?.y - DynamicGenerateShape.Min_Deviation > point?.y &&
          point?.y > position?.y - DynamicGenerateShape.Max_Deviation &&
          position?.z + DynamicGenerateShape.Deviation > point?.z &&
          point?.z > position?.z - DynamicGenerateShape.Deviation;

        this.frontFace =
          position?.x + DynamicGenerateShape.Deviation > point?.x &&
          point?.x > position?.x - DynamicGenerateShape.Deviation &&
          position?.y + DynamicGenerateShape.Deviation > point?.y &&
          point?.y > position?.y - DynamicGenerateShape.Deviation &&
          position?.z + DynamicGenerateShape.Min_Deviation < point?.z &&
          point?.z < position?.z + DynamicGenerateShape.Max_Deviation;

        this.backFace =
          position?.x + DynamicGenerateShape.Deviation > point?.x &&
          point?.x > position?.x - DynamicGenerateShape.Deviation &&
          position?.y + DynamicGenerateShape.Deviation > point?.y &&
          point?.y > position?.y - DynamicGenerateShape.Deviation &&
          position?.z - DynamicGenerateShape.Min_Deviation > point?.z &&
          point?.z > position?.z - DynamicGenerateShape.Max_Deviation;
      } else if (objectName === AvailableShapes.CurvedCube2) {
        this.leftFace =
          position?.x -
            DynamicGenerateShape.X_Min_Deviation_For_Curvedcube2_Left >
            point?.x &&
          position?.x -
            DynamicGenerateShape.X_Max_Deviation_For_Curvedcube2_Left <
            point?.x &&
          position?.y + DynamicGenerateShape.Deviation > point?.y &&
          point?.y > position?.y - DynamicGenerateShape.Deviation &&
          position?.z + DynamicGenerateShape.Deviation > point?.z &&
          point?.z > position?.z - DynamicGenerateShape.Deviation;

        this.frontFace =
          position?.x +
            DynamicGenerateShape.X_Min_Deviation_For_Curvedcube2_Front_or_Back >
            point?.x &&
          point?.x >
            position?.x -
              DynamicGenerateShape.X_Max_Deviation_For_Curvedcube2_Front_or_Back &&
          position?.y + DynamicGenerateShape.Deviation > point?.y &&
          point?.y > position?.y - DynamicGenerateShape.Deviation &&
          position?.z + DynamicGenerateShape.Min_Deviation <
            point?.z &&
          point?.z <
            position?.z + DynamicGenerateShape.Max_Deviation;

        this.backFace =
          position?.x +
            DynamicGenerateShape.X_Min_Deviation_For_Curvedcube2_Front_or_Back >
            point?.x &&
          point?.x >
            position?.x -
              DynamicGenerateShape.X_Max_Deviation_For_Curvedcube2_Front_or_Back &&
          position?.y + DynamicGenerateShape.Y_Deviation_For_Curvedcube2_Back >
            point?.y &&
          point?.y >
            position?.y -
              DynamicGenerateShape.Y_Deviation_For_Curvedcube2_Back &&
          position?.z - DynamicGenerateShape.Min_Deviation >
            point?.z &&
          point?.z >
            position?.z - DynamicGenerateShape.Max_Deviation;

        this.topFace =
          position?.x -
            DynamicGenerateShape.X_Min_Deviation_For_Curvedcube2_Front_or_Back >
            point?.x &&
          point?.x >
            position?.x -
              DynamicGenerateShape.X_Max_Deviation_For_Curvedcube2_Front_or_Back &&
          position?.y +
            DynamicGenerateShape.Min_Deviation <
            point?.y &&
          point?.y <
            position?.y +
              DynamicGenerateShape.Max_Deviation &&
          position?.z + DynamicGenerateShape.Deviation > point?.z &&
          point?.z > position?.z - DynamicGenerateShape.Deviation;

        this.bottomFace =
          point?.x >
            position?.x -
              DynamicGenerateShape.X_Max_Deviation_For_Curvedcube2_Front_or_Back &&
          position?.y -
            DynamicGenerateShape.Min_Deviation >
            point.y &&
          position?.y -
            DynamicGenerateShape.Max_Deviation <
            point?.y &&
          position?.z - DynamicGenerateShape.Deviation < point?.z &&
          position?.z + DynamicGenerateShape.Deviation > point?.z;

        this.rightFace =
          point?.x <
            position?.x +
              DynamicGenerateShape.X_Max_Deviation_For_Curvedcube2_Right &&
          point?.x >
            position?.x -
              DynamicGenerateShape.X_Min_Deviation_For_Curvedcube2_Right &&
          point?.y < position?.y + DynamicGenerateShape.Deviation &&
          point?.y > position?.y - DynamicGenerateShape.Deviation &&
          point?.z < position?.z + DynamicGenerateShape.Deviation &&
          point?.z > position?.z - DynamicGenerateShape.Deviation;

        if (this.leftFace === true) {
          this.rightFace = false;
        } else if (
          this.topFace === true ||
          this.frontFace === true ||
          this.rightFace === true
        ) {
          this.leftFace === false;
        }
      } else if (objectName === AvailableShapes.CurvedCube) {
        this.topFace = false;
        this.rightFace =
          position?.x + DynamicGenerateShape.X_Min_Deviation_For_Curvedcube >
            point?.x &&
          point?.x >
            position?.x - DynamicGenerateShape.X_Max_Deviation_For_Curvedcube &&
          position?.y + DynamicGenerateShape.Deviation > point?.y &&
          point?.y > position?.y - DynamicGenerateShape.Deviation &&
          point?.z >
            position?.z - DynamicGenerateShape.Max_Deviation &&
          point?.z <
            position?.z + DynamicGenerateShape.Max_Deviation;

        this.leftFace =
          position?.x -
            DynamicGenerateShape.X_Max_Deviation_For_Curvedcube2_Left <
            point?.x &&
          position?.x -
            DynamicGenerateShape.X_Min_Deviation_For_Curvedcube_Left >
            point?.x &&
          position?.y + DynamicGenerateShape.Deviation > point?.y &&
          point?.y > position?.y - DynamicGenerateShape.Deviation &&
          position?.z + DynamicGenerateShape.Deviation > point?.z &&
          position?.z - DynamicGenerateShape.Deviation < point?.z;

        // this.topFace = this.rightFace;

        this.bottomFace =
          position?.x + DynamicGenerateShape.X_Min_Deviation_For_Curvedcube >
            point?.x &&
          point?.x >
            position?.x - DynamicGenerateShape.X_Max_Deviation_For_Curvedcube &&
          point?.y >
            position?.y - DynamicGenerateShape.Min_Deviation &&
          point?.y <
            position?.y - DynamicGenerateShape.Min_Deviation &&
          position?.z + DynamicGenerateShape.Deviation > point?.z &&
          position?.z - DynamicGenerateShape.Deviation < point?.z;

        this.frontFace =
          position?.x + DynamicGenerateShape.X_Min_Deviation_For_Curvedcube >
            point?.x &&
          point?.x >
            position?.x - DynamicGenerateShape.X_Max_Deviation_For_Curvedcube &&
          position?.y + DynamicGenerateShape.Deviation > point?.y &&
          point?.y > position?.y - DynamicGenerateShape.Deviation &&
          point?.z >
            position?.z + DynamicGenerateShape.Min_Deviation &&
          point?.z <
            position?.z + DynamicGenerateShape.Max_Deviation;

        this.backFace =
          position?.x + DynamicGenerateShape.X_Min_Deviation_For_Curvedcube >
            point?.x &&
          point?.x >
            position?.x - DynamicGenerateShape.X_Max_Deviation_For_Curvedcube &&
          position?.y + DynamicGenerateShape.Deviation > point?.y &&
          point?.y > position?.y - DynamicGenerateShape.Deviation &&
          point?.z <
            position?.z - DynamicGenerateShape.Min_Deviation &&
          point?.z >
            position?.z - DynamicGenerateShape.Max_Deviation;

        if (
          this.frontFace === true ||
          this.backFace === true ||
          this.leftFace === true ||
          this.bottomFace === true
        ) {
          this.rightFace = false;
        }
      } else if (objectName === AvailableShapes.HalfCylinder) {
        this.topFace =
          position?.x + DynamicGenerateShape.Max_Deviation >
            point?.x &&
          position?.x - DynamicGenerateShape.Max_Deviation <
            point?.x &&
          position?.y + DynamicGenerateShape.Min_Deviation >
            point?.y &&
          position?.y - DynamicGenerateShape.Min_Deviation <
            point?.y &&
          position?.z + DynamicGenerateShape.Deviation > point?.z &&
          position?.z - DynamicGenerateShape.Deviation < point?.z;

        this.bottomFace =
          position?.x + DynamicGenerateShape.Deviation > point?.x &&
          position?.x - DynamicGenerateShape.Deviation < point?.x &&
          position?.y - DynamicGenerateShape.Y_Max_Deviation_For_HalfCylinder <
            point?.y &&
          position?.y - DynamicGenerateShape.Y_Min_Deviation_For_HalfCylinder >
            point?.y &&
          position?.z + DynamicGenerateShape.Deviation > position?.z &&
          position?.z - DynamicGenerateShape.Deviation < position?.z;

        this.backFace =
          position?.x + DynamicGenerateShape.Deviation > point?.x &&
          position?.x - DynamicGenerateShape.Deviation < point?.x &&
          position?.y - DynamicGenerateShape.Deviation < point?.y &&
          position?.y > point?.y &&
          position?.z - DynamicGenerateShape.Max_Deviation <
            point?.z &&
          position?.z - DynamicGenerateShape.Min_Deviation >
            point?.z;

        this.frontFace =
          position?.x + DynamicGenerateShape.Deviation > point?.x &&
          position?.x - DynamicGenerateShape.Deviation < point?.x &&
          position?.y + DynamicGenerateShape.Y_Min_Deviation_For_HalfCylinder >
            point?.y &&
          position?.y - DynamicGenerateShape.Deviation < point?.y &&
          position?.z + DynamicGenerateShape.Min_Deviation <
            point?.z &&
          position?.z + DynamicGenerateShape.Max_Deviation >
            point?.z;

        if (this.bottomFace === true) {
          this.topFace = false;
        } else if (this.topFace === true) {
          this.rightFace = true;
          this.leftFace = true;
        }
      } else if (objectName === AvailableShapes.Prism) {
        this.frontFace =
          position?.x + DynamicGenerateShape.X_Deviation_For_Prism > point?.x &&
          position?.x - DynamicGenerateShape.X_Deviation_For_Prism < point?.x &&
          position?.y - DynamicGenerateShape.Deviation < point?.y &&
          position?.y + DynamicGenerateShape.Deviation > point?.y &&
          position?.z + DynamicGenerateShape.Min_Deviation < point?.z &&
          position?.z + DynamicGenerateShape.Max_Deviation > point?.z;

        this.backFace =
          position?.x + DynamicGenerateShape.X_Deviation_For_Prism > point?.x &&
          position?.x - DynamicGenerateShape.X_Deviation_For_Prism < point?.x &&
          position?.y - DynamicGenerateShape.Deviation < point?.y &&
          position?.y + DynamicGenerateShape.Deviation > point?.y &&
          position?.z - DynamicGenerateShape.Min_Deviation > point?.z &&
          position?.z - DynamicGenerateShape.Max_Deviation < point?.z;

        this.rightFace =
          position.x < point.x &&
          position.x + DynamicGenerateShape.Deviation > point.x &&
          position.y - DynamicGenerateShape.Y_Min_Deviation_For_Prism_Right <
            point.y &&
          position.y + DynamicGenerateShape.Y_Max_Deviation_For_Prism_Right >
            point.y &&
          position.z + DynamicGenerateShape.Deviation > point.z &&
          position.z - DynamicGenerateShape.Deviation < point.z;

        this.leftFace =
          position.x > point?.x &&
          position?.x - DynamicGenerateShape.Deviation < point?.x &&
          position?.y + DynamicGenerateShape.Y_Max_Deviation_For_Prism_Left >
            point?.y &&
          position?.y - DynamicGenerateShape.Y_Min_Deviation_For_Prism_Left <
            point?.y &&
          position.z + DynamicGenerateShape.Deviation > point.z &&
          position.z - DynamicGenerateShape.Deviation < point.z;

        this.bottomFace =
          position?.x + DynamicGenerateShape.Deviation > point?.x &&
          position?.x - DynamicGenerateShape.Deviation < point?.x &&
          position?.y - DynamicGenerateShape.Y_Max_Deviation_For_Prism_Bottom <
            point?.y &&
          position?.y - DynamicGenerateShape.Y_Min_Deviation_For_Prism_Bottom <
            point?.y &&
          position?.z + DynamicGenerateShape.Deviation > point?.z &&
          position?.z - DynamicGenerateShape.Deviation < point?.z;
      } else if (objectName === AvailableShapes.SquarePyramid) {
        this.rightFace =
          position?.x < point?.x &&
          position?.x + DynamicGenerateShape.Deviation > point?.x &&
          position?.y + DynamicGenerateShape.Deviation > point?.y &&
          position?.y - DynamicGenerateShape.Deviation < point?.y &&
          position?.z + DynamicGenerateShape.Deviation > point?.z &&
          position?.z - DynamicGenerateShape.Deviation < point?.z;

        this.leftFace =
          position?.x > point?.x &&
          position?.x - DynamicGenerateShape.Deviation < point?.x &&
          position?.y + DynamicGenerateShape.Deviation > point?.y &&
          position?.y - DynamicGenerateShape.Deviation < point?.y &&
          position?.z + DynamicGenerateShape.Deviation > point?.z &&
          position?.z - DynamicGenerateShape.Deviation < point?.z;

        this.frontFace =
          position?.x + DynamicGenerateShape.Deviation > point?.x &&
          position?.x - DynamicGenerateShape.Deviation < point?.x &&
          position?.y + DynamicGenerateShape.Deviation > point?.y &&
          position?.y - DynamicGenerateShape.Deviation < point?.y &&
          position?.z < point?.z &&
          position?.z + DynamicGenerateShape.Deviation > point?.z;

        this.backFace =
          position?.x + DynamicGenerateShape.Deviation > point?.x &&
          position?.x - DynamicGenerateShape.Deviation < point?.x &&
          position?.y + DynamicGenerateShape.Deviation > point?.y &&
          position?.y - DynamicGenerateShape.Deviation < point?.y &&
          position?.z < point?.z &&
          position?.z + DynamicGenerateShape.Deviation > point?.z;
      } else if (objectName === AvailableShapes.Cone) {
        this.bottomFace =
          position?.x + DynamicGenerateShape.Deviation > point?.x &&
          position?.x - DynamicGenerateShape.Deviation < point?.x &&
          position?.y - DynamicGenerateShape.Max_Deviation < point?.y &&
          position?.y - DynamicGenerateShape.Min_Deviation > point?.y &&
          position?.z + DynamicGenerateShape.Deviation > point?.z &&
          position?.z - DynamicGenerateShape.Deviation < point?.z;

        this.rightFace =
          position?.x + DynamicGenerateShape.Deviation > point?.x &&
          position?.y + DynamicGenerateShape.Deviation > point?.y &&
          position?.y - DynamicGenerateShape.Deviation < point?.y &&
          position?.z - DynamicGenerateShape.Min_Deviation < point?.z;

        this.leftFace =
          position?.x - DynamicGenerateShape.Deviation < point?.x &&
          position?.y + DynamicGenerateShape.Deviation > point?.y &&
          position?.y - DynamicGenerateShape.Deviation < point?.y &&
          position?.z + DynamicGenerateShape.Min_Deviation > point?.z;

        this.frontFace =
          position?.x + DynamicGenerateShape.Min_Deviation > point?.x &&
          position?.y + DynamicGenerateShape.Deviation > point?.y &&
          position?.y - DynamicGenerateShape.Deviation < point?.y &&
          position?.z + DynamicGenerateShape.Deviation > point?.z;

        this.backFace =
          position?.x - DynamicGenerateShape.Min_Deviation < point?.x &&
          position?.y + DynamicGenerateShape.Deviation > point?.y &&
          position?.y - DynamicGenerateShape.Deviation < point?.y &&
          position?.z - DynamicGenerateShape.Deviation < point?.y;
      } else if (objectName === AvailableShapes.Cylinder) {
        this.topFace =
          position?.x + DynamicGenerateShape.Deviation > point?.x &&
          point?.x > position?.x - DynamicGenerateShape.Deviation &&
          position?.y + DynamicGenerateShape.Min_Deviation <
            point?.y &&
          point?.y <
            position?.y + DynamicGenerateShape.Max_Deviation &&
          position?.z + DynamicGenerateShape.Deviation > point?.z &&
          point?.z > position?.z - DynamicGenerateShape.Deviation;
        this.bottomFace =
          position?.x + DynamicGenerateShape.Deviation > point?.x &&
          position?.x - DynamicGenerateShape.Deviation < point?.x &&
          position?.y - DynamicGenerateShape.Max_Deviation <
            point?.y &&
          position?.y - DynamicGenerateShape.Min_Deviation >
            point?.y &&
          position?.z + DynamicGenerateShape.Deviation > point?.z &&
          position?.z - DynamicGenerateShape.Deviation < point?.z;
        this.rightFace =
          position?.x + DynamicGenerateShape.Deviation > point?.z &&
          position?.y + DynamicGenerateShape.Deviation > point?.y &&
          position?.y - DynamicGenerateShape.Deviation < point?.y &&
          position?.z - DynamicGenerateShape.Min_Dev < point?.z;
        this.leftFace =
          position?.x - DynamicGenerateShape.Deviation < point?.x &&
          position?.y + DynamicGenerateShape.Deviation > point?.y &&
          position?.y - DynamicGenerateShape.Deviation < point?.y &&
          position?.z +  DynamicGenerateShape.Min_Dev  > point?.z
        this.frontFace =
        position?.x +  DynamicGenerateShape.Min_Dev > point?.x &&
        position?.y + DynamicGenerateShape.Deviation > point?.y &&
        position?.y - DynamicGenerateShape.Deviation < point?.y &&
        position?.z + DynamicGenerateShape.Deviation > point?.z
        this.backFace =
        position?.x -  DynamicGenerateShape.Min_Dev <point?.x &&
        position?.y + DynamicGenerateShape.Deviation > point?.y &&
        position?.y - DynamicGenerateShape.Deviation < point?.y &&
        position?.z - DynamicGenerateShape.Deviation <point?.z

        if(this.topFace === true || this.bottomFace === true ){
          this.rightFace = false;
          this.leftFace = false;
          this.frontFace = false;
          this.backFace = false
        }
      } else if (objectName === AvailableShapes.HemiSphere){
        this.bottomFace =
        position?.x + DynamicGenerateShape.Deviation > point?.x &&
        position?.x - DynamicGenerateShape.Deviation < point?.x &&
        position?.y +  DynamicGenerateShape.Min_Dev > point?.y &&
        position?.y -  DynamicGenerateShape.Min_Dev < point?.y &&
        position?.z + DynamicGenerateShape.Deviation > point?.z &&
        position?.z - DynamicGenerateShape.Deviation < point?.z
        this.rightFace =
        position?.x + DynamicGenerateShape.Deviation > point.x &&
        position?.y + DynamicGenerateShape.Deviation > point.y &&
        position?.z -  DynamicGenerateShape.Min_Dev < point?.z
        this.leftFace =
        position?.x - DynamicGenerateShape.Deviation < point?.x &&
        position?.y + DynamicGenerateShape.Deviation > point?.y &&
        position?.z +  DynamicGenerateShape.Min_Dev > point?.z
        this.frontFace =
        position?.x -  DynamicGenerateShape.Min_Dev < point?.x &&
        position?.y + DynamicGenerateShape.Deviation > point?.y &&
        position?.z + DynamicGenerateShape.Deviation > point?.z
        this.backFace =
        position?.x -  DynamicGenerateShape.Min_Dev <point?.x &&
        position?.y + DynamicGenerateShape.Deviation > point?.y &&
        position?.z - DynamicGenerateShape.Deviation < point?.z
      }

      this.invalidJoin = false;
      const seletedShapeFaceType = setFaceTypeForShape(this.selectedShape);
      if (this.rightFace) {
        if (
          intersects[0].object.userData[Object3DUserData.faceType]?.Front ===
            FaceType.Curved ||
          seletedShapeFaceType[Face.Back] === FaceType.Curved
        ) {
          this.invalidJoin = true;
        }
      } else if (this.leftFace) {
        if (
          intersects[0].object.userData[Object3DUserData.faceType]?.Back ===
            FaceType.Curved ||
          seletedShapeFaceType[Face.Front] === FaceType.Curved
        ) {
          this.invalidJoin = true;
        }
      } else if (this.frontFace) {
        if (
          intersects[0].object.userData[Object3DUserData.faceType]?.Right ===
            FaceType.Curved ||
          seletedShapeFaceType[Face.Left] === FaceType.Curved
        ) {
          this.invalidJoin = true;
        }
      } else if (this.backFace) {
        if (
          intersects[0].object.userData[Object3DUserData.faceType]?.Left ===
            FaceType.Curved ||
          seletedShapeFaceType[Face.Right] === FaceType.Curved
        ) {
          this.invalidJoin = true;
        }
      } else if (this.topFace) {
        if (
          intersects[0].object.userData[Object3DUserData.faceType]?.Top ===
            FaceType.Curved ||
          seletedShapeFaceType[Face.Bottom] === FaceType.Curved
        ) {
          this.invalidJoin = true;
        }
      } else if (this.bottomFace) {
        if (
          intersects[0].object.userData[Object3DUserData.faceType]?.Bottom ===
            FaceType.Curved ||
          seletedShapeFaceType[Face.Top] === FaceType.Curved
        ) {
          this.invalidJoin = true;
        }
      }

      this.invalidPointOfGeneration =
        intersects[0].object.name === AvailableShapes.Outline ? true : false;
    });
  }
}
