import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { environment } from "src/environments/environment";
import {
  InitiatePaymentInput,
  InitiatePaymentResponse,
  PaytmCallbackData
} from "@models/payment.model";

import {
  InitiatePhonePePaymentInput,
  PhonePeResponse
} from "@models/phonepe.model";

@Injectable({
  providedIn: "root"
})
export class PaymentService {
  constructor(private http: HttpClient) {}

  public initiatePayment(
    paymentBody: InitiatePaymentInput
  ): Observable<InitiatePaymentResponse> {
    return this.http.post(
      `${environment.apiUrlBasePath}/payment/paytm/initiatePayment`,
      paymentBody
    ) as Observable<InitiatePaymentResponse>;
  }

  public verifyCallbackResponse(data: PaytmCallbackData): Observable<string> {
    return this.http.post(
      `${environment.apiUrlBasePath}/payment/paytm/verifyCallbackResponse`,
      data
    ) as Observable<string>;
  }

  public initiatePhonePePayment(
    data: InitiatePhonePePaymentInput
  ): Observable<PhonePeResponse> {
    return this.http.post<PhonePeResponse>(
      `${environment.apiUrlBasePath}/phonepe/pay`,
      data
    );
  }

  public getPhonePePaymentStatus(
    merchantTransactionId: string
  ): Observable<PhonePeResponse> {
    return this.http.get<PhonePeResponse>(
      `${environment.apiUrlBasePath}/phonepe/payment/${merchantTransactionId}/status`
    );
  }
}
