import { ReviewModel } from "./admin.model";
import { TableColumn } from "./data-grid.model";

export interface ProductBody {
  id: number;
  name: string;
  price: number;
  discountPrice?: number;
  description?: string;
  images?: Images;
  quantity: number;
  value?: number;
  video?: string[];
  isActive?: boolean;
}

export interface BaseProduct {
  name: string;
  price: number;
  discountPrice?: number;
  images?: Images;
  video?: string[];
  isActive: boolean;
  gstRate?: number;
  description?: string;
  model3DJson?: object;
  modelQuantityJson?: object;
  createdBy?: string;
  updatedBy?:string;
  createdByEmail?:string;
  updatedByEmail?:string;
  category?: string;
  averageRating?: number;
  disable?: boolean;
  image?: string;
  isStructureUpdate?:boolean
}

export interface Images {
  imagesUrl: string[];
}

export interface ProductAttributes extends BaseProduct {
  id: number;
}

export interface ProductCreationAttributes extends BaseProduct {
  id?: number;
  structureId?:number;
}

export interface ProductDetails extends ProductAttributes {
  createdAt?: Date;
  updatedAt?: Date;
  quantity: number;
  averageRating?: number;
  reviews?: ReviewModel[];
}

export interface ProductsWithTotalAmount {
  products: ProductDetails[];
  totalAmount: number;
  totalCount?: number;
}

export interface ProductOperation {
  id?: number;
  name: string;
  price: number;
  discountPrice?: number;
  images?: Images;
  video?: string[];
  isActive: boolean;
  gstRate?: number;
  description?: string;
  model3DJson?: object;
  modelQuantityJson?: object;
  createdBy: string;
  updatedBy?:string;
  createdByEmail?:string;
  updatedByEmail?:string;
  category: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface ReviewValues {
  productId: number;
  orderId: number;
  rating: number;
  review?: string;
  status: string;
  name: string;
  images?: Images;
  rejectComment?: string;
  userId: number;
}

export enum RatingResponse {
  Added = "Review added successfully"
}

export enum ErrorMessagesForProductOperation {
  Failed = "Failed to add product",
  FailedToUpload = "Failed to upload to S3",
  FailedUpdate = "Failed to update product",
  FailedGetById = "Failed to get product by id",
  NotFound = "Product not found",
  NotFoundExistingProduct = "Existing product not found",
  NotAnArray = "Parsed video is not an array",
  FailedFetch = "Failed to fetch products",
  FailedFetchInactiveProductIds = "Failed to fetch inactive product ids",
  FailedAddReview = "Failed to add review",
  FailedFetchSuggestedProducts = "Failed to fetch suggested products"
}

export enum FileErrorMessage {
  Fileconfiguration = "Invalid maximum file size configuration",
  NoFile = "No files selected",
  ImageFilesize = "Each image must be less than 2MB",
  ImageMimeType = "Only JPG and PNG files are allowed",
  VideoFileSize = "The video must be less than 20MB",
  VideoFile = "Only video files are allowed",
  VideoMimeType = "only .mp4 and .webM are allowed",
  Model3dFormat = "Invalid JSON format for 3D model",
  ModelQuantityJsonFormat = "Invalid JSON format for quantity model."
}

export const productListColumns: TableColumn[] = [
  { key: "action", value: "Action", type: "action" },
  { key: "images", value: "Image", type: "thumbnail" },
  { key: "name", value: "Name", type: "text" },
  { key: "category", value: "Category", type: "text" },
  { key: "description", value: "Description", type: "text" },
  { key: "price", value: "Price", type: "price" },
  { key: "discountPrice", value: "Discounted Price", type: "price" },
  { key: "gstRate", value: "GST Rate", type: "percent" },
  { key: "isActive", value: "Is Active", type: "boolean" },
  { key: "createdBy", value: "Created By", type: "text", tooltipKey: "createdByEmail" },
  { key: "updatedBy", value: "Updated By", type: "text", tooltipKey: "updatedByEmail" },
  { key: "createdAt", value: "Created At", type: "datetime" },
  { key: "updatedAt", value: "Updated At", type: "datetime" }
];

export enum ProductResponse {
  Added = "Product added successfully",
  Updated = "Product updated successfully"
}

export const productCategories = ["Featured", "On Sale", "Best Seller", "Buy Blocks"];

export enum ProductCategories {
  Featured = "Featured",
  OnSale = "On Sale",
  BestSeller = "Best Seller",
  BuyBlocks = "Buy Blocks"
}
