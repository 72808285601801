import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, tap } from "rxjs";
import { environment } from "src/environments/environment";
import { GoogleMapsConfig } from "@models/map.model";
import {
  AddressDetailsAttributes,
  AddressDetailsCreationAttributes,
  NullableAddress,
  NullableAddresses,
  NullableRegisteredUserDetails,
  UserDetailsCreationAttributes
} from "@models/user.model";

@Injectable({
  providedIn: "root"
})
export class UserService {

  public dataSubject = new BehaviorSubject<number>(0);
  public data = this.dataSubject.asObservable()
  
  constructor(private http: HttpClient) {}

  public addUserDetails(data: {
    userDetails: UserDetailsCreationAttributes;
    addressDetails: AddressDetailsCreationAttributes;
  }): Observable<string> {
    return this.http.post(
      `${environment.apiUrlBasePath}/user/addUserDetails`,
      data
    ) as Observable<string>;
  }

  public getMapSource(): Observable<GoogleMapsConfig> {
    return this.http.get(
      `${environment.apiUrlBasePath}/map/mapSource`
    ) as Observable<GoogleMapsConfig>;
  }

  public getDefaultAddress(): Observable<NullableAddress> {
    return this.http.get(
      `${environment.apiUrlBasePath}/user/default-address`
    ) as Observable<NullableAddress>;
  }

  public getAllAddresses(): Observable<NullableAddresses> {
    return this.http.get(
      `${environment.apiUrlBasePath}/user/addresses`
    ) as Observable<NullableAddresses>;
  }

  public getUserDetails(): Observable<NullableRegisteredUserDetails> {
    return this.http.get(
      `${environment.apiUrlBasePath}/user/getUserDetails`
    ) as Observable<NullableRegisteredUserDetails>;
  }

  public isUserDetailsExist(): Observable<boolean> {
    return this.http.get(
      `${environment.apiUrlBasePath}/user/isUserDetailsExist`
    ) as Observable<boolean>;
  }

  public deleteAddress(id: number): Observable<string> {
    return this.http.delete(
      `${environment.apiUrlBasePath}/user/deleteAddress/${id}`
    ) as Observable<string>;
  }

  public updateAddress(
    body: AddressDetailsCreationAttributes
  ): Observable<string> {
    return this.http.put(
      `${environment.apiUrlBasePath}/user/updateAddress`,
      body
    ) as Observable<string>;
  }

  public addAddress(
    body: AddressDetailsCreationAttributes
  ): Observable<NullableAddress> {
    return this.http.post(
      `${environment.apiUrlBasePath}/user/addAddress`,
      body
    ) as Observable<NullableAddress>;
  }

  public updateEmail(params: {
    email: String;
    id: number;
  }): Observable<string> {
    return this.http.put(
      `${environment.apiUrlBasePath}/user/updateEmail/`,
      params
    ) as Observable<string>;
  }

  public uploadProfileImage(image: {imageUrl: string}): Observable<string> {
    return this.http.put<string>(`${environment.apiUrlBasePath}/user/profileImage`,image).pipe(
      tap(() => this.dataSubject.next(0))   
    );
  }  
}
