<div class="container" *ngIf="order">
  <mat-card class="content">
    <mat-card-content>
      <mat-card-header>
        <p class="order-id">OrderId : {{ order.id }}</p>
      </mat-card-header>
      <div class="btn-group">
        <div class="btn-content" *ngFor="let status of statusArray">
          <span class="status-label">{{ status.label }}</span>
          <button
            mat-fab
            (click)="
              status.label === orderStatus.Dispatched
                ? showDialogBoxCourierInfo()
                : updateStatus(order, status.status)
            "
            [ngClass]="getButtonColor(statusArray, status.status)"
            [disabled]="
              getButtonColor(statusArray, status.status) === 'green' ||
              order.orderStatus === orderStatus.Cancelled ||
              order.orderStatus === orderStatus.ReturnInitiated ||
              isReturnStatus(order.orderStatus) ||
              getButtonColor(statusArray, status.status) === 'grey'
            "
          >
            <mat-icon>{{ status.icon }}</mat-icon>
          </button>
          <p>
            {{ getStatusDate(status.status) }}
          </p>
          <div
            *ngIf="
              status.status === orderStatus.Dispatched && hasBeenDispatched()
            "
          >
            <p class="awb-number">
              <strong>AWB Number :</strong>
              <span class="text-color">{{ order.awbNumber }}</span>
            </p>
            <p class="courier-name">
              <strong>Courier Name : </strong
              ><span class="text-color">{{ order.courierName }}</span>
            </p>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card
    class="content"
    *ngIf="
      order.orderStatus === orderStatus.ReturnInitiated ||
      isReturnStatus(order.orderStatus)
    "
  >
    <mat-card-content>
      <mat-card-header>
        <p class="order-id">Return Order Status :</p>
      </mat-card-header>
      <div class="btn-group">
        <div class="btn-content" *ngFor="let status of returnOrderStatusArray">
          <span class="status-label">{{ status.label }}</span>
          <button
            mat-fab
            (click)="
              status.label === orderStatus.PickupScheduled
                ? showDialogBoxPickupScheduled()
                : updateStatus(order, status.status)
            "
            [ngClass]="getButtonColor(returnOrderStatusArray, status.status)"
            [disabled]="
              getButtonColor(returnOrderStatusArray, status.status) ===
                'green' ||
              getButtonColor(returnOrderStatusArray, status.status) === 'grey'
            "
          >
            <mat-icon>{{ status.icon }}</mat-icon>
          </button>
          <p>
            {{ getStatusDate(status.status) }}
          </p>
          <div
            class="pickup-scheduled"
            *ngIf="
              status.status === orderStatus.PickupScheduled &&
              ispickupSchedule()
            "
          >
            <p>
              <strong>Pickup Date :</strong>
              <span class="text-color">{{ pickupScheduleDate }}</span>
            </p>
            <p>
              <strong>Pickup Time : </strong
              ><span class="text-color">{{ order.pickupTime }}</span>
            </p>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <div class="customer-info">
    <mat-card class="content">
      <mat-card-content>
        <div class="customer-info-content">
          <div class="left">
            <h3>Customer Information :</h3>
            <p>Name : {{ order.recipientName
            }}</p>
            <p>Phone Number : {{ order.recipientMobileNumber }}</p>
            <p>Email Id : {{ order.customerEmail }}</p>
            <p>
              Address : {{ order.recipientHouseNumber }},
              {{ order.recipientAddress }}, {{ order.recipientCity }},
              {{ order.recipientState }},{{ order.recipientPincode }}
            </p>
          </div>
          <div>
            <h3>Status :</h3>
            <p>
              Payment :
              <span
                [ngClass]="{
                  success: order.paymentStatus === paymentStatus.Success,
                  pending: order.paymentStatus === paymentStatus.Pending,
                  fail: order.paymentStatus === paymentStatus.Failed
                }"
                >{{ order.paymentStatus }}</span
              >
            </p>
            <p *ngIf="order.refundStatus">
              Refund Status :<span
                [ngClass]="{
                  success: order.refundStatus === refundStatus.RefundSuccessful,
                  pending: order.refundStatus !== refundStatus.RefundSuccessful,
                  fail: order.refundStatus === refundStatus.RefundFailed
                }"
                >{{ order.refundStatus }}</span
              >
            </p>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <div *ngFor="let product of order.products">
    <mat-card class="content">
      <mat-card-content>
        <div class="product-info">
          <div>
            <img [src]="product.images?.imagesUrl?.[0]" alt="Item Image" />
          </div>
          <div>
            <p>Product Name : {{ product.name }}</p>
            <p>Quantity: {{ product.quantity }}</p>
            <p>Price: Rs {{ product.discountPrice }}</p>
          </div>
        </div>
      </mat-card-content>
      <hr />
    </mat-card>
  </div>

  <div>
    <mat-card class="content">
      <mat-card-content>
        <p class="order-info">
          <strong>Sub Total : </strong>
          <span>₹ {{ order.totalAmount - order.deliveryCharge }}</span>
        </p>
        <p class="order-info">
          <strong>Delivery Charge : </strong>
          <span>₹ {{ order.deliveryCharge }}</span>
        </p>
        <p class="order-info">
          <strong>Total Amount : </strong>
          <span>₹ {{ order.totalAmount }}</span>
        </p>
      </mat-card-content>
    </mat-card>
  </div>
</div>
