import { ChangeDetectorRef, Component, OnInit, inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { ProductBody } from "@models/product.model";
import { OrderPageDetail } from "src/models/order.model";
import { RatingAndReviewComponent } from "./rating-and-review/rating-and-review.component";
import { OrderStatus, status } from "../../models/order.model";
import { OrderService } from "../services/order/order.service";
import { AdminStatusHistoryModel } from "@models/adminStatusHistory.model";
import { UserService } from "../services/user/user.service";
import { ReviewModel } from "@models/admin.model";
import { environment } from "src/environments/environment";
import { PaymentStatus } from "@models/payment.model";
import { GenericDialogComponent } from "../shared/generic-dialog/generic-dialog.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DialogInvokingComponents } from "@models/generic-dialog.model";
import { config, finalize } from "rxjs";

@Component({
  selector: "app-order-details",
  templateUrl: "./order-details.component.html",
  styleUrls: ["./order-details.component.scss"]
})
export class OrderDetailsComponent implements OnInit {
  public order!: OrderPageDetail;
  public filteredStatusHistory?: AdminStatusHistoryModel[] = [];
  public showTimeline!: boolean;

  public currency = environment.currency.indianRupee;
  public totalProducts: number | undefined;
  public subtotal: number | undefined;
  readonly dialog = inject(MatDialog);
  public orderStatusHistory: AdminStatusHistoryModel[] = [];
  public orderStatusTrack!: status[];
  public showTrack!: boolean;
  public getUserId!: number;
  public OrderStatus = OrderStatus;
  public currentOrder: OrderPageDetail | undefined;
  public returnReason!: string;
  public showReturnPopup = false;
  public loading: boolean = true;

  constructor(
    private orderService: OrderService,
    private route: ActivatedRoute,
    private userService: UserService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.getOrderDetailsbyId();
  }
  public getOrderDetailsbyId(): void {
    const orderID = this.route.snapshot.params["id"];
    this.orderService.getOrderById(orderID)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe({
        next: response => {
          this.loading = true;
          if (response === null) {
            this.router.navigate(["/noAccess"]);
          }
          this.order = response;
          this.cdr.detectChanges();

          this.filteredStatusHistory = this.order.orderStatusHistory?.filter(history => {
            return history.status !== 'Pending' && history.status !== 'Failed';
          });

          if (this.filteredStatusHistory?.length) {
            this.showTimeline = true;
          }
        },
        error: (error) => {
          throw error;
        }
      });
    this.userService.getUserDetails().subscribe(response => {
      if (response) {
        this.getUserId = response.id;
      }
    });
  }

  public getOrderStatusClasses(): { [key: string]: boolean } {
    return {
      failed: this.order.orderStatus === OrderStatus.Failed,
      pending: this.order.orderStatus === OrderStatus.Pending,
      ordered: this.order.orderStatus === OrderStatus.Ordered,
      packed: this.order.orderStatus === OrderStatus.Packed,
      cancelled: this.order.orderStatus === OrderStatus.Cancelled,
      dispatched: this.order.orderStatus === OrderStatus.Dispatched,
      delivered: this.order.orderStatus === OrderStatus.Delivered,
      returnInitiated: this.order.orderStatus === OrderStatus.ReturnInitiated,
      pickupScheduled: this.order.orderStatus === OrderStatus.PickupScheduled,
      returned: this.order.orderStatus === OrderStatus.Returned
    };
  }

  // Example method to dynamically set classes based on payment status
  public getPaymentStatusClasses(): { [key: string]: boolean } {
    return {
      pending: this.order.paymentStatus === PaymentStatus.Pending,
      success: this.order.paymentStatus === PaymentStatus.Success,
      failed: this.order.paymentStatus === PaymentStatus.Failed
    };
  }

  public isOverflow(el: HTMLElement): boolean {
    return el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;
  }

  public isReviewDisabled(product: ProductBody): boolean {
    if (!this.order || !this.order.reviews) {
      return true;
    }

    return this.order.reviews.some(
      (review: ReviewModel) =>
        review.productId === product.id && review.userId === this.getUserId
    );
  }

  public openRatingPopup(product: ProductBody, order: OrderPageDetail): void {
    const dialogRef = this.dialog.open(RatingAndReviewComponent, {
      disableClose: true,
      width: "450px",
      data: {
        product_details: product,
        order_details: order,
        userId: this.getUserId
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        setTimeout(() => {
          this.getOrderDetailsbyId();
        }, 1000);
      }
    });
  }

  private openDialog(): void {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      data: {
        componentName: DialogInvokingComponents.CancelOrder,
        title: "Cancel Order",
        content:
          "Order will be cancelled, and the amount will be refunded within 5-7 working days",
        firstBtn: "OK",
        secondBtn: "Cancel"
      },
      autoFocus: false,
      restoreFocus: false,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.confirmCancel();
      }
    });
  }

  private confirmCancel(): void {
    if (this.currentOrder) {
      this.orderService.cancelOrder(this.currentOrder.id).subscribe(response => {
        if (response) {
          this.getOrderDetailsbyId();
          this.snackBar.open(response.message, "Close", {
            duration: 3000
          });
        }
      })
    }
  }

  public cancel(order: OrderPageDetail): void {
    if (this.isCancellable(order)) {
      this.currentOrder = order;
      this.openDialog();
    }
  }

  public isCancellable(order: OrderPageDetail): boolean {
    return [OrderStatus.Packed, OrderStatus.Ordered].includes(order.orderStatus as OrderStatus)
  }

}
