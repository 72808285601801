import { Component, OnInit } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { Location } from "@angular/common";
import { NavigationEnd, Router, Event as RouterEvent } from "@angular/router";
import { RouteDataService } from "./services/route-data.service";
import { Subscription, filter, map, switchMap } from "rxjs";
import { AdminService } from './services/admin/admin.service';
import { RouteData } from '@models/map.model';
import { AuthService } from "./services/auth/auth.service";
import { CartItemService } from "./services/cart-item/cart-item.service";
import { WishlistService } from "./services/wishlistService/wishlist.service";

interface IconPaths {
  [key: string]: string;
}

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
  public isShowHeader = false;
  public showFooter = true;
  public isShowBackButton = true;
  private subscriptions: Subscription = new Subscription();
  public showProductDetailsMessage = false;
  private previousNavigationId: number | null = null;

  private iconPaths: IconPaths = {
    udm: "/assets/udm_icon.svg",
    funFigz: "/assets/funFigz-logo.svg",
    checkMark: "/assets/check-mark.svg",
    emptyCart: "/assets/empty-cart.svg",
    zoomIn: "/assets/zoom-in.svg",
    zoomOut: "/assets/zoom-out.svg",
    instagram: "/assets/instagram.svg",
    twitter: "/assets/twitter.svg",
    facebook: "/assets/facebook.svg",
    emptyNotification: "/assets/noNotification.svg",
    emptyWishlist: "/assets/emptyWishlist.svg"
  };

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private router: Router,
    public routeDataService: RouteDataService,
    private location: Location,
    private adminService: AdminService,
    private authService: AuthService,
    private cartService: CartItemService,
    private wishlistService: WishlistService
  ) {
    this.registerIcons();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.showFooter = this.canShowFooter(event.url);
      }
    });
  }

  ngOnInit(): void {
    const routerEventSubscription = this.router.events
      .pipe(
        filter((event: RouterEvent): event is NavigationEnd => event instanceof NavigationEnd),
        switchMap((event: NavigationEnd) => {
          window.scrollTo(0, 0);
  
          return this.routeDataService
            .get({
              noHeader: false,
              noBackButton: false
            })
            .pipe(map((data: RouteData) => ({ data, url: event.urlAfterRedirects })));
        })
      )
      .subscribe(({ data, url }) => {
        this.updateHeaderAndBackButtonVisibility(data, url);
        this.showProductDetailsMessage = /products\/\d+/.test(url) || url.includes('home');
  
        // Check if navigation ID is 1 and not repeating for the same session
        if (this.authService.isUserLoggedIn && !url.startsWith("/admin") && !url.includes("designer")) {
          const currentNavigationId = history.state.navigationId;
  
          if (currentNavigationId === 1 && currentNavigationId !== this.previousNavigationId) {
            if (!url.startsWith('/order/success')) {
              this.cartService.processCartItems();
            }
            this.wishlistService.loadMyWishlistItems();
          }
  
          // Update the previous navigation ID to prevent multiple hits
          this.previousNavigationId = currentNavigationId;
        }
      });
  
    this.subscriptions.add(routerEventSubscription);
  }
  

  private updateHeaderAndBackButtonVisibility(data: {
    noBackButton: boolean, noHeader: boolean
  }, url: string): void {
    const isAdminLoginPage = url.startsWith("/admin") || url.startsWith("/designer");
    if (isAdminLoginPage) {
      this.isShowHeader = false;
    } else {
      this.isShowHeader = !data.noHeader;
    }
    this.isShowBackButton = !data.noBackButton;
  }

  private registerIcons() {
    Object.keys(this.iconPaths).forEach(key => {
      const iconPath = this.iconPaths[key];
      this.matIconRegistry.addSvgIcon(
        key,
        this.domSanitizer.bypassSecurityTrustResourceUrl(iconPath)
      );
    });
  }

  // Designer takes the entire screen as canvas, footer wont be necessary
  private canShowFooter(url: string): boolean {
    return !url.includes("designer");
  }

  public goBack(): void {
    this.location.back();
  }
}
