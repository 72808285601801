<div class="container">
  <app-header *ngIf="isShowHeader" class="header"></app-header>
  <div class="running-text-container" *ngIf="showProductDetailsMessage">
      <marquee
        >We strive to deliver all orders within 2 days for your convenience and
        satisfaction.</marquee
      >
  </div>
  <div class="body-wrap">
    <div class="back-btn-container" *ngIf="isShowBackButton">
      <button (click)="goBack()" class="back">
        <mat-icon class="icon">arrow_back</mat-icon
        >
      </button>
    </div>
    <router-outlet></router-outlet>
  </div>
  <app-footer *ngIf="showFooter"></app-footer>
</div>
