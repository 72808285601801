import { Component, OnInit } from "@angular/core";
import { OrderService } from "../../services/order/order.service";
import {
  OrderPageDetail,
  OrderStatus,
  OrderStatusItem
} from "@models/order.model";
import { AdminStatusHistoryModel } from "@models/adminStatusHistory.model";
import { ActivatedRoute } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { GenericDialogComponent } from "../../shared/generic-dialog/generic-dialog.component";
import * as moment from "moment";
import { PaymentStatus, RefundStatus } from "@models/payment.model";
import { DialogInvokingComponents } from "@models/generic-dialog.model";

@Component({
  selector: "app-admin-order-page",
  templateUrl: "./admin-order-page.component.html",
  styleUrls: ["./admin-order-page.component.scss"]
})
export class AdminOrderPageComponent implements OnInit {
  public order!: OrderPageDetail;
  public id!: number;
  public status!: string[];
  public latestStatus!: string;
  public pickUpDate!: Date;
  public pickUpTime!: string;
  public awbNumber!: string;
  public courierName!: string;
  public responseData!: AdminStatusHistoryModel;
  public dateArray!: string[];
  public orderStatus = OrderStatus;
  public paymentStatus = PaymentStatus;
  public refundStatus = RefundStatus;
  public pickupScheduleDate!: string;
  public formattedDate!: string;
  public statusArray = [
    {
      status: OrderStatus.Ordered,
      label: OrderStatus.Ordered,
      icon: "shopping_cart"
    },
    {
      status: OrderStatus.Packed,
      label: OrderStatus.Packed,
      icon: "inventory"
    },
    {
      status: OrderStatus.Dispatched,
      label: OrderStatus.Dispatched,
      icon: "flight_takeoff"
    },
    {
      status: OrderStatus.Delivered,
      label: OrderStatus.Delivered,
      icon: "check_circle"
    }
  ];

  public returnOrderStatusArray = [
    {
      status: OrderStatus.ReturnInitiated,
      label: OrderStatus.ReturnInitiated,
      icon: "replay"
    },
    {
      status: OrderStatus.PickupScheduled,
      label: OrderStatus.PickupScheduled,
      icon: "pending_actions"
    },
    {
      status: OrderStatus.Returned,
      label: OrderStatus.Returned,
      icon: "check_circle"
    }
  ];

  constructor(
    private orderService: OrderService,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params["id"];
    this.getOrderDetails();
  }

  public getOrderDetails(): void {
    this.orderService.getOrderDetails(this.id).subscribe(response => {
      if (response) {
        this.order = response;
        this.pickupScheduleDate = moment(this.order.pickupDate).format(
          "YYYY-MM-DD"
        );
        const removeRefund = this.order.orderStatusHistory?.filter(
          statusHistory => !statusHistory.status.includes("Refund")
        );
        if (removeRefund?.length) {
          this.latestStatus = removeRefund?.[0].status || "";
        } else {
          this.latestStatus = this.order.orderStatusHistory?.[0].status || "";
        }
        this.status =
          this.order.orderStatusHistory?.map(history => history.status) || [];
      }
    });
  }

  public showDialogBoxCourierInfo(): void {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      data: {
        componentName: DialogInvokingComponents.CourierInfo,
        title: "Enter Courier Information",
        inputs: [
          {
            label: "Courier Name",
            value: "",
            placeholder: "Enter Courier Name",
            controlName: "courierName",
            type: "text"
          },
          {
            label: "AWB Number",
            value: "",
            placeholder: "Enter AWB Number",
            controlName: "awbNumber",
            type: "text"
          }
        ],
        firstBtn: "Save",
        secondBtn: "Cancel"
      },
      autoFocus:false,
      restoreFocus:false,
    });
    dialogRef.componentInstance.saveClicked.subscribe(savedData => {
      this.awbNumber = savedData.awbNumber;
      this.courierName = savedData.courierName;
      this.updateStatus(this.order, OrderStatus.Dispatched);
    });
  }

  public showDialogBoxPickupScheduled(): void {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      disableClose:true,
      data: {
        componentName: DialogInvokingComponents.PickupScheduled,
        title: "Enter the Pickup Scheduled",
        inputs: [
          {
            label: "Date",
            value: "",
            placeholder: "Choose a date",
            controlName: "date",
            type: "date"
          },
          {
            label: "Time",
            value: "",
            placeholder: "Select a time",
            controlName: "time",
            type: "time"
          }
        ],
        firstBtn: "Save",
        secondBtn: "Cancel"
      },
      autoFocus:false,
      restoreFocus:false,
    });
    dialogRef.componentInstance.saveClicked.subscribe(savedData => {
      this.pickUpDate = savedData.date;
      this.pickUpTime = savedData.time;
      this.updateStatus(this.order, OrderStatus.PickupScheduled);
    });
  }

  public updateStatus(order: OrderPageDetail, newStatus: string): void {
    const id = this.id;
    const date = this.pickUpDate;
    const time = this.pickUpTime;
    const awbNumber = this.awbNumber;
    const courierName = this.courierName;
    const data = { id, newStatus, date, time, order, awbNumber, courierName };
    this.orderService.addOrderStatus(data).subscribe({
      next: response => {
        this.formattedDate = moment(response.createdAt).format("ddd DD, YYYY");
        this.latestStatus = newStatus;
        this.getOrderDetails();
      },
      error: error => {
        alert(error.error)
      }
    });
  }

  public getButtonColor(
    statusArray: OrderStatusItem[],
    buttonStatus: string
  ): string | undefined {
    const currentIndex = statusArray.findIndex(
      (status: { status: string }) => status.status === this.latestStatus
    );
    const buttonIndex = statusArray.findIndex(
      (status: { status: string }) => status.status === buttonStatus
    );

    if (buttonIndex === currentIndex || buttonIndex < currentIndex) {
      return "green"; // Current status
    } else if (buttonIndex === currentIndex + 1) {
      return "yellow"; // Next status
    } else {
      return "grey"; // Other statuses
    }
  }

  public isReturnStatus(status: string): boolean {
    return this.returnOrderStatusArray.some(s => s.status === status);
  }

  public getStatusDate(status: string): string | undefined {
    if (this.order && this.order.orderStatusHistory) {
      const historyEntry = this.order.orderStatusHistory.find(
        history => history.status === status
      );
      return historyEntry
        ? moment(historyEntry.createdAt).format("DD/MM/yyyy hh:mm a")
        : undefined;
    }
    return undefined;
  }

  public ispickupSchedule(): boolean {
    return this.status.includes(OrderStatus.PickupScheduled);
  }

  public hasBeenDispatched(): boolean {
    return this.status.includes(OrderStatus.Dispatched);
  }
}
