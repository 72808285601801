import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { CanvasConfig, ZoomConfig } from "@utils/canvas-configuration";
import {
  AlteredShapeConfiguration,
  PlaneCustomProperties,
} from "@utils/shape";
import { MeshModel } from "@models/mesh.model";
import { ComponentInteractionSrevice } from "../services/component-interaction/component-interaction.service";
import { ColorCodes } from "@utils/color-constants";
import { InteractionService } from "../shared/helpers/interaction.service";
import { SetShapeUserDataService } from "../shared/helpers/set-shape-userdata.service";
import { ActivatedRoute, Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { StructureBaseModel, StructureInstance, StructureResponseMessage, StructureStatus } from "@models/structure.model";
import { ColorHEX } from "../../utils/action";
import { AvailableShapes } from "../../utils/shape-facetype";
import { LoadStructureService } from '../load-structure.service';
import { MatDialog } from '@angular/material/dialog';
import { GenericDialogComponent } from '../shared/generic-dialog/generic-dialog.component';
import { DialogInvokingComponents } from '@models/generic-dialog.model';
import { DialogService } from '../services/dialog/dialog.service';
import { StructureService } from '../services/structure/structure.service';
import { filter, distinctUntilChanged, merge, scan, Subscription } from "rxjs";
import { Location } from "@angular/common";
import { GenerateNewShapes } from "../shared/generate-new-shapes";
import { CountCalculatorService } from "../services/count-calculator.service";
import { CountOutputData } from "../../../../utils/count-calculator/count-calculator.model";

@Component({
  selector: "new-canvas",
  templateUrl: "./canvascomponent.component.html",
  styleUrls: ["./canvascomponent.component.scss"],
})
export class CanvasComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("rendererContainer", { static: false })
  rendererContainer: ElementRef | any;
  private objects: THREE.Object3D[] = [];
  public isEditMode: boolean = false;
  public isEditted: boolean = false;
  public initialObjectsLength: number = 0;
  private renderer: THREE.WebGLRenderer;
  private outputPattern!: CountOutputData[];
  private scene: THREE.Scene;
  private camera: THREE.PerspectiveCamera;
  private plane!:
    | THREE.Mesh<THREE.BoxGeometry, THREE.MeshLambertMaterial>
    | MeshModel;
  private orbitControl!: OrbitControls;
  public zoomConfig = ZoomConfig;
  public pushCapturedImage: File[] = [];
  public isDisabledCaptureBtn: boolean = false;
  @Input() isViewMode = false;
  public imageDataToChild!: File;
  private structureId: string | null = null;
  private capturedImageLength = 0;
  private structureImageSub!: Subscription;
  private generatedShapeSub!: Subscription;
  private objectLengthSub!: Subscription;
  private clearStructureSub!: Subscription;
  private isEdittedSub!: Subscription;
  public autoCapturedImage!: string;

  constructor(
    private componentInteractionSrv: ComponentInteractionSrevice,
    private interactionService: InteractionService,
    private ngZone: NgZone,
    private setShapeUserDataSrv: SetShapeUserDataService,
    private router: Router,
    private snackBar: MatSnackBar,
    private loadStructureService: LoadStructureService,
    private dialog: MatDialog,
    private dialogService: DialogService,
    private structureService: StructureService,
    private route: ActivatedRoute,
    private location: Location,
    private generateShapeService: GenerateNewShapes,
    private countCalculatorService: CountCalculatorService
  ) {
    this.renderer = new THREE.WebGLRenderer({
      alpha: true,
      preserveDrawingBuffer: true,
    });
    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color(ColorCodes.sceneBackground);
    this.camera = new THREE.PerspectiveCamera(
      CanvasConfig.perspectiveCameraFov,
      CanvasConfig.perspectiveCameraAspectRatio,
      CanvasConfig.perspectiveCameraNearField,
      CanvasConfig.perspectiveCameraFarField
    );
  }

  ngOnInit(): void {
    this.setOrbitControl();
    this.subscribeToInteractiveEvents();
    this.startAnimationLoop();

    this.route.paramMap.subscribe((paramMap) => {
      if (paramMap.has('id')) {
        this.isEditMode = true;
        this.structureId = paramMap.get('id')!;
        this.loadStructure(+this.structureId);
      }
    });

    this.route.queryParamMap.subscribe((queryParamMap) => {
      if (queryParamMap.has('id')) {
        this.isEditMode = true;
        this.structureId = queryParamMap.get('id')!;
        this.loadStructure(+this.structureId);

      } else if (!this.isEditMode) {
        // If no ID is found in either paramMap or queryParamMap, set isEditMode to false
        this.isEditMode = false;
        this.structureId = "";
      }
    });

    const isEditted$ = this.interactionService.isEditted$;
    const isNewShapeGenerated$ = this.generateShapeService.isNewShapeGenerated$;

    const merged$ = merge(isEditted$, isNewShapeGenerated$).pipe(
      scan((acc, current) => acc || current, false), // Maintain a state of `true` if any observable emits `true`
      distinctUntilChanged() // Emit only when the value changes
    );

    // Subscribe only when isStructureLoaded and isEditMode are true
    this.isEdittedSub = merged$.pipe(
      filter(() => this.isEditMode)
    ).subscribe(value => {
      this.isEditted = value;
    });

    this.clearStructureSub = this.loadStructureService.clearStructure.subscribe(() => {
      this.clearStructure();
    });

    this.objectLengthSub = this.loadStructureService.objectLength.subscribe((length) => {
      this.initialObjectsLength = length;
      this.objects = this.loadStructureService.getObject();
      this.componentInteractionSrv.setObjectInfo(this.objects);
    });

    this.structureImageSub = this.structureService.structureImage$.subscribe(() => {
      this.capturedImageLength = this.structureService.getImagesCount();
      this.isDisabledCaptureBtn = this.capturedImageLength > 9;
    });

    window.addEventListener('beforeunload', this.handleBeforeUnload);
  }

  private setOrbitControl(): void {
    this.orbitControl = new OrbitControls(
      this.camera,
      this.renderer.domElement
    );
  }
  public structureStatus = StructureStatus;

  private subscribeToInteractiveEvents(): void {
    this.interactionService.subscribeToEvents(
      this.renderer,
      this.camera,
      this.scene,
      this.objects
    );
  }

  private unsubscribeToInteractiveEvents(): void {
    this.interactionService.unsubscribeToEvents();
  }

  private loadStructure(structureId: number): void {
    this.structureService.getStructureDetails(structureId).subscribe(structure => {
      // this.isEditMode = structure.structureData?.status !== this.structureStatus.Published;
      this.loadStructureService.loadStructure(structure.structureData, this.objects, this.scene);
    })
  }

  private handleBeforeUnload = (event: BeforeUnloadEvent) => {
    if (this.isEditted) {
      event.preventDefault();
      event.returnValue = ''; // Standard way to trigger the prompt
    }
  }

  private clearStructure(): void {
    this.objects = this.objects?.filter((object) => {
      if (object.name !== AvailableShapes.Plane) {
        this.scene.remove(object);
        return false;
      }
      return true;
    });
  }

  public get structureData(): StructureInstance | null {
    return this.isEditMode ? this.loadStructureService.structureData : null;
  }

  // This is for stalling the animation loop untill we initialise our component's variables.
  private startAnimationLoop(): void {
    this.ngZone.runOutsideAngular(() => {
      this.animate();
    });
  }

  ngAfterViewInit(): void {
    if (this.rendererContainer) {
      this.rendererConfiguration();// RENDERER CONFIGURATION
    }
    this.cameraConfiguration(); // CAMERA CONFIGURATION
    this.controlsConfiguration(); // ORIBIT CONTROLLER CONFIGURATION
    this.createPlane(); // FOR PLANE CREATION

    this.generatedShapeSub = this.componentInteractionSrv.getGeneratedShapeInfo().subscribe((shape) => {
      this.objects.push(shape);
      this.componentInteractionSrv.setObjectInfo(this.objects);
      this.setShapeUserDataSrv.adjacentData(this.objects, shape);
      this.scene.add(shape);
      this.isEditted = true;
    });

    this.orbitControl.addEventListener("change", () => {
      // Update the compass cube with the new rotation
      this.componentInteractionSrv.updateRotation(this.camera.position.clone());
    });

    window.addEventListener('resize', () => this.onWindowResize());
  }

  private rendererConfiguration(): void {
    this.onWindowResize();
    this.renderer.setClearColor(ColorCodes.renderSetClear);
    this.rendererContainer.nativeElement.appendChild(this.renderer.domElement);
  }

  private onWindowResize(): void {
    const container = this.rendererContainer.nativeElement;
    const width = container.clientWidth;
    const height = container.clientHeight
    this.renderer.setSize(width, height);
  }

  private cameraConfiguration(): void {
    this.camera.position.set(
      CanvasConfig.cameraPosition_X_Cooridinate,
      CanvasConfig.cameraPosition_Y_Cooridinate,
      CanvasConfig.cameraPosition_Z_Cooridinate
    );
    this.camera.add(
      new THREE.PointLight(
        ColorCodes.pointLight,
        CanvasConfig.pointLightIntensity
      )
    ); // point light at camera position
    this.scene.add(
      new THREE.DirectionalLight(
        ColorCodes.directionalLight,
        CanvasConfig.directionalLightIntensity
      )
    );
  }

  private controlsConfiguration(): void {
    const orbitControl = this.orbitControl;

    const {
      orbitControlMaxDistance,
      orbitControlTarget_X_Coordinate,
      orbitControlTarget_Y_Coordinate,
      orbitControlTarget_Z_Coordinate,
      oribitControlRotateSpeed,
      oribitControlZoomSpeed,
      oribitControlPanSpeed,
      orbitControlDynamicDampingFactor,
      orbitControlMaxPolarAngleDivisor,
    } = CanvasConfig;

    Object.assign(orbitControl, {
      enableZoom: true,
      enablePan: true,
      maxDistance: orbitControlMaxDistance,
      target: new THREE.Vector3(
        orbitControlTarget_X_Coordinate,
        orbitControlTarget_Y_Coordinate,
        orbitControlTarget_Z_Coordinate
      ),
      rotateSpeed: oribitControlRotateSpeed,
      zoomSpeed: oribitControlZoomSpeed,
      panSpeed: oribitControlPanSpeed,
      enableDamping: true,
      dynamicDampingFactor: orbitControlDynamicDampingFactor,
      maxPolarAngle: Math.PI / orbitControlMaxPolarAngleDivisor,
    });
  }

  private createPlane(): void {
    const gridHelperForCanvas = new THREE.GridHelper(
      CanvasConfig.gridHelperSize,
      CanvasConfig.gridHelperDivisions
    );
    this.scene.add(gridHelperForCanvas);
    const geometryForCanvas = new THREE.BoxGeometry(
      CanvasConfig.canvasWidth,
      CanvasConfig.canvasHeight,
      CanvasConfig.canvasDepth
    ); // CONFIGURING DEMENSIONS FOR PLANE USING BOX GEOMETRY
    const materialForCanvas = new THREE.MeshLambertMaterial({
      color: ColorCodes.canvasMaterial,
    }); // CONFIGURING THE COLOR TO PLANE WITH HELP MESH-LAMBERT-METERIAL USING COLOR PROPERTY
    this.plane = new THREE.Mesh(geometryForCanvas, materialForCanvas); // ADDING THE GEOMETRY AND MATERIAL TO THE MESH
    this.plane.position.y = CanvasConfig.canavasPostionIn_Y_Coordinate;
    gridHelperForCanvas.position.y =
      CanvasConfig.canavasPostionIn_Y_Coordinate +
      AlteredShapeConfiguration.gridHelperYCoOrdinatesAdjustment;
    this.plane.name = PlaneCustomProperties.name;
    this.plane.userData[PlaneCustomProperties.orientationKey] = false;
    this.plane.userData[PlaneCustomProperties.isDraggable] = false;
    this.scene.add(this.plane); // ADDING THE MESH TO SCENE FOR THE VISABILITY
    this.objects.push(this.plane);
  }

  private animate(): void {
    window.requestAnimationFrame(() => this.animate());
    this.interactionService.render();
    this.orbitControl.update();
  }

  public redirectPage() {
    if (this.isEditted) {
      this.openDialog();
      this.componentInteractionSrv.setSelectedShape('');
    } else {
      this.location.back();
      this.componentInteractionSrv.setSelectedShape('');
    }
  }

  private openDialog(): void {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      data: {
        componentName: DialogInvokingComponents.SaveDesignConfirmation,
        title: this.isEditMode ? "Update Design Confirmation" : "Save Design Confirmation",
        content: this.isEditMode ? "Do you want to update your structure?" : "Do you want to save your structure?",
        firstBtn: "Yes",
        secondBtn: "No"
      },
      autoFocus: false,
      restoreFocus: false,
    })

    dialogRef.afterClosed().subscribe(saveClicked => {
      if (saveClicked === true) {
        if (this.objects.length <= 1) {
          this.openSnackBar(StructureResponseMessage.NoStructureCreated, "Okay");
          return;
        } else {
          const objectsWithoutPlaneMesh = this.objects.filter((object) => {
            return object.name != "plane";
          });
          this.autoCaptureImageToActionBtn(true);
          const objectsWithoutPlaneJson = objectsWithoutPlaneMesh.map((object) => {
            const objectJson = object.toJSON();
            object.userData['rotation'] = {
              x: object.rotation.x,
              y: object.rotation.y,
              z: object.rotation.z,
              order: object.rotation.order
            };
            return objectJson;
          });

          const data = {
            componentName: DialogInvokingComponents.StructureDetails,
            title: this.isEditMode ? "Edit Structure" : "Save Structure",
            firstBtn: this.isEditMode ? "Update" : "Save",
            secondBtn: "Cancel",
            structureName: this.structureData?.structureName || '',
            structureDescription: this.structureData?.structureDescription || ''
          };

          this.dialogService.openDialog(data).afterClosed().subscribe(async response => {
            if (!response) {
              return;
            };
            if (this.imageDataToChild) {
              await this.convertAndSetImageData(this.imageDataToChild);
            }

            this.countCalculatorService.calculate(this.objects);
            const body: StructureBaseModel = {
              structureName: response.structureName,
              structureDescription: response.structureDescription,
              structureData: JSON.stringify(objectsWithoutPlaneJson),
              status: StructureStatus.Saved,
              imageurl: this.autoCapturedImage,
              modelQuantityJson: {
                totalcount: this.objects.filter(mesh => mesh.name !== AvailableShapes.Plane).length,
                faceinformation: this.outputPattern,
              }
            };
            if (this.structureData?.id) {
              this.structureService.updateStructure(this.structureData?.id, body)
                .subscribe({
                  next: (response) => {
                    this.openSnackBar(response.message, "okay")
                    this.router.navigateByUrl("myStructures");
                  },
                  error: (error) => {
                    this.openSnackBar(error.error, "Okay");
                  }
                });
            }
            else {
              this.structureService.saveStructure(body)
                .subscribe({
                  next: (response) => {
                    this.openSnackBar(response.message, "okay");
                    this.router.navigateByUrl("myStructures");
                  },
                  error: (error) => {
                    this.openSnackBar(error.error, "Okay");
                  }
                });
            }
          });
        }
      }
      else {
        this.location.back();
      }
    });
  }

  private openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      horizontalPosition: "center",
      verticalPosition: "bottom",
      duration: 4000
    });
  }


  public zoom(direction: string): void {
    switch (direction) {
      case this.zoomConfig.zoomIn:
        if (this.camera.zoom < this.zoomConfig.maximumAllowedZoom) {
          this.camera.zoom += this.zoomConfig.zoomIncrement;
          this.camera.updateProjectionMatrix();
        } else {
          this.snackBar.open(
            this.zoomConfig.maxZoomLimitMessage,
            this.zoomConfig.closeMessage,
            { duration: 2000 }
          );
        }
        break;
      case this.zoomConfig.zoomOut:
        if (this.camera.zoom > 0.5) {
          this.camera.zoom -= 0.2;
          this.camera.updateProjectionMatrix();
        } else {
          this.snackBar.open(
            this.zoomConfig.maxZoomLimitMessage,
            this.zoomConfig.closeMessage,
            { duration: 2000 }
          );
        }
        break;
      default:
        break;
    }
  }

  // For download
  public captureImage(): void {
    if (this.isDisabledCaptureBtn) {
      return;
    }
    // Store the references to be restored later
    const gridHelperForCanvas = this.scene.children.find(
      child => child instanceof THREE.GridHelper
    ) as THREE.GridHelper;

    const planeMesh = this.plane as THREE.Mesh<THREE.BoxGeometry, THREE.MeshLambertMaterial>;

    // Temporarily remove the grid helper and plane
    if (gridHelperForCanvas) this.scene.remove(gridHelperForCanvas);
    if (planeMesh) this.scene.remove(planeMesh);

    // Change the background color to white for the capture
    const originalBackground = this.scene.background;
    this.scene.background = new THREE.Color(ColorCodes.white);
    // Render the scene
    this.renderer.render(this.scene, this.camera);
    const imgData = this.renderer.domElement.toDataURL("image/jpeg");
    const filename = `image_${Date.now()}.jpeg`;
    const file = this.dataURLToFile(imgData, filename);

    // Restore the grid helper and plane
    if (gridHelperForCanvas) this.scene.add(gridHelperForCanvas);
    if (planeMesh) this.scene.add(planeMesh);
    this.scene.background = originalBackground;

    this.structureService.addImage(file);
  }

  private dataURLToFile(dataURL: string, filename: string): File {
    const [header, data] = dataURL.split(',');
    const mime = header.match(/:(.*?);/)![1];
    const binaryString = atob(data);
    const arrayBuffer = new ArrayBuffer(binaryString.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < binaryString.length; i++) {
      uint8Array[i] = binaryString.charCodeAt(i);
    }

    const blob = new Blob([uint8Array], { type: mime });
    return new File([blob], filename, { type: mime });
  }

  public autoCaptureImageToActionBtn(value: Boolean): void {
    if (value) {
      // Store the references to be restored later
      const gridHelperForCanvas = this.scene.children.find(
        child => child instanceof THREE.GridHelper
      ) as THREE.GridHelper;

      const planeMesh = this.plane as THREE.Mesh<THREE.BoxGeometry, THREE.MeshLambertMaterial>;

      // Temporarily remove the grid helper and plane
      if (gridHelperForCanvas) this.scene.remove(gridHelperForCanvas);
      if (planeMesh) this.scene.remove(planeMesh);

      // Save the current camera position and orientation
      const originalCameraPosition = this.camera.position.clone();
      const originalCameraRotation = this.camera.rotation.clone();

      this.camera.position.set(
        CanvasConfig.cameraPosition_X_Cooridinate,
        CanvasConfig.cameraPosition_Y_Cooridinate,
        CanvasConfig.cameraPosition_Z_Cooridinate + 10
      );
      this.camera.lookAt(new THREE.Vector3(0, 0, 0));
      this.camera.updateProjectionMatrix();
      const originalBackground = this.scene.background;
      this.scene.background = new THREE.Color(ColorCodes.white);
      this.renderer.render(this.scene, this.camera);
      const imgData = this.renderer.domElement.toDataURL("image/jpeg");
      const fileName = `image_${Date.now()}.jpeg`;
      const file = this.dataURLToFile(imgData, fileName);

      // Restore the grid helper and plane
      if (gridHelperForCanvas) this.scene.add(gridHelperForCanvas);
      if (planeMesh) this.scene.add(planeMesh);
      this.scene.background = originalBackground;

      // Restore the camera position and rotation
      this.camera.position.copy(originalCameraPosition);
      this.camera.rotation.copy(originalCameraRotation);
      this.camera.updateProjectionMatrix();

      this.imageDataToChild = file;
    }
  }

  private async convertAndSetImageData(file: File): Promise<void> {
    const reader = new FileReader();

    const dataURL: string = await new Promise((resolve, reject) => {
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });

    this.autoCapturedImage = dataURL;
  }

  ngOnDestroy() {
    this.unsubscribeToInteractiveEvents();
    if (this.generatedShapeSub) {
      this.generatedShapeSub.unsubscribe();
    }
    if (this.objectLengthSub) {
      this.objectLengthSub.unsubscribe();
    }
    if (this.clearStructureSub) {
      this.clearStructureSub.unsubscribe();
    }
    if (this.isEdittedSub) {
      this.isEdittedSub.unsubscribe();
    }
    if (this.structureImageSub) {
      this.structureImageSub.unsubscribe();
    }
    // Clean up the event listener
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  }
}
