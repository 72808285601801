import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { Router } from "@angular/router";
import { AuthService } from "../auth/auth.service";

@Injectable()
export class GenericInterceptor implements HttpInterceptor {
  constructor(private router: Router, private authService: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = localStorage.getItem("token");
    const adminToken = localStorage.getItem("adminToken");

    // Decide which token to use based on the URL pattern
    const isAdminRoute = request.url.includes("/admin");

    // Clone the request and set the appropriate headers
    const reqCopy = request.clone({
      setHeaders: {
        Authorization:
          isAdminRoute && adminToken
            ? `Bearer ${adminToken}`
            : token
            ? `Bearer ${token}`
            : "",
        "Content-Type": "application/json"
      }
    });

    return next.handle(reqCopy).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          if (isAdminRoute && adminToken) {
            this.authService.removeAdminToken();
            this.router.navigate(["/admin/login"]);
          } else {
            this.authService.removeUserToken();
            this.router.navigate(["/login"]);
          }
        }
        return throwError(error);
      })
    );
  }
}
