import { ValidatorFn, AbstractControl, ValidationErrors } from "@angular/forms";

/** A hero's name can't match the given regular expression */
export function nameValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const numbersRegex = /\d/; // Regular expression to detect numbers
    const specialCharacterRegex = /[!@#$%^&*(),.?":{}|<>]/;

    const containsNumbers = numbersRegex.test(control.value);
    const containsSpecialCharacters = specialCharacterRegex.test(control.value);

    return containsNumbers || containsSpecialCharacters
      ? { forbiddenName: { value: control.value } }
      : null;
  };
}
