<!-- Cards -->
<div class="mat-card-container" *ngIf="userStructureData.length">
  <div class="card-design" [ngClass]="{'black-background': userStructureData?.isCheckbox, 'default-background': !userStructureData?.isCheckbox}"
    *ngFor="let userStructureData of userStructureData">
    <div class="card-header">
      <div class="checkbox" *ngIf="showCheckboxes">
        <mat-checkbox [(ngModel)]="userStructureData.isCheckbox" (change)="bulkPublishOrDelete()"></mat-checkbox>
      </div>
      <div class="card-title-group">
        <div class="image-wrapper" (click)="goToMyStuctureDesign(userStructureData.id)">
          <ng-container *ngIf="userStructureData.imageurl; else noImageTemplate">
            <img class="structure-image" [src]="userStructureData.imageurl" alt="Structure Image">
          </ng-container>
          <ng-template #noImageTemplate>
            <img class="structure-image"
            >
          </ng-template>
          <div class="icon-wrapper">
            <mat-icon matTooltip="Actions" class="actions-icon" [matMenuTriggerFor]="actions"(click)="$event.stopPropagation()" >settings</mat-icon>
          </div>
        </div>
      </div>
    </div>
    <div class="card-content">
      <p class="structure-name">{{userStructureData.structureName}}</p>
      <p class="structure-type">{{userStructureData.createdAt | date:'dd MMM yy'}}</p>
      <p class="structure-type">{{userStructureData.status}}</p>
    </div>
    <!-- cogwheel Mat-menu -->
    <mat-menu #actions>
      <button mat-menu-item (click)="openPropertiesDialog(userStructureData)">Properties</button>
      <button mat-menu-item [disabled]="isDisabledPublishAndDelete(userStructureData.status)" (click)="onPublish(userStructureData.id, userStructureData.status)">Publish</button>
      <button mat-menu-item [disabled]="isDisabledPublishAndDelete(userStructureData.status)" (click)="onDelete(userStructureData.id, userStructureData.status)">Delete</button>
    </mat-menu>
  </div>
</div>

<!-- Pagination -->
<div class="view-more-container" *ngIf="showButton">
  <button class="customise-btn" mat-raised-button (click)="onViewMore()">
    View More
  </button>
</div>