<div fxLayout="row" fxLayoutAlign="start center">
    <h3 style="color: aliceblue;">Select Color</h3>
</div>
<div class="customiseColorPicker" fxLayout="row" fxLayoutAlign="space-around center">
    <div *ngFor="let inputConf of inputConfs">
        <input type="radio" name="color" [id]="inputConf.accessbility" [value]="inputConf.value"
           (click)="changeColor($event)"/>
        <label [for]="inputConf.accessbility" [style.background-color]="inputConf.value" (click)="changeColor(inputConf.value)"></label>
    </div>     
</div>

