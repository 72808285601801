export const CompassConfig = {
    cubeSize: 5,
    canvasWidth: 128,
    canvasHeight: 128,
    cubeColor: '#54534F',
    cubeFont: 'bold 28px Arial',
    textColor: '#FFFFFF'
}

/* ** DO NOT CHANGE ** */
// This is in the exact order so as to match the compass cube.
export const CompassLabels = [
  'RIGHT',
  'LEFT',
  'TOP',
  'BOTTOM',
  'FRONT',
  'BACK',
];
