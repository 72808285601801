import { Component, Input, OnChanges, ViewChild } from '@angular/core';

@Component({
  selector: "designer",
  templateUrl: "./designer.component.html",
  styleUrls: ["./designer.component.scss"]
})
export class DesignerComponent {
  @Input() isViewMode = false;
  
  constructor() { }
}
