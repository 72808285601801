import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { ResendOtp, SendOtp, SendOtpResponse, VerifyOtp } from "./login";

@Injectable({
  providedIn: "root"
})
export class LoginPageService {
  constructor(private http: HttpClient) {}

  sendOTP(body: SendOtp): Observable<SendOtpResponse> {
    return this.http.post(
      `${environment.apiUrlBasePath}/login/sendOtp`,
      body
    ) as Observable<SendOtpResponse>;
  }

  verifyOtp(body: VerifyOtp): Observable<string> {
    return this.http.post(
      `${environment.apiUrlBasePath}/login/verifyOtp`,
      body
    ) as Observable<string>;
  }

  resendOtp(body: ResendOtp): Observable<string> {
    return this.http.post(
      `${environment.apiUrlBasePath}/login/resendOtp`,
      body
    ) as Observable<string>;
  }
}
