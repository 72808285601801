<h4 mat-dialog-title class="heading1">Rating and Review</h4>
<mat-dialog-content>
  <form [formGroup]="ratingAndReview">
    <!-- rating  -->
    <div>
      <h2 class="heading">Rating</h2>
      <div class="cont">
        <div class="stars">
          <mat-icon
            *ngFor="let star of stars; let i = index"
            [class.selected]="i < ratingAndReview.get('rating')?.value"
            (click)="selectRating(i + 1)"
          >
            {{ i < ratingAndReview.get('rating')?.value ? 'star' : 'star_border' }}
          </mat-icon>
        </div>
      </div>
    </div>

    <!-- review  -->
    <div>
      <h2 class="heading">Review</h2>
      <textarea tabindex="-1" class="review" formControlName="review" rows="30" cols="50" [maxlength]="2000"></textarea>
    </div>
  </form>
  <!-- file upload  -->
  <label for="images">Images:</label>
  <div class="preview" *ngIf="images.imagesUrl.length > 0">
    <div *ngFor="let img of images.imagesUrl; let i = index" class="preview-image">
      <img [src]="img" [alt]="'Image ' + (i + 1)">
      <mat-icon class="remove-icon" (click)="removeImage(i)">delete</mat-icon>
    </div>
  </div>
  <div>
    <label for="images" class="custom-file-upload-image" [class.disabled]="images.imagesUrl.length >= 5">Choose
      Files</label>
    <input type="file" id="images" multiple accept="image/jpeg, image/png, image/webp, image/gif"
      (change)="onFileSelected($event)" class="file-input" [disabled]="images.imagesUrl.length >= 5" />
  </div>
  <div *ngIf="loading" class="spinner-overlay">
    <mat-spinner diameter="45"></mat-spinner>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close class="cancel-btn">Cancel</button>
  <button
    mat-button
    (click)="submitReview()"
    class="save-btn"
    [disabled]="!ratingAndReview.get('rating')?.value"
  >
    Submit
  </button>
</mat-dialog-actions>