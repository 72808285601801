import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { CountOutputData } from '@utils/count-calculator/count-calculator.model';

@Injectable({
  providedIn: 'root',
})
export class ComponentInteractionSrevice {
  private interceptInfo2 = new Subject<
    THREE.Intersection<THREE.Object3D<THREE.Event>>[]
  >();
  private interceptInfo = new Subject<
    THREE.Intersection<THREE.Object3D<THREE.Event>>[]
  >();
  private eventInfo = new Subject<THREE.Object3D>();
  private passSelectedMesh = new Subject<string>();
  private cameraRotation = new Subject<THREE.Vector3>();
  private objectInfo = new Subject<THREE.Object3D[]>();
  private selectedColor = new Subject<THREE.ColorRepresentation>();
  private outputPattern = new Subject<CountOutputData[]>();
  private redirectionPath = new Subject<string>();

  constructor() {}

  public getSeletedShape(): Observable<string> {
    return this.passSelectedMesh.asObservable();
  }

  public setSelectedShape(selectedShape: string) {
    this.passSelectedMesh.next(selectedShape);
  }

  public setGeneratedShapeInfo(event: THREE.Object3D): void {
    this.eventInfo.next(event);
  }

  public getGeneratedShapeInfo(): Observable<THREE.Object3D> {
    return this.eventInfo.asObservable();
  }

  public setInterceptInfo(
    interceptInfo: THREE.Intersection<THREE.Object3D<THREE.Event>>[]
  ): void {
    this.interceptInfo.next(interceptInfo);
  }

  public getInterceptInfo(): Observable<
    THREE.Intersection<THREE.Object3D<THREE.Event>>[]
  > {
    return this.interceptInfo.asObservable();
  }

  public getRotationObservable(): Observable<THREE.Vector3> {
    return this.cameraRotation.asObservable();
  }

  public updateRotation(rotation: THREE.Vector3) {
    this.cameraRotation.next(rotation);
  }

  public setObjectInfo(objects: THREE.Object3D[]): void {
    this.objectInfo.next(objects);
  }

  public getObjectInfo(): Observable<THREE.Object3D[]> {
    return this.objectInfo.asObservable();
  }

  public updateSelectedColor(color: THREE.ColorRepresentation) {
    this.selectedColor.next(color);
  }

  public getSelectedColor(): Observable<THREE.ColorRepresentation> {
    return this.selectedColor.asObservable();
  }

  public setOutputPattern(outputPattern: CountOutputData[]): void {
    this.outputPattern.next(outputPattern);
  }

  public getOutputPattern(): Observable<CountOutputData[]> {
    return this.outputPattern.asObservable();
  }
  public setInterceptInfo2(
    interceptInfo: THREE.Intersection<THREE.Object3D<THREE.Event>>[]
  ): void {
    this.interceptInfo2.next(interceptInfo);
  }

  public getInterceptInfo2(): Observable<
    THREE.Intersection<THREE.Object3D<THREE.Event>>[]
  > {
    return this.interceptInfo2.asObservable();
  }

  public setRedirectionPath(redirectionPath: string): void {
    this.redirectionPath.next(redirectionPath);
  }

  public getRedirectionPath(): Observable<string> {
    return this.redirectionPath.asObservable();
  }
}
