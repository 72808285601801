import { ShapeHelperService } from 'src/app/services/shape-helper/shape-helper.service';
import * as THREE from 'three';
import { ColorRepresentation } from 'three';
import { CanvasConfig } from '../shape-selector/shape-selector.model';
import { createShapeConfig } from './shape.config.model';
import { ShapeProperty } from './shape.model';

export const shapeProperty = (
  shapeType: string,
  color: ColorRepresentation,
  isForSelector: boolean
): ShapeProperty => {
  const shapeConfigs = createShapeConfig(color, isForSelector);

  const commonProperties = {
    material: isForSelector
      ? new THREE.MeshStandardMaterial({
          color: shapeConfigs[shapeType].color,
          transparent: true,
          opacity: CanvasConfig.transparentOpacity,
          side: THREE.DoubleSide // we do this only for selector
        })
      : new THREE.MeshBasicMaterial({ color: color as ColorRepresentation, side: THREE.DoubleSide }),
    offSetCoordinates: shapeConfigs[shapeType].offSetCoordinates,
  };

  if (
    shapeConfigs[shapeType].width !== undefined &&
    shapeConfigs[shapeType].height !== undefined &&
    shapeConfigs[shapeType].depth !== undefined
  ) {
    return {
      geometry: new THREE.BoxGeometry(
        shapeConfigs[shapeType].width,
        shapeConfigs[shapeType].height,
        shapeConfigs[shapeType].depth
      ),
      ...commonProperties,
    };
  } else if (shapeConfigs[shapeType].extrudeSetting !== undefined) {
    let geometry = new THREE.ExtrudeGeometry(
      getCustomShape(shapeConfigs[shapeType]),
      shapeConfigs[shapeType].extrudeSetting
    );
    geometry = ShapeHelperService.calculateCentroid(geometry); // Recalculate the centroid.
    return {
      geometry,
      ...commonProperties,
    };
  } else if (
    shapeConfigs[shapeType].radius !== undefined &&
    shapeConfigs[shapeType].height !== undefined &&
    shapeConfigs[shapeType].radialSegments !== undefined &&
    shapeConfigs[shapeType].heightSegments !== undefined
  ) {
    return {
      geometry: new THREE.ConeGeometry(
        shapeConfigs[shapeType].radius,
        shapeConfigs[shapeType].height,
        shapeConfigs[shapeType].radialSegments,
        shapeConfigs[shapeType].heightSegments
      ),
      ...commonProperties,
    };
  } else if (
    shapeConfigs[shapeType].radiusTop !== undefined &&
    shapeConfigs[shapeType].radiusBottom !== undefined &&
    shapeConfigs[shapeType].height !== undefined &&
    shapeConfigs[shapeType].radialSegments !== undefined &&
    shapeConfigs[shapeType].heightSegments !== undefined
  ) {
    return {
      geometry: new THREE.CylinderGeometry(
        shapeConfigs[shapeType].radiusTop,
        shapeConfigs[shapeType].radiusBottom,
        shapeConfigs[shapeType].height,
        shapeConfigs[shapeType].radialSegments,
        shapeConfigs[shapeType].heightSegments 
      ),
      ...commonProperties,
    };
  } else if (
    shapeConfigs[shapeType].radius !== undefined &&
    shapeConfigs[shapeType].widthSegments !== undefined &&
    shapeConfigs[shapeType].heightSegments !== undefined &&
    shapeConfigs[shapeType].phiStart !== undefined &&
    shapeConfigs[shapeType].phiEnd !== undefined &&
    shapeConfigs[shapeType].thetaStart !== undefined &&
    shapeConfigs[shapeType].thetaEnd !== undefined &&
    shapeConfigs[shapeType].capRadius !== undefined &&
    shapeConfigs[shapeType].capRadiusSegments !== undefined
  ) {
    return {
      geometry : new THREE.SphereGeometry(
        shapeConfigs[shapeType].radius,
        shapeConfigs[shapeType].widthSegments,
        shapeConfigs[shapeType].heightSegments,
        shapeConfigs[shapeType].phiStart,
        shapeConfigs[shapeType].phiEnd,
        shapeConfigs[shapeType].thetaStart,
        shapeConfigs[shapeType].thetaEnd
      ),
      ...commonProperties,
      capgeometry : new THREE.CircleGeometry(
        shapeConfigs[shapeType].capRadius,
        shapeConfigs[shapeType].capRadiusSegments
      ),
      ...commonProperties
    }
  }else {
    throw new Error(
      `Invalid shape configuration: ${JSON.stringify(shapeConfigs[shapeType])}`
    );
  }
};

/*
    For any addition in the custom shape, we must add the config and then modify here.
*/
const getCustomShape = (shapeConfig: any): THREE.Shape => {
  const shape = new THREE.Shape();
  if (shapeConfig.firstLine && shapeConfig.lastLine) {
    shape.lineTo(shapeConfig?.firstLine.x, shapeConfig?.firstLine.y);
    shape.absarc(
      shapeConfig?.arc.x,
      shapeConfig?.arc.y,
      shapeConfig?.arc.radius,
      shapeConfig?.arc.startAngle,
      shapeConfig?.arc.endAngle,
      shapeConfig?.arc.isClockwise
    );
    shape.lineTo(shapeConfig.lastLine.x, shapeConfig.lastLine.x);
  } else if (shapeConfig.arc) {
    shape.absarc(
      shapeConfig?.arc.x,
      shapeConfig?.arc.y,
      shapeConfig?.arc.radius,
      shapeConfig?.arc.startAngle,
      shapeConfig?.arc.endAngle,
      shapeConfig?.arc.isClockwise
    );
  } else if (
    (shapeConfig.moveTo && shapeConfig.firstLine && shapeConfig.secondLine) ||
    shapeConfig.thirdLine
  ) {
    shape.moveTo(shapeConfig.moveTo.x, shapeConfig.moveTo.y);
    shape.lineTo(shapeConfig?.firstLine.x, shapeConfig?.firstLine.y);
    shape.lineTo(shapeConfig?.secondLine.x, shapeConfig?.secondLine.y);
    shape.lineTo(shapeConfig?.thirdLine?.x, shapeConfig?.thirdLine?.y);
    shape.lineTo(shapeConfig?.fourthLine?.x, shapeConfig?.fourthLine?.y);
  }

  return shape;
};
