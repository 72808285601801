import { Component, ViewChild } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { AddressComponent } from "./address/address.component";
import { ProfileDetailsComponent } from "./profile-details/profile-details.component";
import {
  AddressDetailsAttributes,
  AddressDetailsCreationAttributes,
  UserDetailsAttributes
} from "@models/user.model";
import { UserService } from "../services/user/user.service";

@Component({
  selector: "app-user-details",
  templateUrl: "./user-details.component.html",
  styleUrls: ["./user-details.component.scss"]
})
export class UserDetailsComponent {
  @ViewChild(ProfileDetailsComponent) profileDetailRef:
    | ProfileDetailsComponent
    | undefined;
  @ViewChild(AddressComponent) addressDetailRef: AddressComponent | undefined;

  public location!: AddressDetailsAttributes;
  public profile!: UserDetailsAttributes;
  public address!: AddressDetailsCreationAttributes;

  constructor(
    private userService: UserService,
    private snackBar: MatSnackBar,
    private router: Router
  ) {}

  public submitForm(): void {
    this.userService
      .addUserDetails({
        userDetails: this.profile,
        addressDetails: this.address
      })
      .subscribe(response => {
        if (response) {
          this.openSnackBar("Added successfully", "Close");
          this.back();
        }
      });
  }

  back() {
    this.router.navigate(["/home"]);
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, { duration: 2000 });
  }

  public receiveLocationData(location: AddressDetailsAttributes): void {
    this.location = location;
  }

  public receiveProfileData(profile: UserDetailsAttributes): void {
    this.profile = profile;
  }

  public receiveAddressData(address: AddressDetailsCreationAttributes): void {
    this.address = address;
  }

  public isFormDataValid(): boolean {
    return !!(
      this.profileDetailRef?.profileForm.valid &&
      this.addressDetailRef?.addressForm.valid
    );
  }
}
