import { Injectable } from '@angular/core';
import * as THREE from 'three';
import { BufferAttribute } from 'three';

@Injectable({
  providedIn: 'root',
})
export class ShapeHelperService {
  /*
    This method iterates through the vertex positions using the positionAttribute
    and calculates the average position to determine the centroid.
    Then, it adjusts the geometry by translating it to set the centroid as the origin
  */
  public static calculateCentroid(
    geometry: THREE.ExtrudeGeometry
  ): THREE.ExtrudeGeometry {
    const positionAttribute = geometry.getAttribute('position');
    const centroid = new THREE.Vector3();

    for (let i = 0; i < positionAttribute.count; i++) {
      const vertex = new THREE.Vector3();
      vertex.fromBufferAttribute(positionAttribute as BufferAttribute, i);
      centroid.add(vertex);
    }

    centroid.divideScalar(positionAttribute.count);

    geometry.translate(-centroid.x, -centroid.y, -centroid.z);
    return geometry;
  }
}
