import { Component, OnInit } from "@angular/core";
import { ProductService } from "src/app/services/product/product.service";
import { productCategories, ProductDetails } from "@models/product.model";
import { AuthService } from "../../services/auth/auth.service";
import { environment } from "src/environments/environment";
import { CartItemService } from "../../services/cart-item/cart-item.service";
import { AdvertisementService } from "../../services/advertisement/advertisement.service";
import { AdvertisementInstance } from "@models/advertisement.model";
import { WishlistService } from "../../services/wishlistService/wishlist.service";

@Component({
  selector: "home-page",
  templateUrl: "./home-page.component.html",
  styleUrls: ["./home-page.component.scss"]
})
export class HomePageComponent implements OnInit {
  public productList: ProductDetails[] = [];
  public searchResults: ProductDetails[] = [];
  public isLoading = false;
  public pageIndex = 0;
  public offerPageIndex = 0;
  public mostSellingPageIndex = 0;
  public searchPageIndex = 0;
  public initialPageSize = 10; // must be a multiple of pageSize
  public pageSize = 5;
  public searchProductName!:string;
  public searchInitiated: boolean = false;
  private debounceTimer!: ReturnType<typeof setTimeout>;
  public productCategories = productCategories;
  public selectedCategory!:string;
  public totalSearchResults = 0;
  public totalProducts = 0;
  public searchInput!:string;
  public banners: AdvertisementInstance[] = []

  constructor(
    private productService: ProductService,
    private authService: AuthService,
    private cartService: CartItemService,
    private advertisementService: AdvertisementService,
    private wishlistService: WishlistService
  ) { }

  ngOnInit(): void {
    this.selectedCategory = this.productService.getSelectedCategory();
    this.advertisementService.getAllActiveBanners().subscribe({
      next: (response) => {
        this.banners = response;
      }
    })

    this.productService.productName$.subscribe(data => {
      this.searchProductName = data;
      this.searchResults = [];
      this.searchPageIndex = 0;
      this.searchInitiated = false; // Reset before new search

      if (this.searchProductName && this.searchProductName.trim().length > 0) {
        this.searchInitiated = true; // Set to true to show results section
        this.loadSearchProducts(this.initialPageSize);
      }
    });

    this.loadProducts(this.initialPageSize);
    this.pageIndex = Math.max(
      0,
      Math.floor(this.initialPageSize / this.pageSize) - 1
    );
  }

  ngAfterViewInit() {
    const input = document.getElementById('input') as HTMLInputElement;
    const placeholderText: string = 'Search your product...';
    if (input) {
      this.productService.animatePlaceholder(input, placeholderText);
    }
  }

  onSearchClick(inputValue: string): void {
    this.productService.setProductName(inputValue);
    window.scrollTo({
      top: 0,
      behavior:'smooth'
    })
  }

  onSearchInputChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    clearTimeout(this.debounceTimer);

    // If search input is not empty, initiate search with debounce
    if (input.value.trim() !== '') {
      this.debounceTimer = setTimeout(() => {
        this.productService.setProductName(input.value);
      }, 3000);
    } else {
      // If search input is empty, clear search results and fetch default products
      this.debounceTimer = setTimeout(() => {
        this.productService.setProductName(input.value);
      }, 300);
      window.scrollTo({
        top: 0,
        behavior:'smooth'
      })
    }
  }

  public loadProducts(
    pageSize: number = environment.dynamicPagination.pageSize
  ): void {
    this.isLoading = true;
    this.productService.getAllActiveProducts(this.pageIndex, pageSize, undefined,  this.selectedCategory).subscribe({
      next: response => {
        this.isLoading = false;
        if (response && response.rows && response.rows.length > 0) {
          this.productList.push(...response.rows);
          this.totalProducts = response.count;
        }
      },
      error: () => {
        this.isLoading = false;
      }
    });
  }

  public loadSearchProducts(
    pageSize: number = environment.dynamicPagination.pageSize
  ): void {
    this.isLoading = true;
    this.productService.getAllActiveProducts(this.searchPageIndex, pageSize, this.searchProductName).subscribe({
      next: response => {
        this.isLoading = false;
        if (response && response.rows && response.rows.length) {
          this.searchResults.push(...response.rows);
          this.totalSearchResults = response.count;
        }
      },
      error: () => {
        this.isLoading = false;
      }
    });
  }

  public onViewMoreClick(): void {
    this.pageIndex++;
    this.loadProducts(this.pageSize);
  }

  public onSearchViewMoreClick(): void {
    this.searchPageIndex++;
    this.loadSearchProducts(this.pageSize);
  }

  public onClickedCategory(category: string): void {
    this.selectedCategory = category;
    this.searchInitiated = false;
    this.productService.triggerReset(true);
    this.productList = [];
    this.pageIndex = 0;
    this.loadProducts(this.initialPageSize);
    this.productService.setSelectedCategory(this.selectedCategory);
  }
}
