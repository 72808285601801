
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import * as THREE from 'three';
import { ColorCodes } from "@utils/color-constants";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { HalfScreenCanvasConfig, ZoomConfig } from "@utils/canvas-configuration";
import { InteractionService } from '../shared/helpers/interaction.service';
import { ComponentInteractionSrevice } from '../services/component-interaction/component-interaction.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AvailableShapes } from '@utils/shape-facetype';
import { ShapeGeneratorService } from '../services/shape-generator/shape-generator.service';
import { OutlineService } from '../services/shape-helper/outline.service';
import { LoadStructureService } from '../load-structure.service';
import { ShapeJson } from '../shared/shape-selector/shape-selector.model';

@Component({
  selector: 'app-block-details-component',
  templateUrl: './block-details-component.component.html',
  styleUrls: ['./block-details-component.component.scss']
})
export class BlockDetailsComponentComponent implements AfterViewInit, OnChanges {
  @ViewChild("rendererContainer", { static: false })
  rendererContainer: ElementRef | any;

  private renderer = new THREE.WebGLRenderer({ alpha: true });
  private scene: THREE.Scene;
  private camera: THREE.PerspectiveCamera;
  private orbitControl!: OrbitControls;
  private objects: THREE.Object3D[] = [];
  public zoomConfig = ZoomConfig;
  private shape: THREE.Mesh | null = null;
  private availableShapes = AvailableShapes;
  private currentShape!: string;
  @Input() shapeJson!: ShapeJson;
  @Input() selectedColor!: THREE.ColorRepresentation | string;

  constructor(private ngZone: NgZone, private interactionService: InteractionService, private cdr: ChangeDetectorRef, private componentInteractionSrv: ComponentInteractionSrevice, private snackBar: MatSnackBar, private shapeGeneratorService: ShapeGeneratorService, private outlineService: OutlineService, private loadStructureService: LoadStructureService) {
    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color(ColorCodes.sceneBackground);
    const aspectRatio = HalfScreenCanvasConfig.perspectiveCameraAspectRatio;
    this.camera = new THREE.PerspectiveCamera(
      HalfScreenCanvasConfig.perspectiveCameraFov,
      aspectRatio,
      HalfScreenCanvasConfig.perspectiveCameraNearField,
      HalfScreenCanvasConfig.perspectiveCameraFarField
    );
    this.orbitControl = new OrbitControls(this.camera, this.renderer.domElement);
    this.orbitControl.update();
  }

  ngOnInit(): void {
    if (this.shapeJson) {
      this.currentShape = this.shapeJson.object.name;
    }
    this.interactionService.subscribeToEvents(
      this.renderer,
      this.camera,
      this.scene,
      this.objects
    );
    this.startAnimationLoop();
    this.cdr.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.createShape();
  }

  ngAfterViewInit(): void {
    if (this.rendererContainer) {
      this.rendererConfiguration();
    }
    this.cameraConfiguration();
    this.controlsConfiguration();
    this.createShape();
    this.startAnimationLoop();
  }

  private rendererConfiguration(): void {
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.rendererContainer.nativeElement.appendChild(this.renderer.domElement);
  }

  private cameraConfiguration(): void {
    this.camera.position.set(
      HalfScreenCanvasConfig.cameraPosition_X_Cooridinate,
      HalfScreenCanvasConfig.cameraPosition_Y_Cooridinate,
      HalfScreenCanvasConfig.cameraPosition_Z_Cooridinate
    );
    this.camera.add(
      new THREE.PointLight(
        ColorCodes.pointLight,
        HalfScreenCanvasConfig.pointLightIntensity
      )
    );
    this.scene.add(
      new THREE.DirectionalLight(
        ColorCodes.directionalLight,
        HalfScreenCanvasConfig.directionalLightIntensity
      )
    );
  }

  private controlsConfiguration(): void {
    const orbitControl = this.orbitControl;

    const {
      orbitControlMaxDistance,
      orbitControlTarget_X_Coordinate,
      orbitControlTarget_Y_Coordinate,
      orbitControlTarget_Z_Coordinate,
      oribitControlPanSpeed,
      orbitControlDynamicDampingFactor,
      orbitControlMaxPolarAngleDivisor
    } = HalfScreenCanvasConfig;

    Object.assign(orbitControl, {
      maxDistance: orbitControlMaxDistance,
      target: new THREE.Vector3(
        orbitControlTarget_X_Coordinate,
        orbitControlTarget_Y_Coordinate,
        orbitControlTarget_Z_Coordinate
      ),
      panSpeed: oribitControlPanSpeed,
      enableDamping: true,
      dynamicDampingFactor: orbitControlDynamicDampingFactor
    });

    orbitControl.enableZoom = false;
  }

  // creating shape
  private createShape(): void {
    if (this.shapeJson) {
      this.shape = this.loadStructureService.createMesh(this.shapeJson, true, this.selectedColor, true) ?? null;
      if (this.shape) {
        this.scene.add(this.shape);
        this.outlineService.addOutline(this.shape, this.currentShape);

        // handling for transparent cube
        if ([ this.availableShapes.CurvedCube, this.availableShapes.HalfCylinder, this.availableShapes.Cylinder, this.availableShapes.Cube, this.availableShapes.SquarePyramid, this.availableShapes.Prism, this.availableShapes.Cone, this.availableShapes.HemiSphere ].includes(this.currentShape)
          ) {
          const transparentMesh = this.loadStructureService.createMesh(this.shapeJson.object.userData['faceMesh'], false, "", true);
          if (transparentMesh) {
            this.scene.add(transparentMesh);
          }
        }
      }
    }
  }

  private startAnimationLoop(): void {
    this.ngZone.runOutsideAngular(() => {
      this.animate();
    });
  }

  private animate(): void {
    window.requestAnimationFrame(() => this.animate());
    this.interactionService.render();
    this.orbitControl.update();
  }
}
