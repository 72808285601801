import { Component, Input, OnChanges, ViewEncapsulation, OnDestroy } from '@angular/core';
import { AdvertisementInstance } from "@models/advertisement.model";

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BannerComponent implements OnChanges, OnDestroy {
  @Input() images: AdvertisementInstance[] = [];
  public currentIndex = 0;
  private interval: ReturnType<typeof setTimeout> | null = null;
  private isHovered = false;
  private delay = 5000 //ms

  constructor() {}

  ngOnChanges(): void {
    this.stopCarousel(); // Stop any existing interval
    setTimeout(() => {
      this.startCarousel();
    }, 0);
  }

  ngOnDestroy() {
    this.stopCarousel();
  }

  private startCarousel(): void {
    this.interval = setInterval(() => {
      if (!this.isHovered) {
        this.showNextImage();
      }
    }, this.delay); // Change image every 5 seconds
  }

  private stopCarousel(): void {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  public showPrevImage(): void {
    this.currentIndex = (this.currentIndex - 1 + this.images.length) % this.images.length;
  }

  public showNextImage(): void {
    this.currentIndex = (this.currentIndex + 1) % this.images.length;
  }

  public  goToImage(index: number): void {
    const stepDelay = 200; // 200ms delay between steps

    const changeIndex = () => {
      if (index < this.currentIndex) {
        this.currentIndex--;
      } else if (index > this.currentIndex) {
        this.currentIndex++;
      }
      if (index !== this.currentIndex) {
        setTimeout(changeIndex, stepDelay);
      }
    };

    changeIndex();
  }

  public onMouseEnter(): void {
    this.isHovered = true;
    this.stopCarousel();
  }

  public onMouseLeave(): void {
    this.isHovered = false;
    this.startCarousel();
  }
}
