/*
    All the properties of any shape must be listed here, most of them are common.
*/

import { ColorRepresentation } from 'three';

export interface ShapeProperty {
  geometry?:
    | THREE.BoxGeometry
    | THREE.CylinderGeometry
    | THREE.BufferGeometry
    | THREE.ExtrudeGeometry
    | THREE.EdgesGeometry
    | THREE.ConeGeometry
    | THREE.SphereGeometry; // New Geometries can be added here if any
  capgeometry?: THREE.CircleGeometry; // New Geometries can be added here if any
  material: THREE.Material;
  offSetCoordinates?: THREE.Vector3;
  extrudeSetting?: THREE.ExtrudeGeometryOptions;
}

// config model for all the shapes
export interface ShapeConfig {
  [shapeType: string]: {
    width?: number;
    height?: number;
    depth?: number;
    radius?: number;
    radiusTop?: number;
    radiusBottom?: number;
    radialSegments?: number;
    heightSegments?: number;
    widthSegments?: number;
    openEnded?: boolean;
    thetaStart?: number;
    thetaLength?: number;
    offSetCoordinates?: any;
    color: ColorRepresentation;
    moveTo?: { x: number; y: number };
    firstLine?: { x: number; y: number };
    secondLine?: { x: number; y: number };
    thirdLine?: { x: number; y: number };
    fourthLine?: { x: number; y: number };
    geometry?: any;
    thresholdAngle?: number;
    phiStart?: number;
    phiEnd?: number;
    thetaEnd?: number;
    capRadius?: number;
    capRadiusSegments?: number;
    arc?: {
      x?: number;
      y?: number;
      radius?: number;
      startAngle?: number;
      endAngle?: number;
      isClockwise?: boolean;
    };
    lastLine?: { x: number; y: number };
    extrudeSetting?: {
      depth?: number;
      bevelEnabled?: boolean;
      bevelSegments?: number;
      steps?: number;
      bevelSize?: number;
      bevelThickness?: number;
      amount?: number;
    };
  };
}

export const Colors = [
  {class: "dark-green-color", value: "#117A65"},
  {class: "green-color", value: "#21BA45"},
  {class: "skyBlue-color", value: "#5DADE2"},
  {class: "violet-color", value: "#3633BF"},
  {class: "orange-color", value: "#CF871B"},
  {class: "teal-color", value: "#00B5AD"},
  {class: "lightblue-color", value: "#2185D0"},
  {class: "darkblue-color", value: "#3C79B7"}
]