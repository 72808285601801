import { Injectable } from "@angular/core";
import * as THREE from "three";
import { ShapeGeneratorService } from "../services/shape-generator/shape-generator.service";
import { AvailableShapes } from "src/utils/shape-facetype";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class GenerateNewShapes {
  private isNewShapeGeneratedSubject = new Subject<boolean>();
  public isNewShapeGenerated$ = this.isNewShapeGeneratedSubject.asObservable();
  
  constructor(private shapeGeneratorService: ShapeGeneratorService) {}

  private markAsGenerated(): void {
    this.isNewShapeGeneratedSubject.next(true);
  }

  public generateNewShapes(
    position: THREE.Vector3,
    shape: string,
    color: THREE.Color
  ): THREE.Mesh {
    let object: THREE.Mesh = new THREE.Mesh();
    switch (shape) {
      case AvailableShapes.Cube: // IF USER SELECTED SHAPE IS CUBE
        object = this.shapeGeneratorService.generateShape(
          AvailableShapes.Cube,
          color,
          false,
          position
        );
        object.name = AvailableShapes.Cube;
        this.setShapePosition(position.x, position.y, position.z, object);
        break;
      case AvailableShapes.CurvedCube: // IF USER SELECTED SHAPE IS CURVED CUBE
        object = this.shapeGeneratorService.generateShape(
          AvailableShapes.CurvedCube,
          color,
          false,
          position
        );
        object.name = AvailableShapes.CurvedCube;
        this.setShapePosition(position.x, position.y, position.z, object);
        break;
      case AvailableShapes.CurvedCube2: // IF USER SELECTED SHAPE IS CURVED FOR TWO SIDES
        object = this.shapeGeneratorService.generateShape(
          AvailableShapes.CurvedCube2,
          color,
          false,
          position
        );
        object.name = AvailableShapes.CurvedCube2;
        this.setShapePosition(position.x, position.y, position.z, object);
        break;
      case AvailableShapes.HalfCylinder: // IF USER SELECTED SHAPE IS HALF CYLINDER
        object = this.shapeGeneratorService.generateShape(
          AvailableShapes.HalfCylinder,
          color,
          false,
          position
        );
        object.name = AvailableShapes.HalfCylinder;
        this.setShapePosition(position.x, position.y, position.z, object);
        break;
      case AvailableShapes.Prism: // IF USER SELECTED SHAPE IS PRISM
        object = this.shapeGeneratorService.generateShape(
          AvailableShapes.Prism,
          color,
          false,
          position
        );
        object.name = AvailableShapes.Prism;
        this.setShapePosition(position.x, position.y, position.z, object);
        break;
      case AvailableShapes.SquarePyramid: // IF USER SELECTED SHAPE IS SYMMITRICAL PYRAMID
        object = this.shapeGeneratorService.generateShape(
          AvailableShapes.SquarePyramid,
          color,
          false,
          position
        );
        object.name = AvailableShapes.SquarePyramid;
        object.rotateY(14.925);
        this.setShapePosition(position.x, position.y, position.z, object);
        break;
      case AvailableShapes.Cone: // IF USER SELECTED SHAPE IS CONE
        object = this.shapeGeneratorService.generateShape(
          AvailableShapes.Cone,
          color,
          false,
          position
        );
        object.name = AvailableShapes.Cone;
        this.setShapePosition(position.x, position.y, position.z, object);
        break;
      case AvailableShapes.Cylinder: // IF USER SELECTED SHAPE IS CYLINDER
        object = this.shapeGeneratorService.generateShape(
          AvailableShapes.Cylinder,
          color,
          false,
          position
        );
        object.name = AvailableShapes.Cylinder;
        this.setShapePosition(position.x, position.y, position.z, object);
        break;
      case AvailableShapes.HemiSphere: // IF USER SELECTED SHAPE IS HEMISPHERE
        object = this.shapeGeneratorService.generateShape(
          AvailableShapes.HemiSphere,
          color,
          false,
          position
        );
        object.name = AvailableShapes.HemiSphere;
        object.rotateX(-Math.PI * 0.5);
        position.y = position.y - 10;
        this.setShapePosition(position.x, position.y, position.z, object);
    }
    object.name = shape;
    this.markAsGenerated();
    return object;
  }

  // THIS METHOD IS SETTING POSITION/CO-ORDINATES FOR GENERATED MESH / SHAPE
  private setShapePosition(
    axisX: any,
    axisY: any,
    axisZ: any,
    object: THREE.Mesh
  ): void {
    object.position.copy(new THREE.Vector3(axisX, axisY, axisZ));
  }
}
