import { Injectable } from '@angular/core';
import { ColorCodes } from '@utils/color-constants';
import { AlteredShapeConfiguration } from 'src/utils/shape';
import { AvailableShapes } from '@utils/shape-facetype';
import * as THREE from 'three';
import { ColorRepresentation } from 'three';

@Injectable({
  providedIn: 'root',
})
export class OutlineService {
  constructor() { }

  /*
    this method adds the outline for the shapes,
    only for cube we have box helper, for other shape we use edgesGeometry
  */
  public addOutline(mesh: THREE.Mesh, type: string, position?: THREE.Vector3) {
    if (type == AvailableShapes.Cube) {
      const outlineContainer = new THREE.BoxHelper(
        mesh,
        ColorCodes.yellow as ColorRepresentation
      );
      outlineContainer.name = AvailableShapes.Outline
      if (position) {
        outlineContainer.position.setX(position.x);
        outlineContainer.position.setY(position.y);
        outlineContainer.position.setZ(position.z);
      }
      mesh.add(outlineContainer);
    } else {
      const outlineMaterial = new THREE.MeshBasicMaterial({
        color: ColorCodes.yellow as ColorRepresentation,
      });

      const outlineGeometry = new THREE.EdgesGeometry(mesh.geometry, AlteredShapeConfiguration.standardThreshHoldAngle);
      const outline = new THREE.LineSegments(
        outlineGeometry,
        outlineMaterial
      );
      outline.name = AvailableShapes.Outline
      mesh.add(outline);
    }
  }
}
