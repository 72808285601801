export enum CanvasConfig {
    perspectiveCameraFov = 75,
    perspectiveCameraAspectRatio = 1,
    perspectiveCameraNearField = 0.1,
    perspectiveCameraFarField = 1000,
    cameraPosition_X_Cooridinate = 200,
    cameraPosition_Y_Cooridinate = 200,
    cameraPosition_Z_Cooridinate = 200,
    pointLightIntensity = 0.7,
    directionalLightIntensity = 0.8,
    orbitControlMaxDistance = 650,
    orbitControlTarget_X_Coordinate = 0,
    orbitControlTarget_Y_Coordinate = 0,
    orbitControlTarget_Z_Coordinate = 0,
    oribitControlRotateSpeed = 1.0,
    oribitControlPanSpeed = 0.8,
    oribitControlZoomSpeed = 1.2,
    orbitControlMaxPolarAngleDivisor = 2,
    orbitControlDynamicDampingFactor = 0.1,
    gridHelperSize = 500,
    gridHelperDivisions = 25,
    canvasWidth = 500,
    canvasHeight = 10,
    canvasDepth = 500,
    canavasPostionIn_Y_Coordinate = -100,
}

export enum ZoomConfig {
  maximumAllowedZoom = 4,
  minimumAllowedZoom = 0.5,
  zoomIncrement = 0.2,
  maxZoomLimitMessage = 'You cannot zoom further',
  closeMessage = 'Close',
  zoomIn = 'in',
  zoomOut = 'out'
}

export enum HalfScreenCanvasConfig {
  perspectiveCameraFov = 65,
  perspectiveCameraAspectRatio = 1.5 / 1, // Adjusted for half screen
  perspectiveCameraNearField = 0.1,
  perspectiveCameraFarField = 1000,
  cameraPosition_X_Cooridinate = 10, // Adjusted for half screen
  cameraPosition_Y_Cooridinate = 10, // Adjusted for half screen
  cameraPosition_Z_Cooridinate = 10, // Adjusted for half screen
  pointLightIntensity = 0.7,
  directionalLightIntensity = 0.8,
  orbitControlMaxDistance = 325, // Adjusted for half screen
  orbitControlTarget_X_Coordinate = 0,
  orbitControlTarget_Y_Coordinate = 0,
  orbitControlTarget_Z_Coordinate = 0,
  oribitControlRotateSpeed = 1.0,
  oribitControlPanSpeed = 0.8,
  oribitControlZoomSpeed = 1.2,
  orbitControlMaxPolarAngleDivisor = 2,
  orbitControlDynamicDampingFactor = 0.1,
  gridHelperSize = 100, // Adjusted for half screen
  gridHelperDivisions = 10, // Adjusted for half screen
  canvasWidth = 100, // Adjusted for half screen
  canvasHeight = 5, // Adjusted for half screen
  canvasDepth = 10, // Adjusted for half screen
  canavasPostionIn_Y_Coordinate = -10, // Adjusted for half screen
}
