import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DesignerComponent } from "./designer/designer.component";
import { PageNotFoundComponent } from "./shared/page-not-found/page-not-found.component";
import { LoginPageComponent } from "./login-page/login-page.component";
import { TrackOrderComponent } from "./shared/track-order/track-order.component";
import { WishlistPageComponent } from "./shared/wishlist-page/wishlist-page.component";
import { CheckoutPageComponent } from "./dashboard/checkout-page/checkout-page.component";
import { OrderSuccessComponent } from "./order-success/order-success.component";
import { HomePageComponent } from "./dashboard/home-page/home-page.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { CartPageComponent } from "./dashboard/cart-page/cart-page.component";
import { ProductDetailPageComponent } from "./product-detail-page/product-detail-page.component";
import { UserDetailsComponent } from "./user-details/user-details.component";
import { ProfileComponent } from "./profile/profile.component";
import { PaymentComponent } from "./payment/payment.component";
import { OrderDetailsComponent } from "./order-details/order-details.component";
import { PrivacyPageComponent } from "./shared/footer/privacy-page/privacy-page.component";
import { TermConditionComponent } from "./shared/footer/term-condition/term-condition.component";
import { ContactComponent } from "./contact/contact.component";
import { AuthService } from "./services/auth/auth.service";
import { AdminOrderPageComponent } from "./admin/admin-order-page/admin-order-page.component";
import { NoAccessComponent } from "./admin/no-access/no-access.component";
import { MyStructuresComponent } from "./my-structures/my-structures.component";
import { ProductImageComponent } from './product-image/product-image-gallery.component';
import { UserNotificationComponent } from "./user-notification/user-notification.component";

const routes: Routes = [
  {
    path: "admin",
    loadChildren: () => import("./admin/admin.module").then(m => m.AdminModule)
  },
  {
    path: "designer",
    component: DesignerComponent,
    canActivate: [AuthService],
    data: { noHeader: true , noBackButton: true }
  },
  {
    path:"designer/:id",
    component:DesignerComponent,
    canActivate: [AuthService],
  },
  {
    path: "products/:id",
    component: ProductDetailPageComponent
  },
  {
    path: "dashboard",
    component: DashboardComponent
  },
  {
    path: "home",
    component: HomePageComponent,
    data: { noBackButton: true }
  },
  {
    path: "userDetails",
    component: UserDetailsComponent,
    data: { noHeader: true, noBackButton: true }
  },
  {
    path: "profile",
    component: ProfileComponent,
    canActivate: [AuthService]
  },
  {
    path: "login",
    component: LoginPageComponent,
    data: { noBackButton: true }
  },
  {
    path: "wishlist",
    component: WishlistPageComponent,
    canActivate: [AuthService]
  },
  {
    path: "trackOrder",
    component: TrackOrderComponent,
    canActivate: [AuthService]
  },
  {
    path: "privacy",
    component: PrivacyPageComponent
  },
  {
    path: "termCondition",
    component: TermConditionComponent
  },
  {
    path: "cart",
    component: CartPageComponent
  },
  {
    path: "checkout",
    component: CheckoutPageComponent
  },
  {
    path: "payment",
    component: PaymentComponent,
    data: { noHeader: true }
  },
  {
    path: "order/success",
    component: OrderSuccessComponent
  },
  {
    path: "orderDetails/:id",
    component: OrderDetailsComponent
  },
  {
    path: "order/success/:merchantTransactionId",
    component: OrderSuccessComponent
  },
  {
    path: "adminOrderStatusHistory/:id",
    component: AdminOrderPageComponent
  },
  {
    path: "noAccess",
    component: NoAccessComponent
  },
  {
    path: "contact",
    component: ContactComponent
  },
  {
    path: "",
    redirectTo: "/home",
    pathMatch: "full"
  },
  {
    path: "myStructures",
    component: MyStructuresComponent,
    canActivate: [AuthService]
  },
  {
    path: "notifications",
    component: UserNotificationComponent,
    canActivate: [AuthService]
  },
  {
    path: "**",
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
