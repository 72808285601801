import { HttpClient } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { Observable, map, of } from "rxjs";
import { environment } from "../../../environments/environment";
import { AuthService } from "../auth/auth.service";
import { CartItem, ResponseMessage } from "@models/cart.model";
import { ProductDetails } from "@models/product.model";
import { LocalStorageService } from "../local-storage/local-storage.service";

@Injectable({
  providedIn: "root"
})
export class CartItemService {

  public cartItemDeleted = new EventEmitter<number>();

  constructor(private http: HttpClient, private authService: AuthService, private localStorageService: LocalStorageService) {
  }

  public addToCart(item: CartItem): Observable<string> {
    this.localStorageService.addItemToCart(item);

    if (this.authService.isUserLoggedIn) {
      return this.http.post<string>(`${environment.apiUrlBasePath}/carts/add`, item);
    }
    return of(ResponseMessage.AddSuccess);
  }

  public getCartItems(): Observable<CartItem[]> {
    if (this.authService.isUserLoggedIn) {
      const response = this.http.get<CartItem[]>(`${environment.apiUrlBasePath}/carts/getCartItems`);
      return response;
    } else {
      const cartItems = this.localStorageService.getCartItems();
      const cartItemsIds = cartItems.map(item => item.id);
      return this.http.post<ProductDetails[]>(`${environment.apiUrlBasePath}/products/getProductsByIds`, cartItemsIds)
      .pipe(
        map(products => {
          // Map products to cart items
          return cartItems.map(item => ({
            id: item.id,
            quantity: item.quantity,
            product: products.find((product: { id: number; }) => product.id === item.id), // Find corresponding product
            singleBlockInfo:item.singleBlockInfo
          }));
        })
      );
    }
  }

  public updateCartQuantity(productId: number, quantity: number): Observable<string> {
    this.localStorageService.updateCartQuantity(productId, quantity);
    if (this.authService.isUserLoggedIn) {
      const body = {productId, quantity};
      return this.http.put<string>(`${environment.apiUrlBasePath}/carts/update`, body);
    } else {
      return of(ResponseMessage.UpdateSuccess);
    }
  }

  public removeCartItem(itemId: number): Observable<string> {
    this.localStorageService.removeCartItem(itemId);
    this.cartItemDeleted.emit(itemId);

    if (this.authService.isUserLoggedIn) {
      return this.http.delete(
        `${environment.apiUrlBasePath}/carts/delete/${itemId}`
      ) as Observable<string>;
    } else {
      return of(ResponseMessage.Delete);
    }
  }

  public removeAllCartItems(): Observable<string> {
    this.localStorageService.removeAllCartItems();

    if(this.authService.isUserLoggedIn) {
      return this.http.delete(
        `${environment.apiUrlBasePath}/carts/removeAll`
      ) as Observable<string>;
    } else {
      return of(ResponseMessage.Delete);
    }
  }

  public processCartItems(): void {
    const oldCartItems = this.localStorageService.getCartItems();

    if(!oldCartItems?.length || !oldCartItems) {
      this.getCartItems().subscribe(savedItems => {
        if (!savedItems.length) {
          return;
        }

        const body = savedItems.map((item) => ({
          id: item.product!.id,
          quantity: item.quantity,
          singleBlockInfo: item.singleBlockInfo
        }));

        this.localStorageService.setCartItems(body);
      });
      return;
    }

    //If there is old cart items 
    this.getCartItems().subscribe(savedItems => {
      const body = savedItems.map((item) => ({
        id: item.product!.id,
        quantity: item.quantity,
        singleBlockInfo: item.singleBlockInfo
      }));

      this.localStorageService.setCartItems(body);

      for (let oldCartItem of oldCartItems) {

        const existingItemIndex = body.findIndex((item) => item.id === oldCartItem.id);
        if (existingItemIndex !== -1) { //if exist
          if(savedItems[existingItemIndex].quantity === oldCartItem.quantity) {
            continue;
          }
          this.updateCartQuantity(oldCartItem.id, oldCartItem.quantity).subscribe();
        } else { // if new
          this.addToCart(oldCartItem).subscribe();
        }
      }
    });
  }

  totalMRP: number = 0;
  totalDiscount: number = 0;
  totalAmount: number = 0;

  public calculateTotalMRP(items: CartItem[]): void {
    this.totalMRP = 0;
    this.totalDiscount = 0;
    this.totalAmount = 0;
    for (let item of items) {
      const quantity = item.quantity;
      const productTotalPrice = item.product!.price * quantity;
      const productTotalDiscount =
        (item.product!.discountPrice ? item.product!.price - item.product!.discountPrice : 0) * quantity
      this.totalMRP += productTotalPrice;
      this.totalDiscount += productTotalDiscount;
      this.totalAmount = this.totalMRP - this.totalDiscount;
    }
  }
}
