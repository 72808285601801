import { ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ProductService } from '../services/product/product.service';
import { ProductBody, ProductDetails } from '@models/product.model';
import { WishlistService } from '../services/wishlistService/wishlist.service';
import { AuthService } from '../services/auth/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WishListItem } from '@models/cart.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-product-image-gallery',
  templateUrl: './product-image-gallery.component.html',
  styleUrls: ['./product-image-gallery.component.scss']
})
export class ProductImageComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild('slider') slider!: ElementRef;
  @ViewChild('imageSidebar') imageSidebar!: ElementRef;

  @Input() productId!: number;

  public productDetail!: ProductDetails;
  public data: ProductBody[] = [];
  public imageObject: {
    image: string;
    thumbImage: string;
    video?: string;
  }[] = [];
  public currentIndex = 0;
  private interval: ReturnType<typeof setTimeout> | null = null;
  private isHovered = false;
  private delay = 5000 //ms

  constructor(private productService: ProductService,
     private cdr: ChangeDetectorRef,
     private wishListItemService: WishlistService,
     private authService:AuthService,
     private snackBar: MatSnackBar,
     private router: Router,
    ) { }

  ngOnInit(): void {
    if (this.productId) {
      this.loadProducts();
    }
  }

  ngOnChanges(): void {
    this.stopCarousel(); // Stop any existing interval
    setTimeout(() => {
      this.startCarousel();
    }, 0);
  }

  ngOnDestroy() {
    this.stopCarousel();
  }

  private loadProducts(): void {
    this.productService.getProductById(this.productId).subscribe((product) => {
      this.productDetail = product;
      this.loadImagesAndVideos();
    });
  }

  private loadImagesAndVideos(): void {
    if (this.productDetail && this.productDetail.images) {

      const imagesArray = this.productDetail.images.imagesUrl || [];
      let videoUrls: string[] = [];

      if (typeof this.productDetail.video === "string") {
        try {
          videoUrls = JSON.parse(this.productDetail.video) as string[];
        } catch (e) {
          videoUrls = [];
        }
      } else if (Array.isArray(this.productDetail.video)) {
        videoUrls = this.productDetail.video;
      }

      this.imageObject = imagesArray.map(imageUrl => ({
        image: imageUrl,
        thumbImage: imageUrl
      }));

      if (Array.isArray(videoUrls)) {
        videoUrls.forEach(videoUrl => {
          if (typeof videoUrl === "string" && videoUrl) {
            const fileExtension = videoUrl
              .split(".")
              .pop()
              ?.toLowerCase();

            if (fileExtension === "mp4") {
              this.imageObject.push({
                image: videoUrl,
                thumbImage: videoUrl,
                video: videoUrl
              });
            } else {
              throw new Error(`Unsupported video format: ${fileExtension}`);
            }
          }
        });
      }

    }

    for (let i = 0; i < this.data.length; i++) {
      this.data.forEach((item: { id: number; quantity: number }) => {
        if (item.id === this.productDetail.id) {
          this.productDetail.disable = true;
          this.productDetail.quantity = item.quantity;
        } else {
          this.productDetail.disable = false;
        }
      });
    }
    this.cdr.detectChanges();
  }

  private startCarousel(): void {
    this.interval = setInterval(() => {
      if (!this.isHovered) {
        this.showNextImage();
      }
    }, this.delay); // Change image every 5 seconds
  }

  private stopCarousel(): void {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  public showNextImage(): void {
    this.currentIndex = (this.currentIndex + 1) % this.imageObject.length;
    this.scrollToActiveImage();
  }

  public showPrevImage(): void {
    this.currentIndex = (this.currentIndex - 1 + this.imageObject.length) % this.imageObject.length;
    this.scrollToActiveImage();
  }

  public goToImage(index: number): void {
    const stepDelay = 200; // 200ms delay between steps

    const changeIndex = () => {
      if (index < this.currentIndex) {
        this.currentIndex--;
      } else if (index > this.currentIndex) {
        this.currentIndex++;
      }
      if (index !== this.currentIndex) {
        setTimeout(changeIndex, stepDelay);
      }
    };

    changeIndex();
    this.scrollToActiveImage();
  }

  public onMouseEnter(): void {
    this.isHovered = true;
    this.stopCarousel();
  }

  public onMouseLeave(): void {
    this.isHovered = false;
    this.startCarousel();
  }

  public onThumbnailClick(index: number): void {
    this.currentIndex = index;
    const mainInner = this.slider.nativeElement.querySelector(".ng-image-slider-container .main-inner") as HTMLElement;
    const slides = mainInner?.querySelectorAll(".image-popup");
    if (slides && slides[index]) {
      slides[index].scrollIntoView({ behavior: "smooth" });
    }
    this.scrollToActiveImage(); 
  }

  private scrollToActiveImage(): void {
    const sidebar = this.imageSidebar.nativeElement;
    const activeImage = sidebar.querySelectorAll('.sidebar-image')[this.currentIndex];
    if (activeImage) {
      activeImage.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }

  public addToFavorite(): void {
    this.wishListItemService.addToFavorite(this.productDetail);
  }

  public  isInWishlist(): boolean {
    return this.wishListItemService.isInWishlist(this.productDetail);
  }

}
