import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ReviewModel } from '@models/admin.model';

@Component({
  selector: 'app-open-review-images',
  templateUrl: './open-review-images.component.html',
  styleUrls: ['./open-review-images.component.scss']
})
export class OpenReviewImagesComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      reviewProductData:ReviewModel;
    },
    public dialogRef: MatDialogRef<OpenReviewImagesComponent>,
  ) {}

  public closeDialog(): void {
    this.dialogRef.close("");
  }

  public sliderImages(): object[] {
    const images = this.data?.reviewProductData?.images.imagesUrl;
  
     if (Array.isArray(images)) {
      return images.map((url: string) => ({
       image: url,           
       thumbImage: url      
      }));
     }
    return [];
  }
}
