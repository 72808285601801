import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import {
  NullableOrder,
  OrderAttributes,
  OrderInput,
  OrderPageResponse,
  OrderPageDetail
} from "@models/order.model";
import { AdminStatusHistoryModel } from "@models/adminStatusHistory.model";
import { TableDataResponse } from "@models/data-grid.model";

@Injectable({
  providedIn: "root"
})
export class OrderService {
  constructor(private readonly http: HttpClient) {}

  public createOrder(orderData: OrderInput): Observable<OrderAttributes> {
    return this.http.post(
      `${environment.apiUrlBasePath}/orders/create`,
      orderData
    ) as Observable<OrderAttributes>;
  }

  public getOrderById(orderId: string): Observable<OrderPageDetail> {
    return this.http.get<OrderPageDetail>(
      `${environment.apiUrlBasePath}/orders/${orderId}`
    );
  }

  public getOrder(
    pageIndex: number = environment.dynamicPagination.pageIndex,
    pageSize: number = environment.dynamicPagination.pageSize
  ): Observable<OrderPageResponse> {
    return this.http.get<OrderPageResponse>(
      `${environment.apiUrlBasePath}/orders/mobileNumber?pageIndex=${pageIndex}&pageSize=${pageSize}`
    );
  }

  sendHelpRequest(message: string, orderId: number): Observable<string> {
    const payload = { message, orderId };
    return this.http.post<string>(
      `${environment.apiUrlBasePath}/orders/helpRequest`,
      payload
    );
  }

  public addOrderStatus(params: {
    id: number;
    newStatus: string;
    date: Date;
    time: string;
    awbNumber: string;
    courierName: string;
    order: OrderPageDetail;
  }): Observable<AdminStatusHistoryModel> {
    return this.http.post<AdminStatusHistoryModel>(
      `${environment.apiUrlBasePath}/admin/updateOrderStatus/`,
      params
    );
  }

  public getAllOrders(
    pageIndex: number = environment.dynamicPagination.pageIndex,
    pageSize: number = environment.dynamicPagination.pageSize,
    status?: string
  ): Observable<TableDataResponse> {
    return this.http.get<TableDataResponse>(
      `${environment.apiUrlBasePath}/admin/allOrders?pageIndex=${pageIndex}&pageSize=${pageSize}&status=${status}`
    );
  }

  public getAllStatus(): Observable<string[]> {
    return this.http.get<string[]>(
      `${environment.apiUrlBasePath}/admin/getAllStatus`
    );
  }

  public getOrderDetails(id: number): Observable<OrderPageDetail> {
    return this.http.get<OrderPageDetail>(
      `${environment.apiUrlBasePath}/admin/getOrderDetails/${id}`
    );
  }

  public cancelOrder(orderId: number): Observable<{message: string}> {
    return this.http.put<{message: string}>(`${environment.apiUrlBasePath}/orders/${orderId}/cancel`, {});
  }

  public returnOrder(orderId: number, reason: string): Observable<{message: string}> {
    const body: { reason: string } = { reason };
    return this.http.put<{message: string}>(`${environment.apiUrlBasePath}/orders/${orderId}/return`, body);
  }
}
