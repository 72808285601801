<footer class="new-class">
  <div class="content">
    <span><a (click)="onClickPrivacy()">Privacy Policy</a></span>
    <span><a (click)="onClickTermCondition()">Terms & Conditions</a></span>
    <span><a href='#'>About us</a></span>
  </div>
  <div class='social-media'>
    <span><a href='#'>
      <mat-icon
          svgIcon="facebook"
          class="icon"
          aria-hidden="false"
          aria-label="facebook"
        ></mat-icon>
    </a></span>
    <span><a href='#'>
      <mat-icon
          svgIcon="twitter"
          class="icon"
          aria-hidden="false"
          aria-label="twitter"
        ></mat-icon>
    </a></span>
    <span><a href='#'>
      <mat-icon
          svgIcon="instagram"
          class="icon"
          aria-hidden="false"
          aria-label="instagram"
        ></mat-icon>
    </a></span>
  </div>
  <div class="copy-right">
    Copyright © funfigz {{ currentYear }}
  </div>
</footer>
