export const DynamicGenerateShape = {
    X_Max_Deviation_Cube: 16,
    Min_Deviation: 9,
    Max_Deviation: 11,
    X_Min_Deviation_For_Curvedcube2_Left : 17,
    X_Max_Deviation_For_Curvedcube2_Left : 19,
    X_Min_Deviation_For_Curvedcube2_Front_or_Back : 2.5,
    X_Max_Deviation_For_Curvedcube2_Front_or_Back : 17.5,
    X_Max_Deviation_For_Curvedcube2_Right: 5,
    X_Min_Deviation_For_Curvedcube2_Right: 2,
    Y_Deviation_For_Curvedcube2_Back : 15,
    X_Min_Deviation_For_Curvedcube: 5,
    X_Max_Deviation_For_Curvedcube: 15,
    X_Min_Deviation_For_Curvedcube_Left: 14,
    X_Max_Deviation_For_Curvedcube_Left: 16,
    Y_Min_Deviation_For_HalfCylinder: 5,
    Y_Max_Deviation_For_HalfCylinder: 7,
    X_Deviation_For_Prism: 8,
    Y_Min_Deviation_For_Prism_Right: 8,
    Y_Max_Deviation_For_Prism_Right: 14,
    Y_Min_Deviation_For_Prism_Left: 6,
    Y_Max_Deviation_For_Prism_Left: 14,
    Y_Min_Deviation_For_Prism_Bottom: 5,
    Y_Max_Deviation_For_Prism_Bottom: 8,
    Deviation: 10,
    X_Deviation: 15,
    AdjustmentConstant: 10,
    Min_Dev: 2,
    IntersectChildrenLength:2 
}