<div  *ngIf="dataSource; else content">
    <div class="customiseDialogBox">

        <div fxLayout="row" fxLayoutAlign="center center">
            <h1 class="heading">Count Information</h1>
            <a mat-dialog-close style="position: absolute; right: 0;" href="https://www.google.com/" target="_blank" >
                <mat-icon class="close-icon">close</mat-icon></a>
        </div>
        <section class="example-container mat-elevation-z8" tabindex="0">
            <table mat-table [dataSource]="dataSource">

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> Face-Pattern </th>
                    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                </ng-container>
                <!-- Combination Column -->
                <ng-container matColumnDef="facePattern">
                    <th mat-header-cell *matHeaderCellDef> Face-Pattern </th>
                    <td mat-cell *matCellDef="let element"> {{element.facePattern}} </td>
                </ng-container>

                <!-- Color Column -->
                <ng-container matColumnDef="color">
                    <th mat-header-cell *matHeaderCellDef> Color </th>
                    <td mat-cell *matCellDef="let element"> {{element.color}} </td>
                </ng-container>

                <!-- Count Column -->
                <ng-container matColumnDef="count">
                    <th mat-header-cell *matHeaderCellDef> Count </th>
                    <td mat-cell *matCellDef="let element"> {{element.count}} </td>
                </ng-container>

                   <!-- Connection Data Column -->
                <ng-container matColumnDef="connectionDetails">
                  <th mat-header-cell *matHeaderCellDef> Connection Data </th>
                  <!-- <td mat-cell *matCellDef="let element"> {{element.connectionData[0]}} </td> -->
                   <td class="connection-data" *matCellDef="let element">
                     <div *ngIf="element && element.connectionData">
                        <div *ngFor="let data1 of element.connectionData">
                          {{ data1?.face}}  -->  {{ data1?.connectionType }}
                        </div>
                      </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </section>
    </div>
</div>
<ng-template #content>
    <mat-progress-spinner
        class="example-margin"
        color="warn"
        mode="indeterminate"
        value="100">
    </mat-progress-spinner>
</ng-template>
