import { Injectable } from '@angular/core';
import { CanvasConfig } from '@utils/canvas-configuration';
import { Object3DUserData } from '@utils/shape';
import { AvailableShapes } from '@utils/shape-facetype';
import { Vector2, Mesh } from 'three';
import { AlteredShapeConfiguration } from '../../../../../utils/shape';
import { FaceIdentifierService } from '../face-identifier/face-identifier.service';
import { GenerateNewShapes } from '../generate-new-shapes';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  private gridCells: Array<Array<Array<string>>> = [];
  constructor( private faceIdentifierSrv: FaceIdentifierService,  private generateNewShapes: GenerateNewShapes,) {}

  /*
    This method is used to get the location y for all the shapes with respect to the ground.
  */
  public getGroundLocationY(initialLocationY: number): number {
    // Here we make the shape sit on the grid avoiding them to go inside.
    initialLocationY =
      initialLocationY <= AlteredShapeConfiguration.standardYCo_Ordinate
        ? AlteredShapeConfiguration.standardYCo_Ordinate +
          (AlteredShapeConfiguration.standardHeigthOfShape /
            AlteredShapeConfiguration.divisorAdjustYPosition +
            AlteredShapeConfiguration.yOffset)
        : initialLocationY;
    return initialLocationY;
  }

  /*
    This method will return the nearest coordinates of the grid on the plane
    helps to generate or drop the shapes on the nearest grid.
  */
  public getNearestGridCords(
    selectedShape: string,
    twoDCords: THREE.Vector2
  ): THREE.Vector2 {
    /*
      Vector2 and THREE.Vector2 has only two coordinates 'x' and 'y', but in our case we are
      considering 'y' as 'z' since y is alredy been defined, 'x' and 'z' are calculated and altered for any shape
      to place it on the grid.
    */

    // Calculate the nearest grid intersection in X and Z directions.
    const gridSpacing =
      CanvasConfig.gridHelperSize / CanvasConfig.gridHelperDivisions;
    // This is to make the shape perfectly sit in the grid, avoiding them to cross the grid-lines.
    const shapeSpecificOffset = (
      selectedShape === AvailableShapes.CurvedCube
        ? AlteredShapeConfiguration.curvedCubeShapeXOffset
        : selectedShape === AvailableShapes.CurvedCube2
        ? AlteredShapeConfiguration.curvedCube2ShapeXOffset
        : 0
    );

    const nearestGridX = Math.round(twoDCords.x / gridSpacing) * gridSpacing + shapeSpecificOffset;

    const nearestGridZ = Math.round(twoDCords.y / gridSpacing) * gridSpacing;

    return new Vector2(nearestGridX, nearestGridZ);
  }

  // This will calculate the row and column of the grid if passed the position of the shape.
  public calculateRowAndColumn(shape: THREE.Mesh): {
    row: number;
    column: number;
    cell: number;
  } {
    const gridSize = CanvasConfig.gridHelperSize;
    const cellSize = CanvasConfig.gridHelperDivisions - 5;

    // Get the position.
    const position = shape.position;

    const row = (position.z - 10 + gridSize / 2) / cellSize;
    let column = (position.x - 10 + gridSize / 2) / cellSize;
    const cell = Math.round((position.y + 103.5) / cellSize);

    column = (
      shape.name === AvailableShapes.Cube ||
      shape.name === AvailableShapes.HalfCylinder ||
      shape.name === AvailableShapes.Prism ||
      shape.name === AvailableShapes.SquarePyramid ||
      shape.name === AvailableShapes.Cone ||
      shape.name === AvailableShapes.Cylinder ||
      shape.name === AvailableShapes.HemiSphere
    )
      ? column
      : (
        shape.name === AvailableShapes.CurvedCube
          ? column - (AlteredShapeConfiguration.curvedCubeShapeXOffset / AlteredShapeConfiguration.standardHeigthOfShape)
          : (
            shape.name === AvailableShapes.CurvedCube2
              ? column - (AlteredShapeConfiguration.curvedCube2ShapeXOffset / AlteredShapeConfiguration.standardHeigthOfShape)
              : undefined
          )
      )!;

    return { row, column, cell };
  }

  public isShapeDraggable(shape: Mesh, objects: THREE.Object3D[]): boolean {
    const { row, column, cell } = this.calculateRowAndColumn(shape);

    const isShapeOnTop = objects.some(
      (otherShape) =>
        otherShape.userData[Object3DUserData.cellReference].row === row &&
        otherShape.userData[Object3DUserData.cellReference].column === column &&
        otherShape.userData[Object3DUserData.cellReference].cell === cell + 1
    );

    const isShapeOnBottom = objects.some(
      (otherShape) =>
        otherShape.userData[Object3DUserData.cellReference].row === row &&
        otherShape.userData[Object3DUserData.cellReference].column === column &&
        otherShape.userData[Object3DUserData.cellReference].cell === cell - 1
    );

    const isShapeOnRight = objects.some(
      (otherShape) =>
        otherShape.userData[Object3DUserData.cellReference].row === row + 1 &&
        otherShape.userData[Object3DUserData.cellReference].column === column &&
        otherShape.userData[Object3DUserData.cellReference].cell === cell
    );

    const isShapeOnLeft = objects.some(
      (otherShape) =>
        otherShape.userData[Object3DUserData.cellReference].row === row - 1 &&
        otherShape.userData[Object3DUserData.cellReference].column === column &&
        otherShape.userData[Object3DUserData.cellReference].cell === cell
    );

    const isShapeOnFront = objects.some(
      (otherShape) =>
        otherShape.userData[Object3DUserData.cellReference].row === row &&
        otherShape.userData[Object3DUserData.cellReference].column ===
          column + 1 &&
        otherShape.userData[Object3DUserData.cellReference].cell === cell
    );

    const isShapeOnBack = objects.some(
      (otherShape) =>
        otherShape.userData[Object3DUserData.cellReference].row === row &&
        otherShape.userData[Object3DUserData.cellReference].column ===
          column - 1 &&
        otherShape.userData[Object3DUserData.cellReference].cell === cell
    );

    const occupiedSidesCount = [
      isShapeOnTop, isShapeOnBottom, isShapeOnRight,
      isShapeOnLeft, isShapeOnFront, isShapeOnBack
    ].filter(side => side).length;

    return isShapeOnTop ? !isShapeOnTop : occupiedSidesCount < 2;
  }
}
