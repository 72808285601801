<div class="move-from-cart" *ngIf="
    data.componentName === DialogInvokingComponents.RemoveFromCart ||
    data.componentName === DialogInvokingComponents.DeleteAddress ||
    data.componentName === DialogInvokingComponents.CancelOrder ||
    data.componentName === DialogInvokingComponents.MyStructuresList ||
    data.componentName === DialogInvokingComponents.SaveDesignConfirmation ||
    data.componentName === DialogInvokingComponents.RemoveFromNotification
  ">
  <h2 mat-dialog-title>{{ data.title }}</h2>
  <button mat-icon-button *ngIf="data.image" class="close-button" (click)="closeDialog()">
    <mat-icon>close</mat-icon>
  </button>
  <mat-dialog-content class="mat-typography">
    <div class="item-container">
      <div class="image-container" *ngIf="data.componentName === DialogInvokingComponents.RemoveFromCart && data.image">
        <div class="image">
          <img [src]="data.image" alt="Product Image" *ngIf="data.image; else noImage" />

          <ng-template #noImage>
            <div class="image-placeholder"></div>
          </ng-template>
        </div>
      </div>
      <p>{{ data.content }}</p>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="actions-container" align="end">
    <button mat-button class="save-btn" (click)="closeDialogPublish()">
      {{ data.firstBtn }}
    </button>
    <button mat-button (click)="closeDialog()" *ngIf="data.secondBtn" class="cancel-btn">
      {{ data.secondBtn }}
    </button>
  </mat-dialog-actions>
</div>

<div class="changeAddress" *ngIf="data.componentName === DialogInvokingComponents.ChangeAddress">
  <h2 mat-dialog-title>{{ data.title }}</h2>
  <button mat-icon-button class="close-button" (click)="closeDialog()">
    <mat-icon>close</mat-icon>
  </button>
  <mat-dialog-content class="mat-typography changeAddress-content">
    <div class="address-container">
      <mat-radio-group aria-label="Select an option" class="address-radio-group" [(ngModel)]="data.selectedAddressId"
        (change)="onAddressChange()" color="primary">
        <div class="address-card" *ngFor="let address of data.addresses" [ngClass]="{
            'selected-address-card': address['id'] === data.selectedAddressId
          }">
          <label class="address-label">
            <mat-radio-button class="my-radio-button" [value]="address.id">
              <div class="address-content">
                <div class="address-content-heading">
                  {{ address.name }}, {{ address.pincode }}
                  <span class="address-type">{{ address.addressType }}</span>
                </div>

                <p class="address-content-body">
                  {{ address.houseNumber }}, {{ address.address }},
                  {{ address.city }}, {{ address.state }}
                </p>
              </div>
            </mat-radio-button>
          </label>
        </div>
      </mat-radio-group>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="actions-container" align="end">
    <button mat-button  (click)="onAddAddress()" class="save-btn">
      Add New
    </button>
  </mat-dialog-actions>
</div>

<div class="addAddress" *ngIf="data.componentName === DialogInvokingComponents.AddAddress">
  <h2 mat-dialog-title [autofocus]="true">{{ data.title }}</h2>
  <button mat-icon-button class="close-button" (click)="closeDialog()" autoFocus="false">
    <mat-icon>close</mat-icon>
  </button>

  <mat-dialog-content class="mat-typography addAddress-content">
    <div class="user-details-container" *ngIf="data.withUserProfileDetails; else withoutUserProfileDetails">
      <div class="personal-info-container">
        <h2>Personal Info</h2>
        <profile-details [isInDialog]="true" [hideAgeAndShowMobile]="data.hideAgeAndShowMobile"
          (profileData)="receiveProfileData($event)" #profileDetailRef></profile-details>
      </div>

      <div class="address-container">
        <h2>Address</h2>
        <app-address [isInDialog]="true" [showNameAndMobile]="true" (addressData)="receiveAddressData($event)"
          #addressDetailsRef></app-address>
      </div>
    </div>

    <ng-template #withoutUserProfileDetails>
      <app-address [isInDialog]="true" [showNameAndMobile]="true" (addressData)="receiveAddressData($event)"
        #addressDetailsRef></app-address>
    </ng-template>
  </mat-dialog-content>

  <mat-dialog-actions class="actions-container" align="end">
    <button mat-button [mat-dialog-close]="{
        userDetails: profile,
        addressDetails: address
      }" [disabled]="!isFormDataValid()" class="save-btn">
      Submit
    </button>
  </mat-dialog-actions>
</div>

<div *ngIf="data.componentName === DialogInvokingComponents.ApproveRejectReview">
  <div class="courier-information">
    <h2 mat-dialog-title>{{ data.title }}</h2>
    <button mat-icon-button class="close-button" (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>
    <mat-dialog-content class="mat-typography">
      <form [formGroup]="courierForm">
        <div class="item-container" *ngFor="let input of data.inputs">
          <mat-form-field>
            <textarea matInput *ngIf="input.type === 'text'" [formControlName]="input.controlName"
              [placeholder]="input.placeholder" [value]="input.value" [type]="input.type"></textarea>
          </mat-form-field>
        </div>
      </form>
    </mat-dialog-content>
    <mat-dialog-actions class="actions-container" align="end">
      <button mat-button [mat-dialog-close]="true" class="save-btn" [disabled]="this.courierForm.valid"
        (click)="onApprove()">
        {{ data.firstBtn }}
      </button>
      <button mat-button [disabled]="!this.courierForm.valid" (click)="closeDialog()" class="cancel-btn"
        (click)="onReject()">
        {{ data.secondBtn }}
      </button>
    </mat-dialog-actions>
  </div>
</div>

<div *ngIf="
    data.componentName === DialogInvokingComponents.HelpMessage ||
    data.componentName === DialogInvokingComponents.CourierInfo ||
    data.componentName === DialogInvokingComponents.ReturnOrder ||
    data.componentName === DialogInvokingComponents.PickupScheduled
  ">
  <div class="courier-information">
    <h2 mat-dialog-title>{{ data.title }}</h2>
    <mat-dialog-content class="mat-typography">
      <p *ngIf="data.content">{{ data.content }}</p>
      <br />
      <form [formGroup]="courierForm">
        <div class="item-container" *ngFor="let input of data.inputs">
          <mat-form-field class="example-full-width">
            <input matInput *ngIf="input.type !== 'date' && input.type !== 'time' "
              [formControlName]="input.controlName" [placeholder]="input.placeholder" [value]="input.value"
              [type]="input.type" />

            <input matInput *ngIf="input.type === 'time'" [formControlName]="input.controlName"
              [ngxTimepicker]="toggleTimepicker" [disableClick]="true" readonly [placeholder]="input.placeholder">
            <mat-icon matSuffix *ngIf="input.type=== 'time'" (click)="toggleTimepicker.open()">schedule</mat-icon>
            <ngx-material-timepicker #toggleTimepicker class="custom-time-picker"></ngx-material-timepicker>

            <input matInput *ngIf="input.type === 'date'" [formControlName]="input.controlName"
              [placeholder]="input.placeholder" [matDatepicker]="picker" [value]="input.value" [min]="todayDate"
              [disabled]="true" readonly />
            <mat-datepicker-toggle *ngIf="input.type === 'date'" matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
      </form>

    </mat-dialog-content>
    <mat-dialog-actions class="actions-container" align="end">
      <button mat-button [mat-dialog-close]="true" class="save-btn" (click)="save()"
        [disabled]="!this.courierForm.valid">
        {{ data.firstBtn }}
      </button>
      <button mat-button (click)="closeDialog()" class="cancel-btn" *ngIf="data.secondBtn">
        {{ data.secondBtn }}
      </button>
    </mat-dialog-actions>
  </div>
</div>

<div *ngIf="data.componentName === DialogInvokingComponents.ImageSlider">
  <div class="image-content">
    <ng-image-slider #nav [images]="data.imageSlider" [infinite]="true" [autoSlide]="5" [imageSize]="sliderImageSize"
      class="image" slideImage="1">
    </ng-image-slider>
    <mat-dialog-content class="mat-typography"> </mat-dialog-content>
  </div>
</div>

<div *ngIf="data.componentName === DialogInvokingComponents.StructureDetails">
  <h2 mat-dialog-title>{{ data.title }}</h2>

  <mat-dialog-content class="mat-typography">
    <form [formGroup]="saveStructureForm">
      <div class="input-field">
        <label for="structureName" class="required">Structure Name:</label>
        <input id="structureName" type="text" formControlName="structureName"
          placeholder="Give your structure a name" />
        <div *ngIf="isInvalid(saveStructureForm, 'structureName')">
          <div *ngIf="isInvalid(saveStructureForm, 'structureName', 'required')" class="error-text">
            Structure name is required !
          </div>
          <div *ngIf="isInvalid(saveStructureForm, 'structureName', 'leadingSpaces')" class="error-text">
            Name cannot contain only whitespace.
          </div>
        </div>
      </div>
      <div class="input-field">
        <label for="structureDescription" class="required">Structure Description:</label>
        <textarea id="structureDescription" rows="4" formControlName="structureDescription"
          placeholder="Brief description about the structure"></textarea>

        <div *ngIf="isInvalid(saveStructureForm, 'structureDescription')">
          <div *ngIf="isInvalid(saveStructureForm, 'structureDescription', 'required')" class="error-text">
            Structure description is required !
          </div>
          <div *ngIf="isInvalid(saveStructureForm, 'structureDescription', 'leadingSpaces')" class="error-text">
            Description cannot contain only whitespace.
          </div>
        </div>
      </div>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions class="actions-container" align="end">
    <button
      mat-button
      class="save-btn"
      (click)="confirmationDialog()"
      [disabled]="saveStructureForm.invalid"
    >
      {{ data.firstBtn }}
    </button>
    <button mat-button (click)="closeDialog()" *ngIf="data.secondBtn" class="cancel-btn">
      {{ data.secondBtn }}
    </button>
  </mat-dialog-actions>
</div>

<!-- my structure properties  -->
<div *ngIf="data.componentName === DialogInvokingComponents.MyStructuresListProperties">
  <h2 mat-dialog-title>{{ data.title }}</h2>
  <mat-dialog-content class="mat-typography">
    <form [formGroup]="myStructureListForm">
      <!--  Structure Name  -->
      <div>
        <label>Name:<span class="required"> *</span></label>
        <input type="text" formControlName="myStructureName" required autocomplete="off" />
      </div>
      <!-- Structure Description  -->
      <div>
        <label>Description:</label>
        <textarea formControlName="myStructureDescription"></textarea>
      </div>
      <!-- Status  -->
      <div>
        <label>Status:</label>
        <div>
          <mat-select formControlName="status">
            <mat-option *ngFor="let status of myStructureStatus" [value]="status.value"
              [disabled]="disableStatus(status.value)">{{ status.name }}</mat-option>
          </mat-select>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions class="actions-container" align="end">
    <button mat-button (click)="updateStructureData()" [disabled]="myStructureListForm.invalid || isStatusDisabled()"
     class="save-btn">
      {{ data.firstBtn }}
    </button>
    <button mat-button (click)="closeDialog()" *ngIf="data.secondBtn" class="cancel-btn">
      {{ data.secondBtn }}
    </button>
  </mat-dialog-actions>
</div>

<div *ngIf="data.componentName === DialogInvokingComponents.DeleteAdvertisement">
  <h2 mat-dialog-title>{{ data.title }}</h2>
  <button mat-icon-button *ngIf="data.image" class="close-button" (click)="closeDialog()">
    <mat-icon>close</mat-icon>
  </button>
  <mat-dialog-content class="mat-typography">
    <div class="banner-item-container">
      <p>{{ data.content }}</p>
      <div
        class="banner-image-container"
        *ngIf="data.image"
      >
        <div class="banner-image">
          <img [src]="data.image" alt="Product Image" *ngIf="data.image; else noImage" />

          <ng-template #noImage>
            <div class="banner-image-placeholder"></div>
          </ng-template>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="actions-container" align="end">
    <button mat-button [mat-dialog-close]="true" class="save-btn">
      {{ data.firstBtn }}
    </button>
    <button mat-button (click)="closeDialog()" *ngIf="data.secondBtn" class="cancel-btn">
      {{ data.secondBtn }}
    </button>
  </mat-dialog-actions>
</div>

<div *ngIf="data.componentName === DialogInvokingComponents.EmailMessage">
<h1 mat-dialog-title>{{ data.title }}</h1>
<div mat-dialog-content [innerHTML]="data.message"></div>
<div mat-dialog-actions>
  <button mat-button (click)="closeDialog()">Close</button>
</div>
