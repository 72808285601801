export enum ColorCodes {
    sceneBackground = 0xbfd1e5,
    miniSceneBackground= 0x212124,
    renderSetClear = 0x333333,
    pointLight = 0xffffff,
    directionalLight = 0xffffff,
    canvasMaterial = 0xFFFFFF,
    red = 0xE64A4A, // default cube color
    yellow = 0xfae319, // shapes border color
    white = 0xffffff // for light
}

export enum ColorPickerInputConfg{
    inputType = 'radio',
    name = 'color',
    accessbilityRed = 'dark green',
    valueAttributeForRed = '#117A65',
    accessbilityGreen = 'green',
    valueAttributeForGreen = '#21BA45',
    accessbilityYellow = 'yellow',
    forAttributeForYellow =  'yellow',
    valueAttributeForYellow = '#5DADE2',
    accessbilityOlive = 'olive',
    forAttributeForOlive =  'olive',
    valueAttributeForOlive = '#3633BF',
    accessbilityOrange = 'orange',
    forAttributeForOrange =  'orange',
    valueAttributeForOrange = '#CF871B',
    accessbilityTeal = 'teal',
    forAttributeForTeal =  'teal',
    valueAttributeForTeal= '#00B5AD',
    accessbilityBlue = 'lightblue',
    forAttributeForBlue =  'lightblue',
    valueAttributeForBlue = '#2185D0',
    accessbilityViolet = 'darkblue',
    forAttributeForViolet =  'darkblue',
    valueAttributeForViolet = '#3C79B7',
    
}
