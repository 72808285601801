import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { NotificationModel } from '../../models/notification.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(private http: HttpClient) { }

  public saveNotification(): Observable<NotificationModel>{
    return this.http.post<NotificationModel>(`${environment.apiUrlBasePath}/notifications/save`, {});
  }

 public  getNotifications(
  pageIndex: number = environment.dynamicPagination.pageIndex,
  pageSize: number = environment.dynamicPagination.pageSize,
  userId :number
 ): Observable<{ count: number; rows: Notification[], totalUnreadCount: number }> {
    return this.http.get<{ count: number; rows: Notification[], totalUnreadCount: number}>(`${environment.apiUrlBasePath}/notifications/getNotification?pageIndex=${pageIndex}&pageSize=${pageSize}&userId=${userId}`);
  }

  public markAsViewednotification(id: number): Observable<{message:string}> {
    return this.http.patch<{message:string}>(`${environment.apiUrlBasePath}/notifications/viewed/?id=${id}`, {})
  }

  public deleteNotifications(ids: number[]): Observable<{ message: string }> {
    const params = ids.map(id => `ids=${id}`).join('&');
    return this.http.delete<{ message: string }>(`${environment.apiUrlBasePath}/notifications/delete?${params}`);
  }

}
