<div class="section">
  <div class="container">
    <div class="image-grid">
      <div class="product-item" *ngFor="let product of productList">
        <div class="product-card">
          <div class="item-image-container" (mouseenter)="startImageRotation(product.id)"
            (mouseleave)="stopImageRotation(product.id)" matTooltip="{{ product.name }}" matTooltipPosition="right">
            <img *ngIf="product.images; else placeholder" [src]="getCurrentImageUrl(product.id)"
              (click)="onClickProduct(product.id)" alt="Item Image" />

            <!-- Template for the placeholder when there's no image -->
            <ng-template #placeholder>
              <!-- Placeholder div with a specific height to retain space -->
              <div class="image-placeholder"></div>
            </ng-template>
            <div class="rating-content">
              <!-- wishlist icon -->
              <mat-icon class="wish-list" 
              [ngClass]="{'active': isInWishlist(product)}" 
               (click)="addToFavorite(product)">
                  favorite
             </mat-icon>
             <!--  rating icon -->
              <p class="rating" *ngIf="product.averageRating">
                {{ product.averageRating }}
                <mat-icon class="star">star</mat-icon>
                <span *ngIf="product.averageRating && product.reviews?.length" class="count-rating">
                  {{ "(" + +product.reviews!.length + ")" }}
                </span>
              </p>
            </div>
            
          </div>
          <div class="product-card-content">
            <p class="product-name">{{ product.name }}</p>
            <div class="product-price">
              <p [ngClass]="{
                  'cut-price':
                    product.discountPrice &&
                    product.discountPrice !== 0 &&
                    product.discountPrice !== product.price
                }">
                {{ "Rs. " + product.price }}
              </p>
              <p *ngIf="
                  product.discountPrice &&
                  product.discountPrice !== 0 &&
                  product.discountPrice !== product.price
                ">
                {{ "Rs. " + product.discountPrice }}
              </p>
            </div>

            <!-- Section 2 (Add, Increment, Decrement) -->
            <div class="add-to-container">
              <!-- Show quantity controls if the category is not 'Buy Blocks' -->
              <ng-container *ngIf="!product.category?.includes(productCategories.BuyBlocks); else customizeButton">
                <div *ngIf="getProductQtyInCart(product.id); else addProduct" class="quantity-add">
                  <button class="decrement" [disabled]="getProductQtyInCart(product.id) <= 0"
                    (click)="onclick(-1, product.id)">
                    -
                  </button>
                  <input class="quantity" type="number" [value]="getProductQtyInCart(product.id)"
                    (input)="onInputChange($event, product.id)" (blur)="onBlur($event, product.id)" />
                  <button class="increment" [disabled]="getProductQtyInCart(product.id) >= 99"
                    (click)="onclick(1, product.id)">
                    +
                  </button>
                </div>
                <ng-template #addProduct>
                  <button mat-button class="add" (click)="addToCart(product.id, 1)">
                    ADD
                  </button>
                </ng-template>
              </ng-container>

              <!-- Show customize button if the category is 'Buy Blocks' -->
              <ng-template #customizeButton>
                <button mat-button class="add" (click)="onCustomize(product.id)">
                  CUSTOMIZE
                </button>
              </ng-template>
            </div>
            <!-- Section 2 End -->

          </div>
        </div>
      </div>
    </div>
    <div *ngIf="showButton">
      <button class="customise-btn" mat-raised-button (click)="onViewMoreClick()">
        View More
      </button>
    </div>
  </div>
  <app-right-navbar [isOpen]="isNavbarOpen" (closeEvent)="onCloseNavbar()"></app-right-navbar>
</div>