<div class="container">
  <div
    class="carousel-container"
    (mouseenter)="onMouseEnter()"
    (mouseleave)="onMouseLeave()"
    #slider
  >
    <mat-icon
      class="wish-list"
      [ngClass]="{ activeWishlist: isInWishlist() }"
      (click)="addToFavorite()"
    >
      favorite
    </mat-icon>
    <div class="carousel-image-container">
      <div
        *ngFor="let image of imageObject; let i = index"
        class="carousel-image"
        [class.active]="i === currentIndex"
        [class.enter]="
          imageObject.length > 2 &&
          i === (currentIndex + 1) % imageObject.length
        "
        [class.leave]="
          imageObject.length > 2 &&
          i === (currentIndex - 1 + imageObject.length) % imageObject.length
        "
      >
        <ng-container *ngIf="image.video; else imageTemplate">
          <video [src]="image.video" controls>
            Your browser does not support the video tag.
          </video>
        </ng-container>
        <ng-template #imageTemplate>
          <div class="aspect-ratio-box">
            <img [src]="image.image" alt="Image {{ i + 1 }}" />
          </div>
        </ng-template>
      </div>

      <button
        mat-icon-button
        class="carousel-button prev"
        (click)="showPrevImage()"
        *ngIf="imageObject.length > 1"
      >
        <mat-icon>chevron_left</mat-icon>
      </button>
      <button
        mat-icon-button
        class="carousel-button next"
        (click)="showNextImage()"
        *ngIf="imageObject.length > 1"
      >
        <mat-icon>chevron_right</mat-icon>
      </button>
    </div>
    <div class="carousel-dots" *ngIf="imageObject.length > 1">
      <span
        *ngFor="let image of imageObject; let i = index"
        class="carousel-dot"
        [class.active]="i === currentIndex"
        (click)="goToImage(i)"
      >
      </span>
    </div>
  </div>
  <div class="image-sidebar" #imageSidebar>
    <div
      *ngFor="let image of imageObject; let i = index"
      class="sidebar-image"
      (click)="onThumbnailClick(i)"
      [ngClass]="{ activeImage: i === currentIndex }"
    >
      <ng-container *ngIf="image.video; else imageThumbnail">
        <!-- Display video thumbnail preview -->
        <div class="video-thumbnail-container">
          <video class="video-size" [src]="image.thumbImage"></video>
          <mat-icon class="video-icon">play_circle_filled</mat-icon>
          <!-- Angular Material video icon -->
        </div>
      </ng-container>
      <ng-template #imageThumbnail>
        <!-- Display image thumbnail -->
        <img [src]="image.thumbImage" />
      </ng-template>
    </div>
  </div>
</div>
