import { Injectable } from '@angular/core';
import { ColorHEX } from '@utils/action';
import { Object3DUserData } from '@utils/shape';
import * as THREE from 'three';

@Injectable({
  providedIn: 'root'
})
export class UpdateShapeColorService {

  constructor( ) { }

  updateShapeColor(objectHit: any, selectedColor: THREE.ColorRepresentation){
    const updatedColor = new THREE.Color(selectedColor);
    (objectHit as any).material.color = updatedColor;
    this.updateObjectUserData(objectHit, selectedColor);
  }

  updateObjectUserData(mesh: any, selectedColor:THREE.ColorRepresentation) {
    if (selectedColor == ColorHEX.greencyan) {
      mesh.userData[Object3DUserData.colour] = ColorHEX.greencyan;
    } else if (selectedColor == ColorHEX.green) {
      mesh.userData[Object3DUserData.colour] = ColorHEX.green;
    } else if (selectedColor == ColorHEX.irisblue) {
      mesh.userData[Object3DUserData.colour] = ColorHEX.irisblue;
    } else if (selectedColor == ColorHEX.olivetone) {
      mesh.userData[Object3DUserData.colour] = ColorHEX.olivetone;
    } else if (selectedColor == ColorHEX.orange) {
      mesh.userData[Object3DUserData.colour] = ColorHEX.orange;
    } else if (selectedColor == ColorHEX.irisblue) {
      mesh.userData[Object3DUserData.colour] = ColorHEX.irisblue;
    } else if (selectedColor == ColorHEX.blue) {
      mesh.userData[Object3DUserData.colour] = ColorHEX.blue;
    } else if (selectedColor == ColorHEX.darkblue) {
      mesh.userData[Object3DUserData.colour] = ColorHEX.darkblue;
    }
  }
}
