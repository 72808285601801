import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { OrderService } from "src/app/services/order/order.service";
import {
  OrderPageDetail,
  OrderPageResponse,
  ErrorMessageSendEmail,
  OrderStatus,
  DialogSaveDataModel
} from "@models/order.model";
import { ProductBody } from "@models/product.model";
import { AuthService } from "../../services/auth/auth.service";
import { environment } from "src/environments/environment";
import { MatSnackBar } from "@angular/material/snack-bar";
import { GenericDialogComponent } from "../generic-dialog/generic-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PaymentStatus, RefundStatus } from '@models/payment.model';
import { DialogInvokingComponents } from "@models/generic-dialog.model";
import { finalize } from 'rxjs';

@Component({
  selector: "app-track-order",
  templateUrl: "./track-order.component.html",
  styleUrls: ["./track-order.component.scss"]
})
export class TrackOrderComponent implements OnInit {
  public orders: OrderPageDetail[] = [];
  public totalOrders = 0;
  public visibleOrders: OrderPageDetail[] = [];
  public paymentStatus = PaymentStatus;
  public orderStatus = OrderStatus;
  public refundStatus = RefundStatus;
  public returnReason!: string;
  public showReturnPopup = false;
  public currentOrder: OrderPageDetail | undefined;
  private currentPageIndex = environment.dynamicPagination.pageIndex;
  public showHelpDialog = false;
  public helpQuery = "";
  public isOkButtonEnabled = false;
  public returnForm: FormGroup;
  public loading: boolean = true;

  constructor(
    private orderService: OrderService,
    private router: Router,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private authService: AuthService
  ) {
    this.returnForm = this.formBuilder.group({
      feedback: ["", Validators.required]
    });
  }

  ngOnInit(): void {
    if (!this.authService.isUserLoggedIn) {
      this.router.navigate(["/login"]);
    } else {
      this.loadOrders();
    }
  }

  public loadOrders(): void {
    this.loading = true;
    this.orderService.getOrder(this.currentPageIndex)
    .pipe(
      finalize(()=> {
        this.loading = false;
      })
    )
    .subscribe({
      next: (response: OrderPageResponse) => {
        if (response && response.rows && response.rows.length > 0) {
          this.totalOrders = response.count;
          if (this.currentPageIndex === 0) {
            this.orders = response.rows;
          } else {
            this.orders = this.orders.concat(response.rows);
          }
          this.updateVisibleOrders();
        }
      },
      error: (error) => {
        throw error;
      }
    });
  }

  public loadMoreOrders(): void {
    this.currentPageIndex++;
    this.loadOrders();
  }

  public returnOrder(event: Event, order: OrderPageDetail): void {
    event.stopPropagation();
    this.currentOrder = order;

    if (order.orderStatus === OrderStatus.Delivered) {
      this.showReturnPopup = true;
      this.openDialogReturn();
    } else if (
      order.orderStatus !== OrderStatus.Delivered &&
      order.orderStatus !== OrderStatus.Dispatched
    ) {
      this.openDialog();
    }
  }

  public confirmReturn(savedData: DialogSaveDataModel): void {
    if (this.currentOrder) {
      this.returnReason = savedData.reason;
      this.orderService.returnOrder(this.currentOrder.id, this.returnReason).subscribe(response => {
        if (response) {
          this.showReturnPopup = false;
          this.loadOrders();
          this.snackBar.open(response.message, "Close", {
            duration: 3000
          });
        }
      })
    }
  }

  private confirmCancel(): void {
    if (this.currentOrder) {
      this.orderService.cancelOrder(this.currentOrder.id).subscribe({
        next:response => {
          this.loadOrders();
          this.snackBar.open(response.message, "Close", {
            duration: 3000
          });
        },
        error:error => {
          alert(error.error);
        }
      })
    }
  }

  private openDialog(): void {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      data: {
        componentName: DialogInvokingComponents.CancelOrder,
        title: "Cancel Order",
        content:
          "Order will be cancelled, and the amount will be refunded within 5-7 working days",
        firstBtn: "OK",
        secondBtn: "Cancel"
      },
      autoFocus:false,
      restoreFocus:false,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.confirmCancel();
      }
    });
  }

  public openDialogReturn(): void {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      data: {
        componentName: DialogInvokingComponents.ReturnOrder,
        title: "Return Order",
        content: "Please provide your feedback for returning this order:",
        inputs: [
          {
            controlName: 'reason',
            value: '',
            placeholder: 'Reason for Returning',
            type: 'text'
          }
        ],
        firstBtn: "Return Order",
        secondBtn: "Cancel"
      },
      autoFocus:false,
      restoreFocus:false,
    });
    dialogRef.componentInstance.saveClicked.subscribe((savedData: DialogSaveDataModel) => {
      if (savedData) {
        this.confirmReturn(savedData);
      }
    });
  }

  public isCancellableOrReturnOrder(order: OrderPageDetail): string {
    const status =
      order.orderStatus === OrderStatus.Delivered
        ? "Return"
        : ![
          OrderStatus.Dispatched,
          OrderStatus.Cancelled,
          OrderStatus.ReturnInitiated,
          OrderStatus.Returned,
          OrderStatus.PickupScheduled,
          OrderStatus.Pending // Exclude Pending status from cancellation
        ].includes(order.orderStatus as OrderStatus)
          ? "Cancel"
          : "";
    return status;
  }

  public showViewMoreButton(): boolean {
    return (
      (this.currentPageIndex + 1) * environment.dynamicPagination.pageSize <
      this.totalOrders
    );
  }

  public updateVisibleOrders(): void {
    let endIndex =
      (this.currentPageIndex + 1) * environment.dynamicPagination.pageSize;
    endIndex = Math.min(endIndex, this.orders.length);
    this.visibleOrders = this.orders.slice(0, endIndex);
  }

  public viewOrderDetails(order: OrderPageDetail): void {
    this.router.navigate(["/orderDetails", order.id]);
  }

  public getTotalItems(order: OrderPageDetail): number {
    let totalItems = 0;
    order.products.forEach((product: { quantity: number }) => {
      totalItems += product.quantity;
    });
    return totalItems;
  }

  public getTotalPrice(order: OrderPageDetail): number {
    let totalPrice = 0;
    order.products.forEach((product: ProductBody) => {
      if (
        product.discountPrice !== undefined &&
        product.quantity !== undefined
      ) {
        totalPrice += product.discountPrice * product.quantity;
      }
    });
    return totalPrice;
  }

  public getTotalItemsLeft(order: OrderPageDetail): number {
    let totalItemsLeft = 0;
    order.products.forEach((product: { quantity: number }, index: number) => {
      if (index !== 0) {
        totalItemsLeft += product.quantity;
      }
    });
    return totalItemsLeft;
  }

  public goBackToProducts(): void {
    this.router.navigate(["/home"]);
  }

  public openHelpDialog(event: Event, order: OrderPageDetail): void {
    event.stopPropagation();
    this.currentOrder = order;
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      data: {
        componentName: DialogInvokingComponents.HelpMessage,
        title: "PLEASE ENTER YOUR QUERY",
        inputs: [
          {
            label: "Message",
            value: this.helpQuery,
            placeholder: "Enter Your Query...",
            controlName: "helpRequest",
            type: "text"
          }
        ],
        firstBtn: "Submit",
        secondBtn: "Cancel"
      },
      autoFocus:false,
      restoreFocus:false,
    });

    dialogRef.componentInstance.saveClicked.subscribe((savedData: any) => {
      if (savedData && savedData["helpRequest"]) {
        this.helpQuery = savedData["helpRequest"];
        this.sendHelpRequest();
      }
    });
  }

  public sendHelpRequest(): void {
    const query = this.helpQuery.trim();
    if (query.length > 0) {
      const orderId: number | undefined = this.currentOrder?.id;
      if (orderId !== undefined) {
        const message = `${query}`;
        this.orderService.sendHelpRequest(message, orderId).subscribe({
          next: response => {
            this.snackBar.open(ErrorMessageSendEmail.MailSent, "Close", {
              duration: 3000
            });
            this.helpQuery = ""
          },
          error: err => {
            this.snackBar.open(ErrorMessageSendEmail.FailedRequest, "Close", {
              duration: 3000
            });
          }
        });
      }
    }
  }
}
