export interface MultipartPart {
  PartNumber: number;
  ETag: string;
}

export interface CompleteMultipartRequest {
  fileName: string;
  uploadId: string;
  parts: MultipartPart[];
}

export interface CompleteMultipartResponse {
  message: string;
}

export interface GeneratePresignedUrlRequest {
  fileName: string;
  uploadId: string;
  partNumbers: number[];
}

export enum FileUploadMessage {
  FailedInitiateMultipartUpload = "Failed to initiate multipart upload",
  FailedGetPresignedUrl = "Failed to get pre-signed url",
  FailedCompleteMultipartUpload = "Failed to complete multipart upload",
  FailedRemoveFromBucket = "Failed to remove from S3 bucket",
  FileDeleteSuccess = "File deleted successfully",
  FilesDeleteSuccess = "Files deleted successfully",
  MultipartUploadCompleted = "Multipart upload completed successfully"
}

export enum S3UploadCategoryName {
  RatingAndReview = "ratingAndReview"
}
