<div class="timeline" *ngIf="orderStatuses">
    <div class="timeline-track">
        <div *ngFor="let status of orderStatuses; let i = index" class="timeline-item" >
            
            <div class="timeline-label">{{ status.status }}</div>
            <div class="timeline-node" [ngClass]="status.color"></div>
            <div class="timeline-line" *ngIf="i < orderStatuses.length - 1"
                [ngClass]="orderStatuses[i + 1].color"></div>
            <div class="timeline-date">{{(status.time | date: 'd/MM/yyyy hh:mm a')}}</div>
        </div>
    </div>
</div>
