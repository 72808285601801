import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService } from "../auth/auth.service";
import { environment } from "../../../environments/environment";
import { BehaviorSubject, Observable, Subject, map, tap } from "rxjs";
import { ProductDetails } from "@models/product.model";
import { WishListItem } from "@models/cart.model";
import { LocalStorageService } from "../local-storage/local-storage.service";
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: "root"
})
export class WishlistService {
  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private authService: AuthService,
    private router: Router,
    private snackBar: MatSnackBar
  ) {}

  public addToWishList(wishlistBody: WishListItem): Observable<string> {
    return this.http
      .post<string>(
        `${environment.apiUrlBasePath}/wishlist/add`,
        wishlistBody
      )
      .pipe(
        tap(response => {
          if (response) {
            this.localStorageService.addItemToWishList(wishlistBody);
          }
        })
      );
  }

  public getWishListItems(): Observable<WishListItem[]> {
    return this.http.get<WishListItem[]>(
      `${environment.apiUrlBasePath}/wishlist/getWishListItems`
    );
  }

  public loadMyWishlistItems(): void {
    this.getWishListItems().subscribe(response => {
      const body: WishListItem[] = response.map(item => ({
        id: item.product?.id
      }));
      if (body.length) {
        this.localStorageService.setWishListItems(body);
      }
    });
  }

  public deleteFromMyWishlist(productID: number): Observable<string> {
    return this.http
      .delete<string>(
        `${environment.apiUrlBasePath}/wishlist/delete/${productID}`
      )
      .pipe(
        tap(response => {
          if (response) {
            this.localStorageService.removeFromWishlist(productID);
          }
        })
      );
  }

  public isInWishlist(product: ProductDetails): boolean {
    const getLocalsetWishlistItem = this.localStorageService.getWishListItems();
    return getLocalsetWishlistItem.some(item => item.id === product?.id);
  }

  public addToFavorite(product: any): void {
    const id: WishListItem = {
      id: product.id 
    }
    if (this.authService.isUserLoggedIn) {
      if (this.isInWishlist(product)) {
        this.deleteFromMyWishlist(product?.id).subscribe(response => {
          this.snackBar.open(response, 'Close', { duration: 3000 });
        });
      } else {
        this.addToWishList(id).subscribe(response => {
          this.snackBar.open(response, 'Close', { duration: 3000 });
        });
      }
    } else {
      this.router.navigate(['/login']);
    }
  }
}
