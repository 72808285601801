import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CartItemService } from '../../services/cart-item/cart-item.service';
import { CartItem } from '@models/cart.model';
import { ProductDetails } from '@models/product.model';
import { LocalStorageService } from '../../services/local-storage/local-storage.service';
import { Router } from '@angular/router';
import { GenericDialogComponent } from '../../shared/generic-dialog/generic-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DialogInvokingComponents } from '@models/generic-dialog.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { forkJoin } from 'rxjs';
import { appConfig } from "../../../environments/app.config";

@Component({
  selector: 'app-right-navbar',
  templateUrl: './right-navbar.component.html',
  styleUrls: ['./right-navbar.component.scss']
})
export class RightNavbarComponent implements OnInit {

  @Input() isOpen: boolean = false;
  @Output() closeEvent = new EventEmitter<void>();
  public cartItems: CartItem[] = [];
  public products: ProductDetails[] = [];
  public cartId!: number;
  public totalMRP!: number;
  public totalDiscount!: number;
  public totalAmount!: number;
  public minQuantity = appConfig.minProductQuantity;
  public maxQuantity = appConfig.maxProductQuantity;
  public isCheckoutEnabled: boolean = false;
  public minOrderValue = appConfig.minOrderValue;

  constructor(private dialog: MatDialog, private snackBar: MatSnackBar, private cartService: CartItemService, private cartItemService: CartItemService,private localStorageService: LocalStorageService, private router: Router) {}

  ngOnInit(): void {
    this.loadCartItems();
  }

  public loadCartItems(): void {
    this.cartItemService.getCartItems().subscribe(items => {
      this.cartItems = items;
      this.products = this.cartItems.map((item) => item.product!);
      if (this.products.length === 0) {
        this.closeNavbar();
        return;
      }
      this.calculateTotalValues();
      const inActiveIds = this.products.filter((product) => !product.isActive) // Filter items where isActive is false
      .map((item: { id: number; }) => item.id);
      if(inActiveIds.length) {
        this.openDialogUnavailable(inActiveIds);
      }
    });
  }

  private openDialogUnavailable(inActiveIds: number[]): void {

    const dialogRef = this.dialog.open(GenericDialogComponent, {
      data: {
        componentName: DialogInvokingComponents.RemoveFromCart,
        title: "Remove from Cart",
        content:
          "Products will be removed from the cart due to being unavailable!",
        firstBtn: "Remove"
      },
      autoFocus:false,
      restoreFocus:false,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const removeObservables = inActiveIds.map(id => 
          this.cartItemService.removeCartItem(id)
        );

        // Use forkJoin to wait for all observables to complete
        forkJoin(removeObservables).subscribe(() => {
          this.loadCartItems();
        });
      }
    });
  }

  closeNavbar() {
    this.isOpen = false;
    this.closeEvent.emit();
  }

  public onDeleteCartItem(item: ProductDetails): void {
    this.openDialog(item);
  }

  private openDialog(item: ProductDetails): void {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      data: {
        componentName: DialogInvokingComponents.RemoveFromCart,
        title: "Remove from Cart",
        image: item.images?.imagesUrl[0],
        content: "Are you sure you want to remove this item from cart?",
        firstBtn: "Remove"
      },
      autoFocus:false,
      restoreFocus:false,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) this.removeProductFromCart(item.id);
    });
  }

  private openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      horizontalPosition: "center",
      verticalPosition: "bottom",
      duration: 2000
    });
  }

  private calculateTotalValues(): void {
    this.cartItemService.calculateTotalMRP(this.cartItems);
    this.totalMRP = this.cartItemService.totalMRP;
    this.totalDiscount = this.cartItemService.totalDiscount;
    this.totalAmount = this.cartItemService.totalAmount;
    this.isCheckoutEnabled = this.totalAmount >= appConfig.minOrderValue;
  }

  public getProductQtyInCart (productId: number): number {
    return this.localStorageService.getCartedQuantity(productId);
  }
  
  private updateCartQuantity(productId: number, quantity: number): void {
    if (quantity < 1) {
      this.removeProductFromCart(productId);
    } else if (quantity <= 99) {
      this.updateProductQuantityInCart(productId, quantity);
    }
  }

  private removeProductFromCart(productId: number): void {
    this.cartService.removeCartItem(productId).subscribe(() => {
      this.loadCartItems();
    });
  }

  private updateProductQuantityInCart(productId: number, quantity: number): void {
    this.cartService.updateCartQuantity(productId, quantity).subscribe(() => {
      this.loadCartItems();
    });
  }

  public onClick(value: number, productId: number): void {
    const quantityInCart = this.getProductQtyInCart(productId);
    const newQuantity = quantityInCart + value;
    this.updateCartQuantity(productId, newQuantity);
    this.loadCartItems();
  }

  public onBlur(event: Event, productId: number): void {
    const inputValue = (event.target as HTMLInputElement).value.trim();
    if (!inputValue) {
      (event.target as HTMLInputElement).value = "1";
      const quantity = parseInt((event.target as HTMLInputElement).value, 10);
      this.updateCartQuantity(productId, quantity);
    }
  }

  public onInputChange(event: Event, productId: number): void {
    let inputValue = (event.target as HTMLInputElement).value.trim();
    let quantity = parseInt(inputValue, 10);

    if (isNaN(quantity)) {
      return;
    } else if (quantity < 1) {
      (event.target as HTMLInputElement).value = "1";
      quantity = parseInt((event.target as HTMLInputElement).value, 10);
    } else if (inputValue.length > 2) {
      inputValue = inputValue.slice(0, 2);
      (event.target as HTMLInputElement).value = inputValue;
      return;
    }

    this.updateCartQuantity(productId, quantity);
  }

  public onClickProduct(productId: number): void {
    this.router.navigate([`products/${productId}`]);
  }

  public onCheckout(): void {
    this.router.navigate(["checkout"]);
  }

}
