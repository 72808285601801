import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ComponentInteractionSrevice } from 'src/app/services/component-interaction/component-interaction.service';
import { ShapeGeneratorService } from 'src/app/services/shape-generator/shape-generator.service';
import {
  AnimationConfig,
  CanvasConfig,
} from 'src/app/shared/shape-selector/shape-selector.model';
import { ColorCodes } from '@utils/color-constants';
import { AvailableShapes } from '@utils/shape-facetype';
import { ShapeType } from '@utils/shape-type';
import * as THREE from 'three';
import { Mesh } from 'three';

@Component({
  selector: 'shape-selector',
  templateUrl: './shape-selector.component.html',
  styleUrls: ['./shape-selector.component.scss'],
})
export class ShapeSelectorComponent implements OnInit, AfterViewInit {
  @ViewChild('shapeSelector', { static: true })
  shapeSelector: ElementRef | any;

  private renderer = new THREE.WebGLRenderer({
    alpha: true,
    preserveDrawingBuffer: true,
  });

  private scene: THREE.Scene;
  private camera: THREE.PerspectiveCamera;
  private shapes: THREE.Mesh[] = [];
  private availableShapes = AvailableShapes;
  private raycaster = new THREE.Raycaster();
  private selectedShape: Mesh = new Mesh();
  private cube: any;
  private curvedCube: any;
  private curvedCube2: any;
  private halfCylinder: any;
  private prism: any;
  private squarePyramid: any;
  private cone: any;
  private cylinder: any;
  private hemiSphere: any;
  private selectedColour: THREE.ColorRepresentation = '#117A65';

  constructor(
    private shapeGeneratorService: ShapeGeneratorService,
    private componentInteractionSrv: ComponentInteractionSrevice
  ) {
    this.scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera(
      CanvasConfig.cameraConfig.fov,
      CanvasConfig.RenderConfig.width / CanvasConfig.RenderConfig.height // width by height gives proper angle for camera
    );
  }

  ngOnInit(): void {
    this.componentInteractionSrv.getSelectedColor().subscribe((color) => {
      this.scene.remove(this.cube);
      this.scene.remove(this.curvedCube);
      this.scene.remove(this.curvedCube2);
      this.scene.remove(this.halfCylinder);
      this.scene.remove(this.prism);
      this.scene.remove(this.squarePyramid);
      this.scene.remove(this.cylinder);
      this.scene.remove(this.cone);
      this.scene.remove(this.hemiSphere)

      this.shapes.length = 0;
      this.selectedColour = color;
      this.generateShapesForSelection();
    });
  }

  ngAfterViewInit(): void {
    this.canvasConfiguration();
  }

  @HostListener('click', ['$event'])
  onCanvasClick(event: MouseEvent): void {
    // Calculate normalized device coordinates and perform raycasting
    const canvasRect = this.renderer.domElement.getBoundingClientRect();
    const x = ((event.clientX - canvasRect.left) / canvasRect.width) * 2 - 1;
    const y = -((event.clientY - canvasRect.top) / canvasRect.height) * 2 + 1;

    // Update the raycaster with the mouse position
    this.raycaster.setFromCamera(new THREE.Vector2(x, y), this.camera);

    // Perform raycasting to check for intersections
    const intersects = this.raycaster.intersectObjects(this.shapes);

    let clickedShape;
    if (intersects.length > 0) {
      for (let i = 0; i < intersects.length; i++) {
        if (intersects[i].object.name !== '') {
          clickedShape = intersects[i].object as Mesh;
        }
      }
      this.changeShapeColor(clickedShape);
    }
  }

  private canvasConfiguration(): void {
    this.renderer.setSize(
      CanvasConfig.RenderConfig.width,
      CanvasConfig.RenderConfig.height
    );
    this.camera.position.z = CanvasConfig.RenderConfig.cameraPositionZ;
    this.shapeSelector.nativeElement.appendChild(this.renderer.domElement);
    const ambientLight = new THREE.AmbientLight(ColorCodes.white, 1); // 1 is the intensity of the light.
    this.scene.add(ambientLight);
    this.scene.add(this.camera);
    this.generateShapesForSelection();
    this.animate();
  }

  private animate(): void {
    requestAnimationFrame(() => this.animate());
    this.renderer.render(this.scene, this.camera);
    this.shapes.forEach((shape) => {
      if (
        shape.name !== AvailableShapes.Cone &&
        shape.name !== AvailableShapes.Cylinder
      ) {
        shape.rotation.y += AnimationConfig.rotation.y;
        shape.rotation.x += AnimationConfig.rotation.x;
        shape.rotation.z += AnimationConfig.rotation.z;
      } else {
        shape.rotation.y += AnimationConfig.rotation.y;
        shape.rotation.x += AnimationConfig.rotation.x;
        shape.rotation.z += AnimationConfig.rotation.z;
      }
    });
  }

  private generateShapesForSelection() {
    for (const shape in this.availableShapes) {
      switch (shape) {
        case this.availableShapes.HemiSphere:
          this.generateSemiCircle();
          break;
        case this.availableShapes.Cube:
          this.generateCube();
          break;
        case this.availableShapes.CurvedCube:
          this.generateCurvedCube();
          break;
        case this.availableShapes.CurvedCube2:
          this.generateCurvedCube2();
          break;
        case this.availableShapes.HalfCylinder:
          this.generateHalfCylinder();
          break;
        case this.availableShapes.Prism:
          this.generatePrism();
          break;
        case this.availableShapes.SquarePyramid:
          this.generateSquarePyramid();
          break;
        case this.availableShapes.Cone:
          this.generateCone();
          break;
        case this.availableShapes.Cylinder:
          this.generateCylinder();
          break;
        
      }
    }
  }

  // Generates Cube
  private generateCube(): void {
    this.cube = this.shapeGeneratorService.generateShape(
      this.availableShapes.Cube,
      this.selectedColour,
      true
    );
    this.cube.name = this.availableShapes.Cube;
    this.selectedShape = this.cube;
    this.shapes.push(this.cube);
    this.scene.add(this.cube);
  }

  // Generates curved cube
  private generateCurvedCube(): void {
    this.curvedCube = this.shapeGeneratorService.generateShape(
      this.availableShapes.CurvedCube,
      this.selectedColour,
      true
    );
    this.curvedCube.name = this.availableShapes.CurvedCube;
    this.shapes.push(this.curvedCube);
    this.scene.add(this.curvedCube);
  }

  private generateCurvedCube2(): void {
    this.curvedCube2 = this.shapeGeneratorService.generateShape(
      this.availableShapes.CurvedCube2,
      this.selectedColour,
      true
    );
    this.curvedCube2.name = this.availableShapes.CurvedCube2;
    this.shapes.push(this.curvedCube2);
    this.scene.add(this.curvedCube2);
  }

  private generateHalfCylinder(): void {
    this.halfCylinder = this.shapeGeneratorService.generateShape(
      this.availableShapes.HalfCylinder,
      this.selectedColour,
      true
    );
    this.halfCylinder.name = this.availableShapes.HalfCylinder;
    this.shapes.push(this.halfCylinder);
    this.scene.add(this.halfCylinder);
  }

  private generatePrism(): void {
    this.prism = this.shapeGeneratorService.generateShape(
      this.availableShapes.Prism,
      this.selectedColour,
      true
    );
    this.prism.name = this.availableShapes.Prism;
    this.shapes.push(this.prism);
    this.scene.add(this.prism);
  }

  private generateSquarePyramid() {
    this.squarePyramid = this.shapeGeneratorService.generateShape(
      this.availableShapes.SquarePyramid,
      this.selectedColour,
      true
    );
    this.squarePyramid.name = this.availableShapes.SquarePyramid;
    this.shapes.push(this.squarePyramid);
    this.scene.add(this.squarePyramid);
  }

  private generateCone() {
    this.cone = this.shapeGeneratorService.generateShape(
      this.availableShapes.Cone,
      this.selectedColour,
      true
    );
    this.cone.name = this.availableShapes.Cone;
    this.shapes.push(this.cone);
    this.scene.add(this.cone);
  }

  private generateCylinder() {
    this.cylinder = this.shapeGeneratorService.generateShape(
      this.availableShapes.Cylinder,
      this.selectedColour,
      true
    );
    this.cylinder.name = this.availableShapes.Cylinder;
    this.shapes.push(this.cylinder);
    this.scene.add(this.cylinder);
  }

  private generateSemiCircle() {
    this.hemiSphere = this.shapeGeneratorService.generateShape(
      this.availableShapes.HemiSphere,
      this.selectedColour,
      true
    );
    this.hemiSphere.name = this.availableShapes.HemiSphere;
    this.shapes.push(this.hemiSphere);
    this.scene.add(this.hemiSphere);
  }

  private changeShapeColor(shape: any): void {
    if (shape !== undefined) {
      const mesh =
        shape.type === ShapeType.Mesh ? shape : (shape.parent as Mesh);
      if (this.selectedShape) {
        const prevMaterial = this.selectedShape
          .material as THREE.MeshStandardMaterial;
        prevMaterial.transparent = true;
        prevMaterial.opacity = CanvasConfig.transparentOpacity;
      }

      const material = mesh.material as THREE.MeshStandardMaterial;

      material.transparent = false;
      material.opacity = CanvasConfig.solidOpacity;
      this.selectedShape = mesh;
      this.componentInteractionSrv.setSelectedShape(this.selectedShape.name);
    }
  }
}
