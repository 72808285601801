import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from "@angular/router";
import { BehaviorSubject, Observable, catchError, map, of } from "rxjs";
import { environment } from "src/environments/environment";
import { LocalStorageService } from "../local-storage/local-storage.service";
import { AdminService } from '../admin/admin.service';

@Injectable({
  providedIn: "root"
})
export class AuthService {
  public isLoggedIn = new BehaviorSubject<boolean>(false);
  public permissionData: string[] = [];

  constructor( private router: Router, 
               private http: HttpClient, 
               private localStorageService: LocalStorageService, 
               private adminService: AdminService ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
      switch (window.location.pathname) {
        case '/admin/structureReview':
          return true;
        case '/admin/designer':
          return this.isAdminLoggedIn() ? true : this.router.parseUrl("/admin/login");
        default:
          return this.isUserLoggedIn ? true : this.router.parseUrl("/login");
      }      
  }

  public hasPermission(permission: string[]): Observable<boolean> {
    return this.adminService.setUserPermissions().pipe(
      map((permissions:string[]) => 
      permission.some(permission => permissions.includes(permission))),
      catchError((error) => {
        return of(false); // or handle error accordingly
      })
    );
  }

  public isAdminLoggedIn(): boolean {
    return !!this.adminToken;
  }

  public get isUserLoggedIn(): boolean {
    return !!this.userToken;
  }

  private get userToken(): string | null {
    return localStorage.getItem("token");
  }

  private get adminToken(): string | null {
    return localStorage.getItem("adminToken");
  }

  public removeUserToken(): void {
    localStorage.removeItem("token");
  }

  public removeAdminToken(): void {
    localStorage.removeItem("adminToken");
  }

  public logout(isAdmin: boolean = false): void {
    if (isAdmin) {
      localStorage.removeItem("adminToken");
      this.router.navigate(["/admin/login"]);
    } else {
      localStorage.removeItem("token");
      this.localStorageService.removeAllCartItems();
      sessionStorage.clear();
      this.router.navigate(["/home"]);
    }
  }
}
