
<div class="carousel-container" (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()">
  <div class="carousel-image-container">
    <div *ngFor="let image of images; let i = index" 
      class="carousel-image" 
      [class.active]="i === currentIndex"
      [class.enter]="images.length > 2 && i === (currentIndex + 1) % images.length"
      [class.leave]="images.length > 2 && i === (currentIndex - 1 + images.length) % images.length">
      <a [href]="image.link">
        <img [src]="image.image" alt="Image {{i + 1}}">
      </a>
    </div>
  
    
    <button mat-icon-button class="carousel-button prev" (click)="showPrevImage()" *ngIf="images.length > 1">
      <mat-icon>chevron_left</mat-icon>
    </button>
    <button mat-icon-button class="carousel-button next" (click)="showNextImage()" *ngIf="images.length > 1">
      <mat-icon>chevron_right</mat-icon>
    </button>
    
  </div>
  <div class="carousel-dots" *ngIf="images.length > 1">
    <span *ngFor="let image of images; let i = index"
          class="carousel-dot"
          [class.active]="i === currentIndex"
          (click)="goToImage(i)">
    </span>
  </div>
</div>
