import { Injectable } from '@angular/core';
import { shapeProperty } from 'src/app/shared/models/shape.properties.model';
import { AvailableShapes } from '@utils/shape-facetype';
import * as THREE from 'three';
import { ColorRepresentation, Mesh } from 'three';
import { OutlineService } from '../shape-helper/outline.service';

@Injectable({
  providedIn: 'root'
})
export class ShapeGeneratorService {

  constructor(private outlineService: OutlineService,) { }
/*
  This is the generic method which creates the shape for all the type
*/
  public generateShape(type: string, color: ColorRepresentation, isForSelector: boolean, position?: THREE.Vector3): Mesh {

    const property = shapeProperty(type, color, isForSelector);    
    const shape = new THREE.Mesh(
      property.geometry,
      property.material
    );

    let shapecap
    if(property.capgeometry){
       shapecap = new THREE.Mesh(
        property.capgeometry,
        property.material
      )
    shapecap.name = AvailableShapes.HemiSphere;
      shape.add(shapecap);
      this.outlineService.addOutline(shapecap,type, position)
    }

    this.outlineService.addOutline(shape, type, position);
    if (property?.offSetCoordinates) {
      shape.position.set(
        property.offSetCoordinates.x,
        property.offSetCoordinates.y,
        property.offSetCoordinates.z
      );
    }
    return shape;
  }
}
