import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { GenericDialogComponent } from "src/app/shared/generic-dialog/generic-dialog.component";

@Injectable({
  providedIn: "root"
})
export class DialogService {
  private dialogRegistry: Map<MatDialogRef<GenericDialogComponent>, boolean> = new Map();

  constructor(private dialog: MatDialog) { }

  public openDialog<T>(dialogData: T, width: string = "70%"): MatDialogRef<GenericDialogComponent> {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      data: dialogData,
      width: width,
      autoFocus: false,
      restoreFocus: false
    });

    // Add dialog to the stack with default close-all flag set to true
    this.dialogRegistry.set(dialogRef, true);

    dialogRef.afterClosed().subscribe(result => {
      this.handleDialogResult(dialogRef, result);
    });

    return dialogRef;
  }

  private handleDialogResult<T>(dialogRef: MatDialogRef<GenericDialogComponent>, result: T): void {
    // Check if we should close other dialogs based on the dialog's flag
    if (this.dialogRegistry.get(dialogRef) !== false) {
      this.closeAllDialogs(result);
    }
  }

  public closeDialog<T>(dialogRef: MatDialogRef<GenericDialogComponent>, data?: T): void {
    dialogRef.close(data);
    this.dialogRegistry.delete(dialogRef);
  }

  private closeAllDialogs<T>(data?: T): void {
    this.dialogRegistry.forEach((_, dialogRef) => this.closeDialog(dialogRef, data));
  }

  // Optional method to set whether closing all dialogs is required for a particular dialog
  public setCloseAllFlag(dialogRef: MatDialogRef<GenericDialogComponent>, flag: boolean): void {
    if (this.dialogRegistry.has(dialogRef)) {
      this.dialogRegistry.set(dialogRef, flag);
    }
  }
}
