import { EventEmitter, Injectable } from '@angular/core';
import * as THREE from 'three';
import { ShapeType } from '../utils/shape-type';
import { AlteredShapeConfiguration } from '../utils/shape';
import { StructureService } from './services/structure/structure.service';
import { NullableStructureInstance } from "@models/structure.model";
import { ColorCodes } from "../utils/color-constants";
import { ComponentInteractionSrevice } from './services/component-interaction/component-interaction.service';
import { InteractionService } from './shared/helpers/interaction.service';

@Injectable({
  providedIn: 'root'
})
export class LoadStructureService {
  private scene!: THREE.Scene;
  private object: THREE.Object3D[] = [];
  public meshCreated = new EventEmitter<THREE.Mesh>();
  public clearStructure = new EventEmitter();
  public objectLength = new EventEmitter();
  public structureDetails!: NullableStructureInstance;

  constructor(private interactionService: InteractionService, private structureService: StructureService,private componentInteractionSrv: ComponentInteractionSrevice) {
  }

  /*
  any because object has different type for different shape and also depends on how we save.
*/

  public loadStructure(structure: NullableStructureInstance, object: THREE.Object3D[] = [], scene: THREE.Scene): void {
    this.object = object;
    this.scene = scene;
    this.structureDetails = structure;
    this.clearStructure.emit();

    this.structureService.fetchStructureData(structure!.structureData!)
      .subscribe({
        next: data => {
          if (Array.isArray(data)) {
            data.forEach(object => {
              if (object?.object?.type === 'Mesh') {
                this.createMesh(object, true, "", false);
              } else {
                throw new Error;
              }
            });
          } else {
            throw new Error;
          }
        },
        error: error => {
          throw error;
        }
      })
  }

  public get structureData() {
    return this.structureDetails;
  }

  public createMesh(
    object: any,
    ishavingoutline: boolean,
    selectedColour: THREE.ColorRepresentation | string,
    block: boolean
  ): THREE.Mesh | undefined {
    if (!object.geometries || !object.materials) {
      throw Error("Invalid object data: Missing geometries or materials.");
    }

    const geometries = Array.isArray(object.geometries) ? object.geometries : [object.geometries];
    const materials = Array.isArray(object.materials) ? object.materials : [object.materials];
    const texturesData = Array.isArray(object.images) ? object.images : [object.images];

    let mesh: THREE.Mesh | undefined;
    const textureLoader = new THREE.TextureLoader();

    geometries.forEach((geometryData: any, index: number) => {

      const materialData = materials[index];
      let geometry: THREE.BufferGeometry | undefined;
      let materialArray: THREE.Material[] | THREE.Material = [];

      // Use the helper function to create materials
      materialArray = this.createMaterials(texturesData, textureLoader, selectedColour, materialData, block);

      switch (geometryData.type) {
        case ShapeType.Geometry.BoxGeometry:
          geometry = new THREE.BoxGeometry(
            geometryData.width,
            geometryData.height,
            geometryData.depth
          );
          geometry.center();
          break;

        case ShapeType.Geometry.SphereGeometry:
          geometry = new THREE.SphereGeometry(
            geometryData.radius,
            geometryData.widthSegments,
            geometryData.heightSegments,
            geometryData.phiStart,
            geometryData.phiLength,
            geometryData.thetaStart,
            geometryData.thetaLength
          );
          geometry.center();

          // Example of extra position adjustment for SphereGeometry
          if (object.object.userData && object.object.userData.rotation) {
            geometry.rotateX(object.object.userData.rotation.x);
          }
          break;

        case ShapeType.Geometry.CircleGeometry:
          geometry = new THREE.CircleGeometry(
            geometryData.radius,
            geometryData.segments,
            geometryData.thetaStart,
            geometryData.thetaLength
          );
          break;

        case ShapeType.Geometry.ConeGeometry:
          geometry = new THREE.ConeGeometry(
            geometryData.radius,
            geometryData.height,
            geometryData.radialSegments
          );
          break;

        case ShapeType.Geometry.CylinderGeometry:
          geometry = new THREE.CylinderGeometry(
            geometryData.radiusTop,
            geometryData.radiusBottom,
            geometryData.height,
            geometryData.radialSegments,
            geometryData.heightSegments,
            false,
            geometryData.thetaStart,
            geometryData.thetaLength
          );
          geometry.center();
          break;

        case ShapeType.Geometry.ExtrudeGeometry:
          const shape = new THREE.Shape();
          const shapesData = object.shapes || [];
          shapesData.forEach((shapeData: any) => {
            const path = new THREE.Path();
            shapeData.curves.forEach((curveData: any) => {
              if (curveData.type === 'EllipseCurve') {
                path.absellipse(
                  curveData.aX, curveData.aY,
                  curveData.xRadius, curveData.yRadius,
                  curveData.aStartAngle, curveData.aEndAngle,
                  curveData.aClockwise,
                  curveData.aRotation || 0
                );
              } else if (curveData.type === 'LineCurve') {
                path.lineTo(curveData.v2[0], curveData.v2[1]);
              }
            });
            shape.add(path);
          });
          geometry = new THREE.ExtrudeGeometry(shape, geometryData.options);
          geometry.center();
          break;

        default:
          return;
      }

      // Create the mesh and apply the material(s)
      if (geometry) {
        const finalMaterial = Array.isArray(materialArray) ? materialArray : materialArray;
        mesh = new THREE.Mesh(geometry, finalMaterial);

        // Set initial position, rotation, and other properties
        mesh.position.set(
          object.object.userData?.initialPosition?.x || 0,
          object.object.userData?.initialPosition?.y || 0,
          object.object.userData?.initialPosition?.z || 0
        );

        // Handle rotation if any
        if (object.object.userData?.isTilted) {
          mesh.rotation.set(
            object.object.userData?.rotation?.x,
            object.object.userData?.rotation?.y,
            object.object.userData?.rotation?.z
          );
        }

        if(!block) {
          if (geometry.type === 'ConeGeometry') {
            mesh.rotateY(object.object.userData.rotation?.y);
          }
        }

        // Add outline if needed
        if (ishavingoutline) {
          if (geometry instanceof THREE.BoxGeometry) {
            const edges = new THREE.EdgesGeometry(mesh.geometry);
            const lineMaterial = new THREE.LineBasicMaterial({ color: Number(ColorCodes.yellow) });
            const lineSegments = new THREE.LineSegments(edges, lineMaterial);
            mesh.add(lineSegments);
          } else {
            const edgesGeometry = new THREE.EdgesGeometry(geometry, AlteredShapeConfiguration.standardThreshHoldAngle);
            const edgesMaterial = new THREE.LineBasicMaterial({ color: Number(ColorCodes.yellow) });
            const edges = new THREE.LineSegments(edgesGeometry, edgesMaterial);
            mesh.add(edges);
          }
        }

        // Set the userData and name for the mesh
        mesh.userData = {
          ...object.object.userData,
          initialPosition: object.object.userData?.initialPosition ? this.toVector3(object.object.userData.initialPosition) : undefined,
          initialMaterial: object.object.userData?.initialMaterial ? this.toMaterial(object.object.userData.initialMaterial) : undefined,
        };
        mesh.name = object.object.name;
      }
    });
    this.object?.push(mesh!);
    this.scene?.add(mesh!);
    this.objectLength.emit(this.object.length);
    return mesh;
  }

  // Helper function for creating materials
  private createMaterials(
    texturesData: any[],
    textureLoader: THREE.TextureLoader,
    selectedColour: THREE.ColorRepresentation | string,
    materialData: any,
    block: boolean
  ): THREE.Material[] | THREE.Material {
    const color = selectedColour || "#117A65";
    const materialArray: THREE.Material[] = [];
    let material: THREE.Material | undefined;

    if(!block) {
      material = new THREE.MeshBasicMaterial({
        color: materialData.color || "#117A65",
        side: THREE.DoubleSide,
        transparent: false
      });
    } else {
      texturesData.forEach((textured: any) => {
        if (textured) {
          const texture = textureLoader.load(textured.url);
          materialArray.push(new THREE.MeshBasicMaterial({
            map: texture,
            color: selectedColour as THREE.ColorRepresentation,
            transparent: true,
            side: THREE.DoubleSide
          }));
        } else {
          material = new THREE.MeshBasicMaterial({
            color: color as THREE.ColorRepresentation,
            side: THREE.DoubleSide,
            transparent: false
          });
        }
      });
    }
  

    return materialArray.length > 0 ? materialArray : (material || new THREE.MeshBasicMaterial({ color: "#117A65" }));
  }

  public getObject() {
    return this.object;
  }

  private toVector3(position: { x: number; y: number; z: number }): THREE.Vector3 {
    return new THREE.Vector3(position.x, position.y, position.z);
  }

  private toMaterial(materialData: { type: string; color: number }): THREE.Material {
    switch (materialData.type) {
      case 'MeshBasicMaterial':
        return new THREE.MeshBasicMaterial({  color: materialData.color || 0xffffff , side: THREE.DoubleSide, transparent: false});
      // Add cases for other material types if needed
      default:
        return new THREE.MeshBasicMaterial({  color: materialData.color || 0xffffff , side: THREE.DoubleSide, transparent: false}); // Default fallback
    }
  }

}
