export const AvailableShapes = {
  Cube: 'Cube',
  CurvedCube: 'CurvedCube',
  CurvedCube2: 'CurvedCube2',
  HalfCylinder: 'HalfCylinder',
  Prism: 'Prism',
  Plane: 'plane',
  SquarePyramid: 'SquarePyramid',
  Cone: 'Cone',
  Cylinder: 'Cylinder',
  HemiSphere: 'HemiSphere',
  Outline: 'Outline'
};

export function setFaceTypeForShape(shapeName: string): Record<string, string> {
  if (shapeName === AvailableShapes.CurvedCube) {
    return {
      Top: 'Curved',
      Bottom: 'Plane',
      Front: 'Curved',
      Back: 'Plane',
      Right: 'Plane',
      Left: 'Plane'
    };
  } else if (shapeName === AvailableShapes.CurvedCube2) {
    return {
      Top: 'Plane',
      Bottom: 'Plane',
      Front: 'Curved',
      Back: 'Plane',
      Right: 'Plane',
      Left: 'Plane'
    };
  } else if (shapeName === AvailableShapes.Cube) {
    return {
      Top: 'Plane',
      Bottom: 'Plane',
      Front: 'Plane',
      Back: 'Plane',
      Right: 'Plane',
      Left: 'Plane'
    };
  } else if (shapeName === AvailableShapes.HalfCylinder) {
    return {
      Top: 'Curved',
      Bottom: 'Plane',
      Front: 'Curved',
      Back: 'Curved',
      Right: 'Curved',
      Left: 'Curved'
    }
  } else if (shapeName === AvailableShapes.Prism) {
    return {
      Top: 'Curved',
      Bottom: 'Plane',
      Front: 'Plane',
      Back: 'Plane',
      Right: 'Plane',
      Left: 'Plane'
    }
  } else if (shapeName === AvailableShapes.SquarePyramid) {
    return {
      Top: 'Curved',
      Bottom: 'Plane',
      Front: 'Curved',
      Back: 'Curved',
      Right: 'Curved',
      Left: 'Curved'
    }
  }
  else if (shapeName === AvailableShapes.Cone) {
    return {
      Top: 'Curved',
      Bottom: 'Plane',
      Front: 'Curved',
      Back: 'Curved',
      Right: 'Curved',
      Left: 'Curved'
    }
  }
  else if (shapeName === AvailableShapes.Cylinder) {
    return {
      Top: 'Plane',
      Bottom: 'Plane',
      Front: 'Curved',
      Back: 'Curved',
      Right: 'Curved',
      Left: 'Curved'
    }
  } else if (shapeName === AvailableShapes.HemiSphere) {
    return {
      Top: 'Curved',
      Bottom: 'Plane',
      Front: 'Curved',
      Back: 'Curved',
      Right: 'Curved',
      Left: 'Curved'
    }
  }
  return {};
}

export const tiltedShapeConfiguration = {
  offsetXforRightAndLeftFace: 18,
  rotationforRightAndLeftFace: 2.02,
  offsetX: 8.5,
  offsetY: 18,
  defaultOffsetY: 9
}
