<div class="right-navbar" [class.open]="isOpen">
    <mat-icon (click)="closeNavbar()" class="close-icon">close</mat-icon>
    <div class="wrapper">
        <div class="container" *ngIf="products.length">
          <div class="left-section">
            <mat-card *ngFor="let product of products">
              <mat-card-content class="item-content">
                <div class="item">
                  <div class="item-description">
                    <div class="item-image-container">
                      <div class="image" (click)="onClickProduct(product.id)">
                        <img
                          *ngIf="product.images; else placeholder"
                          [src]="product.images.imagesUrl[0]"
                          alt="Item Image"
                          
                        />
                     
                        <ng-template #placeholder>
                          <div class="image-placeholder"></div>
                        </ng-template>
                      </div>
                    </div>
      
                    <div class="item-detail">
                      <b>
                        {{ product.name }}
                      </b>
                      <p class="description">{{ product.description }}</p>
                      <span *ngIf="product.discountPrice; else noDiscount"
                        ><s>₹ {{ product.price }}</s> &nbsp;₹
                        {{ product.discountPrice }}</span
                      >
                      <ng-template #noDiscount>
                        <span>₹ {{ product.price }}</span>
                      </ng-template>
                      <br />
                      <p class="return">14 days return available<br /><br /></p>
                      <div class="unavailable" *ngIf="!product.isActive">
                      <p>Product is currently unavailable.</p>
                     </div>
                    </div>
                  </div>
                  <div class="btn-quantity">
                    <div>
                      <mat-icon (click)="onDeleteCartItem(product)" class="delete-btn">
                      delete
                      </mat-icon>
                    </div>
                    <div class="quantity-count">
                      <span
                        ><button
                          class="decrement"
                          (click)="onClick(-1, product.id)"
                          [disabled]="getProductQtyInCart(product.id) <= minQuantity"
                        >
                          -
                        </button></span
                      >
                      <input
                        type="number"
                        class="quantity"
                        [value]="getProductQtyInCart(product.id)"
                        (input)="onInputChange($event,product.id)"
                        (blur)="onBlur($event, product.id)"
                      />
                      <span
                        ><button
                          class="increment"
                          (click)="onClick(1, product.id)"
                          [disabled]="getProductQtyInCart(product.id) >= maxQuantity"
                        >
                          +
                        </button></span
                      >
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
      
          <div class="right-section" *ngIf="products.length">
            <div class="item-price">
              <mat-card>
                <mat-card-content class="item-price-content">
                  <p>PRICE DETAILS</p>
                  <br />
                  <p class="mrp-value">
                    Total MRP <span>₹ {{ totalMRP }}</span>
                  </p>
                  <p class="discount-mrp">
                    Discount on MRP <span>- ₹ {{ totalDiscount }}</span>
                  </p>
                  <hr class="line"/>
                  <p class="totl-amount">
                    Total Amount <span>₹ {{ totalAmount }}</span>
                  </p>
                </mat-card-content>
              </mat-card>
            </div>
      
            <div class="checkout-btn-cont">
              <button mat-flat-button class="save-btn" (click)="onCheckout()"
              [disabled]="!isCheckoutEnabled" >
                Checkout
              </button><br><br>
              <div *ngIf="!isCheckoutEnabled" class="min-purchase-message">
                The minimum purchase amount is Rs {{ minOrderValue }}. Please add more items to your cart.
              </div>
            </div>
          </div>
        </div>
      </div>
</div>

