import { TableColumn } from "./data-grid.model";
import { ProductDetails } from "./product.model";
import { UserInstance } from "./user.model";
export interface StructureBaseModel {
  structureName: string;
  structureDescription: string;
  structureData?: string;
  status: string;
  imageurl?:string;
  modelQuantityJson?: object
}

export interface StructureUpdateModel {
  structureName?: string;
  structureDescription?: string;
  structureData?: string;
  status?: string;
  productId?: number;
}

export interface StructureAttributes extends StructureBaseModel {
    id: number;
    userId: number;
    adminId?: number;
    productId?: number;
    userDetails?: UserInstance;
    Product?: ProductDetails
}

export interface StructureCreationAttributes extends StructureBaseModel {
    id?: number;
    userId?: number;
}

export interface StructureInstance extends StructureAttributes {
    createdAt?: Date;
    updatedAt?: Date;
    isCheckbox?: boolean;
}

export type NullableStructureInstance = StructureInstance | null;

export enum StructureResponseMessage {
    NoStructureCreatedBeforePublish = "No structure created. Please create a structure before publish.",
    PublishedSuccess = "Published successfully.",
    PublishedFailed = "Publishing failed. Please try again later.",
    DeletedSuccess = "Deleted successfully.",
    DeletedFailed = "Deleting failed. Please try again later.",
    SaveSuccess = "Saved successfully.",
    SaveFailed = "Saving failed. Please try again later.",
    NoStructureCreated = "No structure created. Please create a structure before saving.",
    UpdateSuccess = "Updated successfully.",
    UpdateFailed = "Oops! Update didn’t work.",
    GetStructuresFailed = "GetStructuresFailed.",
    FetchStructureDataFailed = "Failed to fetch structure data."
}

export enum StructureStatus {
    Saved = "Saved",
    Published = "Published",
    Approved = "Approved",
    Rejected = "Rejected",
    Deleted = "Deleted"
}

export enum StructureOptions {
  Pending = "Pending",
  Approved = "Approved",
  Rejected = "Rejected",
}

export const structureReviewListColumns: TableColumn[] = [
  { key: "id", value: "Structure ID", type: "number" },
  { key: "structureName", value: "Structure Name", type: "text" },
  { key: "structureDescription", value: "Description", type: "text" },
  { key: "status", value: "Status", type: "text" },
  { key: "userDetails.name", value: "Created By", type: "text" },
  { key: "admin.name", value: "Actioned By", type: "text" }
];

  export interface DialogData {
    name: string;
    id: number;
  }

  export interface Structurecanvas{
    structureName: string;
    structureDescription: string;
    structurId: number;
    structureData: string;
  }
