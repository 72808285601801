import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root"
})
export class S3UploadHelperService {
    constructor(private http: HttpClient) { }

    public startUpload(fileName: string): Observable<{ uploadId: string }> {
        const url = `${environment.apiUrlBasePath}/admin/initiateMultipart`;
        return this.http.post<{ uploadId: string }>(`${url}`, { fileName });
    }


    public getPresignedUrls(fileName: string, uploadId: string, partNumbers: number[]): Observable<string[]> {
        const url = `${environment.apiUrlBasePath}/admin/generatePresignedUrl`;
        return this.http.post<string[]>(`${url}`, { fileName, uploadId, partNumbers });
    }


    public completeUpload(fileName: string, uploadId: string, parts: any[]): Observable<{ message: string }> {
        const url = `${environment.apiUrlBasePath}/admin/completeMultipart`;
        return this.http.post<{ message: string }>(`${url}`, { fileName, uploadId, parts });
    }
}