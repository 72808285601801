import { Injectable } from '@angular/core';
import { appConfig } from '../../../environments/app.config';
import { environment } from '../../../environments/environment';
import { S3UploadHelperService } from './s3UploadHelper.service';
@Injectable({
  providedIn: 'root'
})
export class S3UploadService {
  constructor(
    private s3UploadHelperService: S3UploadHelperService
  ) {}

  public async uploadImagesToS3(files: File[], categoryName: string): Promise<string[]> {
    const partSize = 5 * 1024 * 1024;
    const removeDuplicateS3ImagesSet: Set<string> = new Set<string>();
    try {
      for (const file of files) {
        const filePathWithName = `${environment.name}/${categoryName}/images/${file.name}`;
        const fileUrl = `${appConfig.awsUrlBasePath}/${filePathWithName}`;
        if (removeDuplicateS3ImagesSet.has(fileUrl)) {
          continue; 
        }
        const parts = Math.ceil(file.size / partSize);
        const partNumbers = Array.from({ length: parts }, (_, i) => i + 1);
        const updateData = await this.s3UploadHelperService.startUpload(filePathWithName).toPromise();
        if (updateData) {
          const urls = await this.s3UploadHelperService.getPresignedUrls(filePathWithName, updateData.uploadId, partNumbers).toPromise();
          if (!urls) {
            throw Error;
          }
          const uploadPromises = urls.map((url: string, index: number) => {
            const start = index * partSize;
            const end = Math.min(start + partSize, file.size);
            const blob = file.slice(start, end);
            return fetch(url, {
              method: "PUT",
              body: blob
            }).then(response => {
              if (!response.ok) {
                throw Error;
              }
              const eTag = response.headers.get("ETag");
              if (!eTag) {
                throw Error;
              }
              return { PartNumber: index + 1, ETag: eTag.replace(/"/g, "") };
            });
          });
          const partsList = await Promise.all(uploadPromises);
          await this.s3UploadHelperService.completeUpload(filePathWithName, updateData.uploadId, partsList).toPromise();
          removeDuplicateS3ImagesSet.add(fileUrl); // Add the newly uploaded file URL to the set
        }
      }
    } catch (error) {
      throw error;
    }
    return Array.from(removeDuplicateS3ImagesSet); // Convert the set back to an array before returning
  }
}
