import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GetCountComponent } from '../get-count/get-count.component';
import { ComponentInteractionSrevice } from 'src/app/services/component-interaction/component-interaction.service';
import { CountCalculatorService } from 'src/app/services/count-calculator.service';
import { ActionButtonContainer, ActionsButton, SaveButton, SlideInOut } from '@utils/action';
import { DialogService } from "../../services/dialog/dialog.service";
import { StructureService } from "../../services/structure/structure.service";
import { StructureBaseModel, StructureInstance, StructureResponseMessage, StructureStatus } from "@models/structure.model";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CountOutputData } from "../../../../../utils/count-calculator/count-calculator.model";
import { DialogInvokingComponents } from "@models/generic-dialog.model";
import { Router } from '@angular/router';
import { filter, switchMap } from 'rxjs';
import { AvailableShapes } from '../../../utils/shape-facetype';

@Component({
  selector: 'action-button-container',
  templateUrl: './action-button-container.component.html',
  styleUrls: ['./action-button-container.component.scss'],
  animations: [
    trigger(SlideInOut.triggerName, [
      state(
        SlideInOut.trueState,
        style({
          top: SlideInOut.topPos,
          right: SlideInOut.rightPoswithStateTrue,
        })
      ),
      state(
        SlideInOut.falseState,
        style({
          top: SlideInOut.topPos,
          right: SlideInOut.rightPoswithStateFalse,
        })
      ),
      transition(
        SlideInOut.transition_true_to_false,
        animate(SlideInOut.animate_duration)
      ),
      transition(
        SlideInOut.transition_false_to_true,
        animate(SlideInOut.animate_duration)
      ),
    ]),
  ],
})
export class ActionButtonContainerComponent implements OnInit, OnChanges {
  public isOpen = false;
  public showButton = false;
  public closeIconLabel = ActionsButton.closeIconLabel;
  @Input() isEditMode: boolean = false;
  @Input() isEditted = false;
  @Input() structureData!: StructureInstance | null;
  public structure!: StructureInstance;
  public structureStatus = StructureStatus;
  public actionsBtns = [
    {
      iconlabel: ActionsButton.rotateIconlabel,
      iconHint1: ActionsButton.rotateIconHint,
    },
    {
      iconlabel: ActionsButton.revertLabel,
      iconHint1: ActionsButton.revertHint,
      iconHint2: ActionsButton.revertBackHint,
    },
    {
      iconlabel: ActionsButton.filpLeftLabel,
      iconHint1: ActionsButton.flipLeftHint,
    },
    {
      iconlabel: ActionsButton.flipRightLabel,
      iconHint1: ActionsButton.flipRightHint,
    },
    {
      iconlabel: ActionsButton.flipFrontLabel,
      iconHint1: ActionsButton.flipForntHint,
    },
    {
      iconlabel: ActionsButton.flipBackLabel,
      iconHint1: ActionsButton.flipBackhint,
    },
  ];

  public saveBtns = [
    {
      iconlabel: SaveButton.saveStructureIconLabel,
      iconHint1: SaveButton.saveStructureIconHint,
    },
    {
      iconlabel: SaveButton.updateStructureIconLabel,
      iconHint1: SaveButton.updateStructureIconHint,
    },
    {
      iconlabel: SaveButton.publishIconLabel,
      iconHint1: SaveButton.publishIconHint
    }
  ];

  // Getter to filter buttons based on isEditMode
  get filteredSaveBtns() {
    return this.saveBtns.filter(btn => 
      btn.iconlabel === SaveButton.publishIconLabel || 
      (this.isEditMode && this.structureData!.status !== StructureStatus.Published && btn.iconlabel === SaveButton.updateStructureIconLabel) || 
      ((!this.isEditMode || this.structureData!.status === StructureStatus.Published)  && btn.iconlabel === SaveButton.saveStructureIconLabel)
    );
  }

  public selectedActionIndex = 10;
  public showRevert = false;
  private objects!: THREE.Mesh[];
  private outputPattern!: CountOutputData[];
  public autoCapturedImage!: string;

  @Output() isImageCaptured = new EventEmitter<boolean>();
  @Input() imageDataFromChild!: File;

  public toggleNav(): void {
    this.isOpen = !this.isOpen;
    if (!this.showButton) {
      this.showButton = !this.showButton;
    } else {
      setTimeout(() => {
        this.showButton = !this.showButton;
      }, 500);
    }
  }

  constructor(
    private dialog: MatDialog,
    private countCalculatorService: CountCalculatorService,
    private componentInteractionSrv: ComponentInteractionSrevice,
    private dialogService: DialogService,
    private structureService: StructureService,
    private snackBar: MatSnackBar,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.componentInteractionSrv.getObjectInfo().subscribe((res) => {
      this.objects = res as THREE.Mesh[];
    })
    this.componentInteractionSrv.getOutputPattern().subscribe((res => {
      this.outputPattern = res;
    }))
  }
  
  ngOnChanges(): void {
    if (this.imageDataFromChild) {
      this.convertFileToDataURL(this.imageDataFromChild).then(dataURL => {
        this.autoCapturedImage = dataURL;
      })
    }
  }

  public openDialogBox(): void {
    if (this.objects && this.objects.length) {
      this.countCalculatorService.calculate(this.objects);
      this.dialog.open(
        GetCountComponent, {
        width: ActionButtonContainer.panelWidth,
        panelClass: ActionButtonContainer.panelClassName,
        data: this.outputPattern
      }
      )
    }
  }

  public actions(index: number): void {
    this.selectedActionIndex = index;
    if (index === 1) {
      this.showRevert = !this.showRevert;
    }
  }

  private openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      horizontalPosition: "center",
      verticalPosition: "bottom",
      duration: 4000
    });
  }


  public saveActions(iconValue: string): void {
    if (iconValue === SaveButton.saveStructureIconLabel || iconValue === SaveButton.updateStructureIconLabel) {
      if (!this.objects) {
        this.openSnackBar(StructureResponseMessage.NoStructureCreated, "Okay");
        return;
      }

      const objectsWithoutPlaneMesh = this.objects.filter((object: THREE.Mesh) => {
        return object.name != "plane";
      });

      const objectsWithoutPlaneJson = objectsWithoutPlaneMesh.map((object: THREE.Mesh) => {
        const objectJson = object.toJSON();
        object.userData['rotation'] = {
          x: object.rotation.x,
          y: object.rotation.y,
          z: object.rotation.z,
          order: object.rotation.order
        };
        return objectJson;
      });

      this.isImageCaptured.emit(true);

      const data = {
        componentName: DialogInvokingComponents.StructureDetails,
        title: (iconValue === SaveButton.saveStructureIconLabel) 
                ? "Save Structure" 
                : (iconValue === SaveButton.updateStructureIconLabel)
                  ? "Edit Structure"
                  : "Publish Structure",
        firstBtn: (iconValue === SaveButton.saveStructureIconLabel) 
                  ? "Save" 
                  : (iconValue === SaveButton.updateStructureIconLabel) 
                    ? "Update" 
                    : "Publish",
        secondBtn: "Cancel",
        structureName: (iconValue === SaveButton.saveStructureIconLabel || 
                       (!this.isEditMode && this.structureData?.status === this.structureStatus.Saved)) 
                       ? '' 
                       : this.structureData?.structureName || '',
        structureDescription: (iconValue === SaveButton.saveStructureIconLabel || 
                              (!this.isEditMode && this.structureData?.status === this.structureStatus.Saved)) 
                              ? '' 
                              : this.structureData?.structureDescription || ''
      };
    

      this.dialogService.openDialog(data).afterClosed().pipe(
        filter(response => !!response),
        switchMap(response => {
          this.countCalculatorService.calculate(this.objects);
            const body: StructureBaseModel = {
              structureName: response.structureName,
              structureDescription: response.structureDescription,
              structureData: JSON.stringify(objectsWithoutPlaneJson),
              status: StructureStatus.Saved,
              imageurl: this.autoCapturedImage,
              modelQuantityJson: {
                totalcount: this.objects.filter(mesh => mesh.name !== AvailableShapes.Plane).length,
                faceinformation: this.outputPattern,
            }
            };
            return (iconValue === SaveButton.saveStructureIconLabel)
            ? this.structureService.saveStructure(body)
            : (this.isEditMode && iconValue === SaveButton.updateStructureIconLabel)
              ? this.structureService.updateStructure(this.structureData!.id, body)
              : this.structureService.saveAndPublishStructure(body);
        })
      ).subscribe({
        next: response => {
          this.openSnackBar(response.message, "okay");
          this.router.navigateByUrl("myStructures");
        }
      })
    } else if (iconValue === SaveButton.publishIconLabel) {
      // publish
      if (!this.objects) {
        this.openSnackBar(StructureResponseMessage.NoStructureCreatedBeforePublish, "Okay");
        return;
      }

      const objectsWithoutPlaneMesh = this.objects.filter((object: THREE.Mesh) => {
        return object.name != "plane";
      });

      const objectsWithoutPlaneJson = objectsWithoutPlaneMesh.map((object: THREE.Mesh) => {
        const objectJson = object.toJSON();
        object.userData['rotation'] = {
          x: object.rotation.x,
          y: object.rotation.y,
          z: object.rotation.z,
          order: object.rotation.order
        };
        return objectJson;
      });

      this.isImageCaptured.emit(true);

      const data = {
        componentName: DialogInvokingComponents.StructureDetails,
        title: "Publish Structure",
        firstBtn: "Publish",
        secondBtn: "Cancel",
        isPublish: true,
        structureName: this.structureData?.structureName || '',
        structureDescription: this.structureData?.structureDescription || ''
      };

      this.dialogService.openDialog(data).afterClosed().pipe(
        // Filter out cases where the dialog was closed without a response
        filter(response => !!response),
        switchMap(response => {
          this.countCalculatorService.calculate(this.objects);
          const count = {
            totalcount: this.objects.filter(mesh => mesh.name !== AvailableShapes.Plane).length,
            faceinformation: this.outputPattern
          }
          const body: StructureBaseModel = {
            structureName: response.structureName,
            structureDescription: response.structureDescription,
            structureData: JSON.stringify(objectsWithoutPlaneJson),
            status: StructureStatus.Published,
            imageurl: this.autoCapturedImage,
            modelQuantityJson: count
          };
          return this.structureService.saveAndPublishStructure(body);
        })
      ).subscribe({
        next: response => {
          this.openSnackBar(response.message, "okay");
          this.router.navigateByUrl("myStructures");
        }, error: error => {
          this.openSnackBar(error, "okay");
        }
      }
      );
    }
  }

  public convertFileToDataURL(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  }

  public isDisabled(btn: { iconlabel: SaveButton; iconHint1: SaveButton; }): boolean {
    return btn.iconHint1 === SaveButton.updateStructureIconHint && !this.isEditted;
  }
}
