import { ValidatorFn, AbstractControl, ValidationErrors } from "@angular/forms";

export function indianPincodeValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const indianPincodeRegex = /^[1-9][0-9]{5}$/;
    return !indianPincodeRegex.test(control.value)
      ? { invalidPincode: true }
      : null;
  };
}
