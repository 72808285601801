import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { environment } from "src/environments/environment";
import { productCategories, ProductDetails, ProductOperation, ReviewValues } from "@models/product.model";
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: "root"
})
export class ProductService {

  private dataSubject = new BehaviorSubject<string>('');
  productName$ = this.dataSubject.asObservable();

  constructor(private http: HttpClient) { }

  public setProductName(data :string) {
    this.dataSubject.next(data);
  }

  public getProductName(): string {
    return this.dataSubject.value;
  }

  public getAllActiveProducts(
    pageIndex: number = environment.dynamicPagination.pageIndex,
    pageSize: number = environment.dynamicPagination.pageSize,
    searchInput?: string,
    category?: string
  ): Observable<{ count: number; rows: ProductDetails[] }> {
    let url = "";
    if (searchInput) {
      const encodedProductName = encodeURIComponent(searchInput);
      url = `${environment.apiUrlBasePath}/products/getAllActive?pageIndex=${pageIndex}&pageSize=${pageSize}&searchInput=${encodedProductName}`;
    } else {
      url = `${environment.apiUrlBasePath}/products/getAllActive?pageIndex=${pageIndex}&pageSize=${pageSize}&category=${category}`;
    }
    return this.http.get<{ count: number; rows: ProductDetails[] }>(url);
  }

  public getAllProducts(
    pageIndex: number = environment.dynamicPagination.pageIndex,
    pageSize: number = environment.dynamicPagination.pageSize,
    name?: string
  ): Observable<{ count: number; rows: ProductDetails[] }> {
    let url = "";
    if (name) {
      const encodedProductName = encodeURIComponent(name);
      url = `${environment.apiUrlBasePath}/admin/getAll/products?pageIndex=${pageIndex}&pageSize=${pageSize}&name=${encodedProductName}`;
    } else {
      url = `${environment.apiUrlBasePath}/admin/getAll/products?pageIndex=${pageIndex}&pageSize=${pageSize}`;
    }

    return this.http.get<{ count: number; rows: ProductDetails[] }>(url);
  }

  public addProduct(product: ProductOperation): Observable<{product: ProductOperation, message: string}> {
    const url = `${environment.apiUrlBasePath}/admin/addProduct`;
    return this.http.post<{product: ProductOperation, message: string}>(url, product);
  }

  public getProductById(productId: number): Observable<ProductDetails> {
    const url = `${environment.apiUrlBasePath}/products/${productId}`;
    return this.http.get<ProductDetails>(url);
  }

  public editProduct(
    productId: number,
    updatedProductData: Partial<ProductOperation>
  ): Observable<{product: ProductOperation, message: string}> {
    const url = `${environment.apiUrlBasePath}/admin/editProduct/${productId}`;
    return this.http.put<{product: ProductOperation, message: string}>(url, updatedProductData);
  }

  public deleteFile(fileUrl: string): Observable<{ message: string }> {
    const url = `${environment.apiUrlBasePath}/admin/deleteFile`;
    return this.http.post<{ message: string }>(`${url}`, {fileUrl});
  }

  public deleteFiles(fileUrls: string[]): Observable<{ message: string }> {
    const url = `${environment.apiUrlBasePath}/admin/deleteFiles`;
    return this.http.post<{ message: string }>(`${url}`, {fileUrls});
  }

  public getInActiveProductIds(): Observable<number[]> {
    return this.http.get<number[]>(
      `${environment.apiUrlBasePath}/products/getInactive`
    );
  }

  public addReview(review: ReviewValues): Observable<{message:string}> {
    return this.http.post<{message:string}>(`${environment.apiUrlBasePath}/products/addRateAndReview`, review)
  }

  public suggestedProduct(productName:string, productId: number): Observable<ProductDetails[]> {
    return this.http.get<ProductDetails[]>(`${environment.apiUrlBasePath}/products/suggestedProduct?productName=${productName}&productId=${productId}`)
  }

  public animatePlaceholder(inputElement: HTMLInputElement, placeholderText: string): void {
    const typingSpeed: number = 100;
    const erasingSpeed: number = 50;
    const pauseDuration: number = 1000;

    let i: number = 0;
    let isErasing: boolean = false;

    function typeEffect(): void {
      if (isErasing) {
        if (i > 0) {
          inputElement.placeholder = placeholderText.slice(0, i - 1);
          i--;
          setTimeout(typeEffect, erasingSpeed);
        } else {
          isErasing = false;
          setTimeout(typeEffect, pauseDuration);
        }
      } else {
        if (i < placeholderText.length) {
          inputElement.placeholder = placeholderText.slice(0, i + 1);
          i++;
          setTimeout(typeEffect, typingSpeed);
        } else {
          isErasing = true;
          setTimeout(typeEffect, pauseDuration);
        }
      }
    }

    typeEffect();
  }
  
  private selectedCategory: string = productCategories[0].toString();
  setSelectedCategory(category: string): void {
    this.selectedCategory = category;
  }

  getSelectedCategory(): string {
    return this.selectedCategory;
  }

  private resetSubject = new BehaviorSubject<boolean>(false);
  reset = this.resetSubject.asObservable();

  triggerReset(reset: boolean) {
    this.resetSubject.next(reset);
  }
  
}
