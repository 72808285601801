<mat-spinner *ngIf="loading" class="loading-spinner" [diameter]="70"></mat-spinner>
<div class="container" *ngIf="!loading && order">
  <div class="heading-container">
    <h3 class="title">Order Details</h3>
    <div *ngIf="isCancellable(order)">
      <button class="btn" mat-raised-button (click)="cancel(order)">
        Cancel Order
      </button>
    </div>
  </div>
  <app-order-timeline
    *ngIf="showTimeline"
    [statusHistory]="filteredStatusHistory!"
  ></app-order-timeline>

  <div class="subtitle">
    <span class="orderedOn"
      >Ordered on {{ order.createdAt | date: "dd MMMM y" }}</span
    >
    <span>|</span>
    <span class="orderId">Order# {{ order.id }}</span>
  </div>

  <div class="card main">
    <div class="shippingAddress">
      <p class="main-title">Shipping Address</p>

      <p>{{ order.recipientName }}</p>

      <p>
        {{ order.recipientHouseNumber }}, {{ order.recipientAddress }},
        {{ order.recipientCity }}, {{ order.recipientState }},
        {{ order.recipientPincode }}
      </p>
      <p>{{ order.recipientMobileNumber }}</p>
    </div>

    <div class="status">
      <p class="main-title">Status</p>

      <span class="status-pair">
        <p class="label">Order Status:</p>
        <p class="value" [ngClass]="getOrderStatusClasses()">
          {{ order.orderStatus }}
        </p>
      </span>

      <span class="status-pair">
        <p class="label">Payment Status:</p>
        <p class="value" [ngClass]="getPaymentStatusClasses()">
          {{ order.paymentStatus }}
        </p>
      </span>
    </div>

    <div class="summary">
      <p class="main-title">Summary</p>

      <span class="pair">
        <p class="label">Item(s) Subtotal:</p>
        <p class="value">
          {{ currency }} {{ order.totalAmount - order.deliveryCharge }}
        </p>
      </span>
      <span class="pair">
        <p class="label">Shipping:</p>
        <p class="value">{{ currency }} {{ order.deliveryCharge }}</p>
      </span>
      <span class="pair">
        <p class="label">Total:</p>
        <p class="value">{{ currency }} {{ order.totalAmount }}</p>
      </span>
    </div>
  </div>

  <div class="card items">
    <div class="product-card" *ngFor="let product of order.products">
      <div class="prod-img-container">
        <img
          *ngIf="product?.images?.imagesUrl?.[0]"
          [src]="product?.images?.imagesUrl?.[0]"
          class="image"
        />
        <div *ngIf="!product?.images?.imagesUrl?.[0]" class="image"></div>
      </div>

      <div class="details">
        <div class="product-name">
          <p
            *ngIf="product.name"
            [matTooltip]="product.name"
            [matTooltipShowDelay]="500"
            [matTooltipHideDelay]="500"
            [matTooltipDisabled]="!isOverflow(productName)"
            #productName
          >
            {{ product.name }}
          </p>
        </div>

        <div class="quantity">
          <p>Quantity: {{ product.quantity }}</p>
        </div>

        <div class="price">
          <p>₹{{ product.discountPrice || product.price }}</p>
        </div>
      </div>

      <div class="ratingAndReview">
        <button
          *ngIf="
            order.orderStatus === OrderStatus.Delivered ||
            order.orderStatus === OrderStatus.ReturnInitiated ||
            order.orderStatus === OrderStatus.PickupScheduled ||
            order.orderStatus === OrderStatus.Returned
          "
          mat-raised-button
          class="save-btn"
          [disabled]="isReviewDisabled(product)"
          (click)="openRatingPopup(product, order)"
        >
          Add Review
        </button>
      </div>
    </div>
  </div>
</div>
