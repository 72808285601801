import { Component, Input } from '@angular/core';
import { AdminStatusHistoryModel } from "@models/adminStatusHistory.model";
import { OrderStatus, OrderStatusNext } from "@models/order.model";

@Component({
  selector: 'app-order-timeline',
  templateUrl: './order-timeline.component.html',
  styleUrls: ['./order-timeline.component.scss']
})
export class OrderTimelineComponent {
  orderStatuses: { status: string, color: string, time?: Date }[] = [];
  @Input() statusHistory: AdminStatusHistoryModel[] = [];

  ngOnChanges() {
    this.orderStatuses = [];
    this.initializeOrderStatuses();
  }

  private initializeOrderStatuses(): void {
    for (const history of this.statusHistory) {
      this.orderStatuses.push({ status: history.status, color: 'green', time: history.createdAt});
    }

    if(this.getNextStatus(this.statusHistory.at(-1)!.status)){
      this.orderStatuses.push({ status: this.getNextStatus(this.statusHistory.at(-1)!.status), color: 'grey'});
    }
  }
  
  private getNextStatus(status: string): string {
    switch (status) {
      case OrderStatus.Ordered:
          return OrderStatusNext.Ordered;
      case OrderStatus.Packed:
          return OrderStatusNext.Packed;
      case OrderStatus.Dispatched:
          return OrderStatusNext.Dispatched;
      case OrderStatus.Delivered:
          return OrderStatusNext.Delivered;
      case OrderStatus.ReturnInitiated:
          return OrderStatusNext.ReturnInitiated;
      case OrderStatus.PickupScheduled:
          return OrderStatusNext.PickupScheduled;
      case OrderStatus.Returned:
          return OrderStatusNext.Returned;
      default:
          return '';
    }
  }
}
