import {
  Component,
  OnInit,
  HostListener,
  AfterViewInit,
  ChangeDetectorRef,
  ViewChild,
} from "@angular/core";
import { ProductCategories, ProductBody, ProductDetails } from "@models/product.model";
import { ActivatedRoute, Router } from "@angular/router";
import { ReviewModel, ReviewStatus } from "@models/admin.model";
import { ProductService } from "../services/product/product.service";
import { CartItemService } from "../services/cart-item/cart-item.service";
import { CartItem, WishListItem } from "@models/cart.model";
import { LocalStorageService } from "../services/local-storage/local-storage.service";
import { RightNavbarComponent } from '../dashboard/right-navbar/right-navbar.component';
import { MatDialog } from "@angular/material/dialog";
import { OpenReviewImagesComponent } from "./open-review-images/open-review-images.component";
import { ShapeJson } from '../shared/shape-selector/shape-selector.model';
import { Colors } from '../shared/models/shape.model';
import { WishlistService } from '../services/wishlistService/wishlist.service';
import { AuthService } from '../services/auth/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: "app-product-detail-page",
  templateUrl: "./product-detail-page.component.html",
  styleUrls: ["./product-detail-page.component.scss"],
})
export class ProductDetailPageComponent implements OnInit, AfterViewInit {
  @ViewChild(RightNavbarComponent) rightNavbarComponent!: RightNavbarComponent;

  public productId!: number;
  public quantity = 1;
  public imageObject: {
    image: string;
    thumbImage: string;
    video?: string;
  }[] = [];
  public productDetail!: ProductDetails;
  public showAnotherIcon: boolean = false;
  public sliderImageSize = { width: "100%", height: "730px" };
  public data: ProductBody[] = [];
  public cartItems: CartItem[] = [];
  public isProductInCart!: boolean;
  public visibleReviews: number = 5;
  public isFullDescription = false;
  public imageObjectReviewAndRating: Array<{ image: string, thumbImage: string }> = [];
  public isDescriptionMoreThanThreeLines = true;
  public showReadMore = false;
  public imageSize = { width: '100px', height: 100 };
  public activeIndex: number = 0;
  public suggestedProducts:ProductDetails[] = [];
  public currentImageIndex: { [key: number]: number } = {};
  private imageRotations: { [key: number]: any } = {};
  public shapeJson !: ShapeJson;
  public isNavbarOpen: boolean = false;
  public isCategoryBuyBlock: boolean = true;
  private categories = ProductCategories;
  public selectedColor!: THREE.ColorRepresentation | string;
  public colors = Colors;

  constructor(
    private productService: ProductService,
    private route: ActivatedRoute,
    private cartService: CartItemService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private wishListItemService: WishlistService,
    private authService:AuthService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.cartService.getCartItems().subscribe(items => {
      this.cartItems = items;
    });

    this.route.params.subscribe(params => {
        this.productId = +params['id'];
        if(this.productId) {
          this.loadProducts();
        }
    });

    this.cartService.cartItemDeleted.subscribe((deletedProductId: number) => {
      if (deletedProductId === this.productId) {
        this.isProductInCart = false;
      }
    });
  }

  private loadProducts(): void {
    this.productService.getProductById(this.productId).subscribe((product) => {
      this.productDetail = product;
      if(this.productDetail.model3DJson) {
        this.shapeJson = this.productDetail.model3DJson as ShapeJson;
      }
      this.isCategoryBuyBlock = !!this.productDetail.category?.includes(this.categories.BuyBlocks);

      this.isProductInCart = this.cartItems.some(
        item => item.product!.id === this.productDetail.id
      );

      this.quantity = this.localStorageService.getCartedQuantity(this.productId) || 1;

      this.loadImagesAndVideos();
      this.buildImageObject();
      this.fetchSuggestedProducts(this.productDetail?.name, this.productDetail?.id)
    });
  }

  ngAfterViewInit() {
    this.checkDescriptionLines();
    this.updateSliderImageSize();
  }

  private checkDescriptionLines(): void {
    const el = document.querySelector('.description') as HTMLElement;
    if (el) {
      const { lineHeight, borderTop, borderBottom } = window.getComputedStyle(el);
      const contentHeight = el.scrollHeight - parseInt(borderTop) - parseInt(borderBottom);
      const lines = Math.floor(contentHeight / parseInt(lineHeight));

      this.isDescriptionMoreThanThreeLines = lines > 3;
    }
  }

  private loadImagesAndVideos(): void {
      if (this.productDetail && this.productDetail.images) {
      const imagesArray = this.productDetail.images.imagesUrl || [];
      let videoUrls: string[] = [];

      if (typeof this.productDetail.video === "string") {
        try {
          videoUrls = JSON.parse(this.productDetail.video) as string[];
        } catch (e) {
          videoUrls = [];
        }
      } else if (Array.isArray(this.productDetail.video)) {
        videoUrls = this.productDetail.video;
      }

      this.imageObject = imagesArray.map(imageUrl => ({
        image: imageUrl,
        thumbImage: imageUrl
      }));

      if (Array.isArray(videoUrls)) {
        videoUrls.forEach(videoUrl => {
          if (typeof videoUrl === "string" && videoUrl) {
            const fileExtension = videoUrl
              .split(".")
              .pop()
              ?.toLowerCase();

            if (fileExtension === "mp4") {
              this.imageObject.push({
                image: videoUrl,
                thumbImage: videoUrl,
                video: videoUrl
              });
            } else {
              throw new Error(`Unsupported video format: ${fileExtension}`);
            }
          }
        });
      }
    }

    for (let i = 0; i < this.data.length; i++) {
      this.data.forEach((item: { id: number; quantity: number }) => {
        if (item.id === this.productDetail.id) {
          this.productDetail.disable = true;
          this.productDetail.quantity = item.quantity;
        } else {
          this.productDetail.disable = false;
        }
      });
    }
    this.cdr.detectChanges();
  }

  public isOverflow(element: HTMLElement): boolean {
    return (element.clientWidth < element.scrollWidth || element.clientHeight < element.scrollHeight);
  }

  public onInputChange(event: Event, item: ProductDetails): void {
    let inputValueString = (event.target as HTMLInputElement).value;
    const numericalValue = parseInt(inputValueString, 10);
    if (!inputValueString) {
      return;
    }
    if (numericalValue < 1) {
      (event.target as HTMLInputElement).value = "1";
    } else {
      inputValueString = inputValueString.slice(0, 2);
      (event.target as HTMLInputElement).value = inputValueString;
      this.quantity = parseInt(inputValueString, 10);
    }
    this.cartService.updateCartQuantity(item.id, this.quantity);
  }

  public checkQuantity(item: ProductBody): void {
    if (!this.quantity) {
      this.quantity = 1;
      this.cartService.updateCartQuantity(item.id, this.quantity);
    }
  }

  public onClick(value: number): void {
    this.quantity += value;
    if(this.isProductInCart) {
      this.cartService.updateCartQuantity(this.productId, this.quantity).subscribe((res) => {
    });
    }
    this.rightNavbarComponent.loadCartItems();
  }

  public addToCart(): void {
    const body: CartItem = {
      id: this.productDetail.id,
      quantity: this.quantity,
      singleBlockInfo: {
        color: this.selectedColor as string
      }
    };

    this.cartService.addToCart(body).subscribe(response => {
      if (response) {
        this.isProductInCart = true;
        this.isNavbarOpen = true;
        this.rightNavbarComponent.loadCartItems();
      }
    });
  }

  public onClickBuy(id: number, quantity: number, product: ProductDetails ): void {
    let productDetails: CartItem[] = [{id, quantity, product}];
    this.router.navigate(["checkout"], {
      state: { productData: productDetails }
    });
  }

  public goToCart(): void {
    this.router.navigate(["cart"]);
  }

  public showMoreReviews(): void {
    this.visibleReviews += 5;
  }

  public getFilteredReviews():ReviewModel[] | undefined{
    return this.productDetail.reviews?.filter(
      review => review.status === ReviewStatus.Approved
    );
  }

  public getStarsArray(rating: number): number[] {
    return Array(rating).fill(0);
  }

  public getTotalRatingsAndReviews():{validReviewsCount:number,ratingCount:number} {
    let validReviewsCount = 0;
    let ratingCount = 0;

    this.productDetail.reviews?.forEach((review: ReviewModel) => {
      if (review.status === ReviewStatus.Approved) {
        if (review.rating !== null) {
          ratingCount++;
        }
        if (review.review) {
          validReviewsCount++;
        }
      }
    });

    return {
      validReviewsCount: validReviewsCount,
      ratingCount: ratingCount
    };
  }

  public canShowMoreReviews(): boolean {
    if (this.productDetail && this.productDetail.reviews) {
      return this.productDetail.reviews.length > this.visibleReviews;
    }
    return false;
  }

  public buildImageObject(): void {
    this.imageObjectReviewAndRating = [];
    this.getFilteredReviews()?.forEach((review) => {
      review.images?.imagesUrl?.forEach((url) => {
        this.imageObjectReviewAndRating.push({ image: url, thumbImage: url });
      });
    });
  }

  public readMore(): void {
    this.isFullDescription = true;
  }

  public readLess(): void {
    this.isFullDescription = false;
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event: Event): void {
    this.checkDescriptionLines();
    this.updateSliderImageSize();
  }

  private updateSliderImageSize(): void {
    const screenWidth = window.innerWidth;
    let height = '';

    if (screenWidth <= 600) { // Small screens
      height = "300px";
    } else if (screenWidth <= 900) { // Medium screens
      height = "500px";
    } else { // Large screens
      height = "730px";
    }

    this.sliderImageSize.height = height;

    // Optionally, force change detection
    setTimeout(() => {
      this.cdr.detectChanges();
    }, 0);
  }

  public getRatingClass(rating: number): string {
    return `rating-${rating}`;
  }

  private fetchSuggestedProducts(productName: string, productId:number): void {
    this.productService.suggestedProduct(productName, productId).subscribe({
      next: response => {
        this.suggestedProducts = response;
      }
    });
  }

  public startImageRotation(productId: number): void {
    this.currentImageIndex[productId] = 0;
    const images = this.suggestedProducts.find(p => p.id === productId)?.images?.imagesUrl || [];
    if (images.length > 1) {
      this.imageRotations[productId] = setInterval(() => {
        this.currentImageIndex[productId] = (this.currentImageIndex[productId] + 1) % images.length;
      }, 2000);
    }
  }

  public stopImageRotation(productId: number): void {
    if (this.imageRotations[productId]) {
      clearInterval(this.imageRotations[productId]);
      delete this.imageRotations[productId];
      this.currentImageIndex[productId] = 0; // Reset to the first image
    }
  }

  public getCurrentImageUrl(productId: number): string {
    const product = this.suggestedProducts.find(p => p.id === productId);
    if (product && product.images && product.images.imagesUrl.length > 0) {
      const index = this.currentImageIndex[productId] || 0;
      return product.images.imagesUrl[index];
    }
    return '';
  }

  public onClickProduct(productId: number): void {
    this.router.navigate([`products/${productId}`]);
  }

  public getProductQtyInCart(productId: number): number {
    return this.localStorageService.getCartedQuantity(productId);
  }

  public onclick(value: number, productId: number): void {
    const quantityInCart = this.getProductQtyInCart(productId);
    const newQuantity = quantityInCart + value;
    this.updateCartQuantity(productId, newQuantity);
  }

  private updateCartQuantity(productId: number, quantity: number): void {
    if (quantity < 1) {
      this.removeProductFromCart(productId);
    } else if (quantity <= 99) {
      this.updateProductQuantityInCart(productId, quantity);
    }
  }

  private removeProductFromCart(productId: number): void {
    this.cartService.removeCartItem(productId).subscribe(() => {});
  }

  private updateProductQuantityInCart(productId: number, quantity: number): void {
    this.cartService.updateCartQuantity(productId, quantity).subscribe(() => {});
  }

  public onBlur(event: Event, productId: number): void {
    const inputValue = (event.target as HTMLInputElement).value.trim();
    if (!inputValue) {
      (event.target as HTMLInputElement).value = "1";
      const quantity = parseInt((event.target as HTMLInputElement).value, 10);
      this.updateCartQuantity(productId, quantity);
    }
  }

  public addToCartSuggested(productId: number, quantity: number) {
    const item: CartItem = {
      id: productId,
      quantity: quantity
    };
    this.cartService.addToCart(item).subscribe(() => {
      this.isNavbarOpen = true;
      this.rightNavbarComponent.loadCartItems();
    });
  }

  public onCloseNavbar() {
    this.isNavbarOpen = false;
  }

  public openReviewImages(reviewData:ReviewModel):void {
     this.dialog.open(OpenReviewImagesComponent, {
      width: '100vw',
      height: '(100vh - 70px)',
      data: {
        reviewProductData:reviewData
      }
    })
  }

  public getSelectedColor(color: THREE.ColorRepresentation | string): void {
    this.selectedColor = color;
  }

  public addToFavorite(product: ProductDetails): void {
    this.wishListItemService.addToFavorite(product);
  }

  public  isInWishlist(product: ProductDetails): boolean {
    return this.wishListItemService.isInWishlist(product);
  }

}
