// app.module.ts
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { FlexLayoutModule } from "@angular/flex-layout";
import { SharedModule } from "./shared/shared/shared.module";

// Material Modules
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import {MatListModule} from '@angular/material/list';
import { MatDialogModule } from "@angular/material/dialog";
import { MatInputModule } from "@angular/material/input";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatTableModule } from "@angular/material/table";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatCardModule } from "@angular/material/card";
import { MatSelectModule } from "@angular/material/select";
import { MatOptionModule } from "@angular/material/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatChipsModule } from "@angular/material/chips";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { MatMenuModule } from "@angular/material/menu";
import { NgImageSliderModule } from "ng-image-slider";
import { CheckoutModule } from "paytm-blink-checkout-angular";

// Components
import { DesignerComponent } from "./designer/designer.component";
import { PageNotFoundComponent } from "./shared/page-not-found/page-not-found.component";
import { GenerateShapeComponent } from "./generate-shape/generate-shape.component";
import { LocationDetailsComponent } from "./user-details/location-details/location-details.component";
import { ProfileDetailsComponent } from "./user-details/profile-details/profile-details.component";
import { CanvasComponent } from "./canvascomponent/canvascomponent.component";
import { ShapeSelectorComponent } from "./canvascomponent/shape-selector/shape-selector.component";
import { CompassCubeComponent } from "./canvascomponent/compass-cube/compass-cube.component";
import { ActionButtonContainerComponent } from "./canvascomponent/action-button-container/action-button-container.component";
import { GetCountComponent } from "./canvascomponent/get-count/get-count.component";
import { ColourPickerComponent } from "./canvascomponent/colour-picker/colour-picker.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { ProductDetailPageComponent } from "./product-detail-page/product-detail-page.component";
import { HeaderComponent } from "./shared/header/header.component";
import { LoginPageComponent } from "./login-page/login-page.component";
import { TrackOrderComponent } from "./shared/track-order/track-order.component";
import { HomePageComponent } from "./dashboard/home-page/home-page.component";
import { ProductViewlistComponent } from "./shared/product-viewlist/product-viewlist.component";
import { CartPageComponent } from "./dashboard/cart-page/cart-page.component";
import { CheckoutPageComponent } from "./dashboard/checkout-page/checkout-page.component";
import { OrderSuccessComponent } from "./order-success/order-success.component";
import { GenericDialogComponent } from "./shared/generic-dialog/generic-dialog.component";
import { AddressComponent } from "./user-details/address/address.component";
import { UserDetailsComponent } from "./user-details/user-details.component";
import { ProfileComponent } from "./profile/profile.component";
import { OrderDetailsComponent } from "./order-details/order-details.component";
import { PrivacyPageComponent } from "./shared/footer/privacy-page/privacy-page.component";
import { TermConditionComponent } from "./shared/footer/term-condition/term-condition.component";
import { MatStepperModule } from "@angular/material/stepper";
import { ContactComponent } from "./contact/contact.component";
import { PaymentModule } from "./payment/payment.module";

import { GenericInterceptor } from "./services/interceptors/generic.interceptor";
import { DashboardAdminPageComponent } from "./admin/dashboard-admin-page/dashboard-admin-page.component";
import { RatingAndReviewComponent } from "./order-details/rating-and-review/rating-and-review.component";
import { MatProgressBarModule } from "@angular/material/progress-bar";

import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { OrderTimelineComponent } from './shared/order-timeline/order-timeline.component';

import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { SavedStructuresViewComponent } from './designer/saved-structures-view/saved-structures-view.component';
import { MyStructuresComponent } from './my-structures/my-structures.component';
import { MyStructuresListComponent } from './my-structures/my-structures-list/my-structures-list.component';
import { RightNavbarComponent } from './dashboard/right-navbar/right-navbar.component';
import { BlockDetailsComponentComponent } from './block-details-component/block-details-component.component';
import { ProductImageComponent } from './product-image/product-image-gallery.component';
import { BannerComponent } from './shared/banner/banner.component';
import { OpenReviewImagesComponent } from './product-detail-page/open-review-images/open-review-images.component';
import { WishlistPageComponent } from './shared/wishlist-page/wishlist-page.component';
import { UserNotificationComponent } from './user-notification/user-notification.component';
@NgModule({
  declarations: [
    AppComponent,
    DesignerComponent,
    PageNotFoundComponent,
    CanvasComponent,
    GenerateShapeComponent,
    ShapeSelectorComponent,
    CompassCubeComponent,
    GetCountComponent,
    ColourPickerComponent,
    DashboardComponent,
    HeaderComponent,
    LoginPageComponent,
    TrackOrderComponent,
    HomePageComponent,
    ProductDetailPageComponent,
    ProductViewlistComponent,
    ActionButtonContainerComponent,
    CartPageComponent,
    CheckoutPageComponent,
    OrderSuccessComponent,
    GenericDialogComponent,
    LocationDetailsComponent,
    ProfileDetailsComponent,
    AddressComponent,
    UserDetailsComponent,
    ProfileComponent,
    OrderDetailsComponent,
    PrivacyPageComponent,
    TermConditionComponent,
    ContactComponent,
    DashboardAdminPageComponent,
    OrderTimelineComponent,
    RatingAndReviewComponent,
    SavedStructuresViewComponent,
    MyStructuresComponent,
    MyStructuresListComponent,
    RightNavbarComponent,
    BlockDetailsComponentComponent,
    ProductImageComponent,
    BannerComponent,
    OpenReviewImagesComponent,
    WishlistPageComponent,
    UserNotificationComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FlexLayoutModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule,
    MatListModule,
    MatDialogModule,
    MatInputModule,
    MatSnackBarModule,
    MatTableModule,
    MatSidenavModule,
    MatCardModule,
    MatSelectModule,
    MatOptionModule,
    MatTooltipModule,
    MatChipsModule,
    PaymentModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatCheckboxModule,
    MatStepperModule,
    MatMenuModule,
    NgImageSliderModule,
    CheckoutModule,
    SharedModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressBarModule,
    NgxMaterialTimepickerModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GenericInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
