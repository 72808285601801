<form [formGroup]="loginForm">
  <div class="container">
    <h2>Login</h2>
    <div class="input-group">
      <label for="mobileNumber">Mobile Number:</label>
      <input
        type="text"
        id="mobileNumber"
        formControlName="mobileNumber"
        maxlength="10"
        autocomplete="off"
      />
      <div
        *ngIf="loginForm.get('mobileNumber')?.errors?.['pattern'] && loginForm.get('mobileNumber')?.dirty"
        class="error-message"
      >
        Mobile number should contain only 10 digits.
      </div>
      <div
        *ngIf="loginForm.get('mobileNumber')?.errors?.['required'] && loginForm.get('mobileNumber')?.dirty"
        class="error-message"
      >
        Mobile number is required.
      </div>
    </div>
    <div *ngIf="showOTPField" class="input-group">
      <label for="otp">OTP:</label>
      <input type="text" id="otp" formControlName="otp" maxlength="6"  #otpInput  />
      <div
        *ngIf="loginForm.get('otp')?.errors?.['pattern'] && loginForm.get('otp')?.dirty && otpSentMessage === ''"
        class="error-message"
      >
        OTP should contain only 6 digits.
      </div>
      <div
        *ngIf="loginForm.get('otp')?.errors?.['required'] && loginForm.get('otp')?.touched && otpSentMessage === '' "
        class="error-message"
      >
        OTP is required.
      </div>
      <div
        *ngIf="loginForm.get('otp')?.errors?.['invalidOTP'] && loginForm.get('otp')?.dirty && otpSentMessage === ''"
        class="error-message"
      >
        OTP is invalid.
      </div>
      <div *ngIf="otpSentMessage" class="input-group">
        <div class="success-message">{{ otpSentMessage }}</div>
      </div>
    </div>
    <button
      class="save-btn"
      mat-raised-button
      (click)="showSubmitButton ? submitOTP() : sendOTP()"
      [disabled]="loginForm.invalid || isOTPButtonDisabled"
    >
      {{ showSubmitButton ? "Submit OTP" : "Send OTP" }}
    </button>
    <div *ngIf="showOTPField">
      <div *ngIf="showResendLink" class="resend-link" (click)="resendOTP()">
        Resend OTP
      </div>
      <div class="timer" *ngIf="showTimer">
        {{ timerDuration }}
      </div>
    </div>
  </div>
</form>
